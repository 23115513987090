@import '../../../styles/variables';
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  h2 {
    font-weight: bold;
    color: $blue;
  }
  .link {
    border-bottom: 1px solid $pink;
    color: $pink;
    cursor: pointer;
  }
  .forgotInput { 
    width: 100%;
    input {
      width: 100%;
      &::placeholder {
        transform: translateX(0rem);
      }
    }
    
  }  


}
