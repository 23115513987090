.confirmationModal {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 1.8rem;
    color: #00aee5;
    text-align: center;
    margin: 0;
  }
  .textDiv {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
    text-align: center;
    p {
      font-size: 1.5rem;
    }
  }
  .btn {
    height: 3rem;
    width: 60%;
    display: inline;
    span {
      font-size: 1.4rem;
    }
  }
}
