// React toast
@import './variables';
.toast {
  z-index: 999999999 !important;
  margin-right: 0.625rem;
}

.toast-wrapper {
  border-radius: 0;
  padding-left: 0.813rem;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

// .Toastify__progress-bar {
//   height: 0.125rem;
//   background-color: $lighterPurple;
// }

// .Toastify__close-button {
//   color: $darkPurple;
// }

.Toastify__toast--success {
  background: #e6fffb;
  color: $green;
  border-left: 0.25rem solid $green;
  .Toastify__toast-icon {
    // background-image: url('./../assets//icons/states/success.png');
  }
}

.Toastify__toast--error {
  background: #ffebeb;
  color: $red;
  border-left: 0.25rem solid $red;
  .Toastify__toast-icon {
    // background-image: url('./../assets//icons/states/failed.png');
  }
}
.Toastify__toast--warning {
  background: #fff6e3;
  color: $yellow;
  border-left: 0.25rem solid $yellow;
  .Toastify__toast-icon {
    // background-image: url('./../assets//icons/states/warning.png');
  }
}
.Toastify__toast-icon {
  height: 1.5rem;
  width: 1.5rem;
  svg {
    display: none;
  }
}
