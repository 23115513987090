@import '../../styles/variables';

.wrapper {
  position: relative;
  border-radius: 1.563rem;
  z-index: 3;
  .container {
    position: relative;
    padding: 0.313rem;
    border-radius: 1.563rem;
    background-color: transparent;
    z-index: 2;
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1.25rem;
      background-color: $white;
      padding: 0.313rem 0.75rem 0.25rem 0.75rem;
      box-shadow: inset 0 4px 4px rgba(#000, 0.25);
      height: 2.5rem;
      background: #f8f8f8;
      width: 100%;
      .searchIcon {
        margin: 0.563rem 0.438rem 0.313rem 0.188rem;
        img {
          width: 1.563rem;
          height: 1.563rem;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .searchText {
        width: 100%;
        input {
          color: $black;
          box-sizing: border-box;
          outline: none;
          border: none;
          font-size: 1.125rem;
          line-height: 1rem;
          letter-spacing: 0.02em;
          text-align: left;
          width: 100%;
          background: #f8f8f8;
        }
        &:focus,
        &:active {
          outline: none;
        }
      }
    }
    &:focus-within {
      background: #f3f3f3;
      border-radius: 1.563rem;
    }
  }
}
