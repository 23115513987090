@import '../../styles/variables';
.wrapper {
  height: 100%;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1.875rem;
    overflow: hidden;
  }
  .flipContainer {
    position: relative;
    .bottomBtn {
      cursor: pointer;
      position: absolute;
      bottom: 2.5rem;
      margin: auto;
      z-index: 100;
      opacity: 0;
      transition: opacity cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.2s;
      box-shadow: 0 1px 4px 2px rgba($black, 0.25);
      border-radius: 50%;
      width: 2.5rem;
      &.left {
        left: 1.25rem;
      }
      &.right {
        right: 1.25rem;
        &.faIcon {
          font-size: 1.375rem;
          line-height: 1.375rem;
          background: white;
          color: $pink;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 0.075rem;
          padding-top: 0.075rem;
        }
      }
    }
    .smallCardsLeft {
      @extend .bottomBtn;
      width: 1.5rem;
      &.left {
        left: 0.625rem;
        bottom: 0.625rem;
      }
    }
    .smallCardsRight {
      @extend .bottomBtn;
      &.right {
        right: 0.625rem;
        bottom: 0.625rem;
        &.faIcon {
          font-size: 0.8rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    &:hover {
      .bottomBtn {
        opacity: 1;
      }
    }
  }
}
