@import 'styles/variables';

.wrapper {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 2rem;
  .filter {
    display: flex;
    align-items: center;
    position: absolute;
    width: 15rem;
    max-width: 33%;
    height: 2.5rem;
    input {
      width: 15rem;
      height: 100%;
      background-color: #ededed;
      border-radius: 2rem;
      border: 0px;
      outline: none;
    }

    input:disabled {
      cursor: not-allowed;
    }
  }
  .selectedContent {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    .emptySelection {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33.875rem;
    }
    .wrapPack {
      cursor: pointer;
      margin: 1rem;
      &.backgroundSelection {
        &:hover {
          background: rgba(255, 255, 255, 0.2);
          opacity: 0.5;
        }
      }
    }
  }
}
