@import 'styles/_variables';

.activeContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.625rem;
  h6 {
    text-align: left;
    font-size: 1.25rem;
    font-weight: 700;
    color: $black;
    padding: 0.313rem 0 0.313rem 0.563rem;
    font-family: $font-roboto;
  }
  h3 {
    margin: unset;
    font-family: $font-roboto;
  }
  p {
    font-size: 14;
    font-weight: 300;
    line-height: 1.5rem;
    color: $black;
    text-align: justify;
    margin: unset;
    font-family: $font-roboto;

    a,
    a:hover {
      color: blue;
    }
  }
}
.headerFont {
  font-size: 1.5rem;
}
.avatarSize {
  width: 3.25rem;
  height: 3.25rem;
}
.backFields {
  padding: 0 0.625rem 0.938rem 0.625rem;
  margin-bottom: 3.75rem;
  margin-top: 1.563rem;
  width: 100%;
}
