.wrapper {
  margin-bottom: 2rem;
}

.cardBody {
  background: #fff !important;
  padding: 0.625rem;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.25rem;
  padding: 0.813rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.avatar {
  width: 6.563rem;
  height: 6.563rem;
}

.counter {
  margin-top: -0.625rem;
  z-index: 20;
  height: 1.25rem;

  > div > div {
    font-size: 0.563rem !important;
    font-weight: 700;
    width: 0.938rem !important;
  }
}

.fullname {
  color: #fff;
  font-size: 1.75rem;
  line-height: 1.875rem;
  font-weight: 700;
  margin-top: 0.563rem;
  padding: 0;
}

.username {
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 300;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.75rem;

  > p {
    width: 60%;
    font-size: 1rem;
    line-height: 0.938rem;
    font-style: italic;
    font-weight: 300;
    letter-spacing: 0.02em;
  }

  > a {
    color: #0e0e0e;
    font-size: 0.875rem;
    line-height: 0.938rem;
    letter-spacing: 0.02em;
    margin: 0.625rem 0;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0.875rem;

    > a {
      color: #0e0e0e;
      font-size: 1.125rem;
      line-height: 0.938rem;
      font-weight: 300;
    }
  }
}

.background_blue {
  background: linear-gradient(180deg, #4fa4f3 0%, #1d6f9c 100%);
}
.background_orange {
  background: linear-gradient(180deg, #fdaf19 0%, #de3e0b 100%);
}
.background_green {
  background: linear-gradient(258.47deg, #dddb4c -11.21%, #9bb111 128.95%);
}

@media only screen and (max-width: 480px) {
  .wrapper {
    .wrapper {
      width: 14rem;
      & > div {
        height: 22rem;
        .container {
          .avatar {
            width: 4.5rem;
            height: 4.5rem;
          }
          .fullname {
            font-size: 1.4rem;
          }
          .username {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
