.gridWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 3rem 1fr auto;
  grid-row-gap: 2rem;
  padding: 0 2rem;

  .upperRow {
    grid-area: 1 / 1 / 2 / 4;
    display: flex;
    justify-content: center;
  }
  .leftMiddleRow {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    justify-content: flex-start;
  }
  .title {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
    justify-content: center;
    text-transform: capitalize;
  }
  .rightMiddleRow {
    grid-area: 2 / 3 / 3 / 4;
    display: flex;
    justify-content: flex-end;
  }
  .bottomRow {
    grid-area: 3 / 1 / 4 / 4;
    display: flex;
    position: relative;
  }
}
