@import 'styles/mixins';

.chatWrapper {
  padding: 0 1.563rem 1.563rem;
  .chatTitle {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1.625rem;
    text-align: left;
    display: flex;
    align-items: center;

    .backImg {
      margin-right: 1rem;
    }
  }
  .chatColumnsWrapper {
    display: flex;
    justify-content: space-between;
    .friendsList {
      background-color: $gray280;
      padding: 0;
      border-radius: 2.5rem;
      width: 32%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      height: calc(45vh + 6.5rem);
      .marginUnsetForTititle {
        h6 {
          margin: unset;
        }
      }
      .newMessageWrapper {
        z-index: 10;
        position: relative;
        .newMessageBtn {
          width: calc(100% - 2rem);
          margin: 1rem;
          margin-bottom: 1.688rem;
        }
        .showFriendList {
          position: absolute;
          width: calc(100% - 2rem);
          left: 1rem;
          top: 3.5rem;
          border-radius: 1.875rem;
          background-color: $white;
          z-index: -1;
          height: 0;
          opacity: 0;
          transition: height 0.4s ease-in-out, opacity 0.2s ease-in-out;
          padding-top: 1.5rem;
          overflow: hidden;
          &.active {
            max-height: 25vh;
            opacity: 1;
            height: auto;
          }
          .newChatListScrollable {
            padding: 0.2rem 1rem;
            width: 100%;
            max-height: calc(25vh - 3rem);
            @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.475rem);
            .wrapList {
              @extend .messageDetails;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0.25rem;
              & > div {
                display: flex;
              }
              &:hover {
                background-color: $matureDisable;
                border-radius: 1.875rem;
                cursor: pointer;
              }
              h6 {
                @extend .senderName;
                margin-left: 1.25rem;
                font-size: 0.875rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 300;
              }
            }
          }
        }
      }
      .friendsListScrollable {
        @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.475rem);
        max-height: 43vh;
        padding: 0.5rem;
        gap: 0;
        .friendsListRow {
          display: flex;
          align-items: center;
          margin-bottom: 0.25rem;
          padding: 0.3125rem;
          cursor: pointer;
          & > div {
            height: 3.5rem !important;
            & > img {
              width: 3.5rem !important;
              height: 3.5rem !important;
            }
          }
          .messageDetails {
            margin-left: 1.25rem;
            width: 100%;
            overflow: hidden;
            .senderName {
              font-size: 0.975rem;
              font-weight: 400;
              font-family: 'Roboto-medium';
            }
            .messagePreview {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 0.6875rem;
              margin-top: 0.313rem;
            }
            .unreadMessageSender {
              @extend .senderName;
              font-weight: 700;
            }
            .unreadMessageData {
              @extend .messagePreview;
              font-weight: 700;
            }
            .messageDate {
              font-size: 0.825rem;
              margin: 0;
            }
          }
        }
        .activeRow {
          @extend .friendsListRow;
          background-color: $matureDisable;
          border-radius: 1.875rem;
        }
      }
    }
    .messageList {
      @extend .friendsList;
      width: 66%;
      padding: 1rem;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      .messagesContentWrapper {
        height: 45vh;
        overflow: auto;
        overflow-x: hidden;
        @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.475rem);
        padding: 0.375rem;
        .messageContainer {
          position: relative;
          max-width: 95%;
          width: fit-content;
          margin: 0.25rem 0;
          &.isBussines {
            .sentMessage {
              background-color: $adminBlue !important;
            }
            .messageTime {
              border: 1px solid $adminBlue !important;
            }
          }
          .messageTime {
            position: absolute;
            top: 50%;
            font-size: 0.875rem;
            font-family: 'Roboto';
            color: $gray700;
            background: $gray200;
            padding: 0.2rem 0.5rem;
            border-radius: 1rem;
            opacity: 0;
            transition: opacity 0.2s ease-in;
            width: max-content;
          }
          &.sentMessage {
            margin-left: auto;
            .messageTime {
              left: 0;
              transform: translate(-50%, -50%);
              border: 1px solid $adminBlue;
            }
          }

          &.receivedMessage {
            margin-right: auto;
            .messageTime {
              right: 0;
              transform: translate(50%, -50%);
              border: 1px solid $matureDisable;
            }
          }
          &:hover {
            .messageTime {
              opacity: 1;
            }
          }
          .message {
            position: relative;
            padding: 0.9rem 2rem 0.5rem 2rem;
            word-break: break-word;
            p {
              font-family: 'Roboto';
              font-size: 1.1875rem;
              margin: unset;
            }
          }
          .sentMessage {
            @extend .message;
            padding-left: 3rem;
            background-color: $adminBlue;
            color: $white;
            border-top-left-radius: 2.5rem;
            border-bottom-left-radius: 2.5rem;
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
            &.topMessage {
              border-top-right-radius: 2.5rem;
              margin-top: 0.75rem;
            }
            &.bottomMessage {
              border-bottom-right-radius: 2.5rem;
            }
          }
          .receivedMessage {
            @extend .message;
            padding-right: 3rem;
            background-color: $matureDisable;
            border-top-right-radius: 2.5rem;
            border-bottom-right-radius: 2.5rem;
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
            &.topMessage {
              border-top-left-radius: 2.5rem;
              margin-top: 0.75rem;
            }
            &.bottomMessage {
              border-bottom-left-radius: 2.5rem;
            }
          }
          .cardPreviewContainer {
            height: 15rem;
            width: 10rem;
            overflow: hidden;
            position: relative;
            .cardPreviewCard {
              transform: scale(0.48);
              position: absolute;
              top: -6rem;
              cursor: pointer;
              &.sentCard {
                right: -40%;
              }
              &.receivedCard {
                left: -40%;
              }
            }
          }
          .userPreviewContainer {
            height: 15rem;
            width: 10rem;
            overflow: hidden;
            position: relative;
            .userPreviewUser {
              transform: scale(0.48);
              position: absolute;
              top: -45%;
              &.sentUser {
                right: -40%;
              }
              &.receivedUser {
                left: -40%;
              }
            }
          }
        }
        .packPreviewContainer {
          height: 16rem;
          overflow: hidden;
          position: relative;
          .packPreviewPack {
            transform: scale(0.48);
            position: absolute;
            top: -50%;
            cursor: pointer;
            &.sentPack {
              right: -10%;
            }
            &.receivedPack {
              left: -10%;
            }
          }
        }
      }
      .messagesFooterWrapper {
        display: flex;
        align-items: center;
        padding-top: 1.25rem;
        width: 100%;
        flex: 1;
        .addCardBtn {
          margin-right: 0.625rem;
          width: 3.125rem;
          &:hover {
            cursor: pointer;
          }
        }
        .messageInputWrapper {
          display: flex;
          align-items: center;
          flex: 1;
          background-color: $matureDisable;
          border-radius: 1.5625rem;
          .messageInputContainer {
            flex: 1;
          }
          .messageInput {
            background-color: $matureDisable;
            padding-right: 0.625rem;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-desktops) {
  .chatWrapper {
    .chatColumnsWrapper {
      .friendsList {
        width: 100%;
      }
      .messageList {
        .messagesContentWrapper {
          .messageContainer {
            .userPreviewContainer {
              .userPreviewUser {
                top: -20%;
                transform: scale(0.5);
                &.sentUser {
                  right: -40%;
                  top: -35%;
                  height: 27rem;
                }
                &.receivedUser {
                  left: -41%;
                  height: 27rem;
                  top: -35%;
                }
              }
            }
          }
        }
        .messagesContentWrapper {
          .packPreviewContainer {
            .packPreviewPack {
              top: 10%;
              transform: scale(0.8);
              &.sentPack {
                right: 9%;
                top: 10%;
                transform: scale(1.3);
              }
              &.receivedPack {
                left: 9%;
                top: 10%;
                transform: scale(1.3);
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-phones) {
  .chatWrapper {
    .chatColumnsWrapper {
      .messageList {
        margin: 0;
        .messagesContentWrapper {
          .messageContainer {
            .userPreviewContainer {
              .userPreviewUser {
                top: -20%;
                transform: scale(0.49);
              }
            }
          }
        }
        .messagesContentWrapper {
          .packPreviewContainer {
            .packPreviewPack {
              top: 10%;
              &.sentPack {
                right: 9%;
                top: 10%;
                transform: scale(1.3);
              }
              &.receivedPack {
                left: 9%;
                top: 10%;
                transform: scale(1.3);
              }
            }
          }
        }
      }
    }
    .chatTitle {
      padding: 0 2rem;
      .backImg {
        height: 2rem;
      }
    }
    .chatColumnsWrapper {
      .friendsList {
        width: 100%;
        .newMessageBtn {
          height: 4rem;
          border-radius: 3rem;
          span {
            font-size: 2rem;
          }
        }
      }
    }
  }
  .friendsListRow {
    & > div {
      & > div {
        & > img {
          width: 5.5rem !important;
          height: 5.5rem !important;
        }
      }
    }
  }
}
