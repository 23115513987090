@import 'styles/variables';

.mediaPicker {
  position: relative;
  border: 0.063rem dashed #000000;
  background-color: #f8f6f1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  img.previewImage {
    width: 100%;
  }
  img.noImageIcon {
    margin: 3.125rem 0;
  }
}

.mediaPickerXS {
  @extend .mediaPicker;
  height: 10.625rem;
  img {
    width: 3.563rem;
    height: 3.438rem;
  }
}

.prevFoto {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  display: flex;
  left: 1rem;
  top: 1rem;
}
.autoHeight {
  height: auto;
}
.cardCover {
  border: none;
  height: 100%;
  background-size: cover;
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  .closeIcon {
    left: auto;
    right: 1.2rem;
    top: 1.2rem;
    z-index: 10;
    width: 2rem;
    height: 1.5rem;
  }
  img.previewImage {
    height: 100%;
    object-fit: cover;
  }
}
.categoryMediaPicker {
  @extend .cardCover;
  .closeIcon {
    right: 1rem;
    top: 1rem;
    z-index: 1;
  }
}
.halfCard {
  border: none;
  height: 60%;
  background-size: cover;
  border-radius: 2.5rem;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
  .closeIcon {
    left: auto;
    right: 1.2rem;
    top: 1.2rem;
    z-index: 10;
    width: 2rem;
    height: 1.5rem;
  }
  img.noImageIcon {
    margin-top: 30%;
  }
  img.previewImage {
    height: 100%;
    object-fit: cover;
    border-radius: 2.5rem;
  }
}
.doubleCard {
  border: none;
  background-size: cover;
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  input {
    z-index: 1;
  }
  .noImageIcon {
    position: absolute;
    right: 25%;
    transform: translateX(50%);
  }
  .closeIcon {
    left: auto;
    right: 1.2rem;
    top: 1.2rem;
    z-index: 10;
    width: 2rem;
    height: 1.5rem;
  }
  img.previewImage {
    height: 100%;
    object-fit: cover;
    border-radius: 2.5rem;
  }
}
.packPicker {
  background: transparent;
  border: none;
  margin: 3.75rem 1.875rem 0 1.875rem;
  height: calc(50% - 3.75rem);
  width: calc(100% - 3.75rem);
  .noImageIcon {
    margin-top: 7.5rem !important;
  }
  .previewImage {
    border-radius: 1.25rem;
    max-width: 100%;
    object-fit: contain;
    width: auto !important;
    max-height: 100%;
  }
}

.imgResize {
  box-sizing: content-box;
  position: fixed;
  z-index: 99;
  top: 1rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 1.875rem;
  box-shadow: inset 0 0 3px $gray700;
  width: 67rem;
  height: 40rem;
  .resizeClose {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1.5rem;
    background: white;
    padding: 0.188rem;
    cursor: pointer;
  }
}

@media only screen and (max-width: $breakpoint-web-mobile) {
  .mediaPicker {
    img.noImageIcon {
      margin: 8.125rem 0;
    }
  }
  .imgResize {
    width: 90%;
    .resizeClose {
      height: 2rem;
      width: 2rem;
    }
  }
}
