@import '../../styles/variables';
@import '../../styles/mixins';

.primaryTable {
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  width: 98%;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  min-height: 50vh;
  .tableScrollable {
    overflow: auto;
    width: 100%;
    @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.675rem);
    table {
      min-width: 100%;
      font-family: 'Roboto';
      letter-spacing: 0.02rem;
      border-spacing: 1px 0.5rem;
      thead {
        font-family: 'Roboto-bold';
        font-weight: 700;
        tr {
          white-space: nowrap;
          th {
            padding: 0.5rem;
            &.draggable {
              cursor: grab;
            }
          }
        }
      }
      tbody {
        tr {
          white-space: nowrap;
          td {
            background-color: $gray280;
            height: 2.5rem;
            padding: 0.25rem 1rem;
            &:first-child {
              border-top-left-radius: 5rem;
              border-bottom-left-radius: 5rem;
            }
            &:last-child {
              border-top-right-radius: 5rem;
              border-bottom-right-radius: 5rem;
            }
          }
        }
      }
    }
  }
}
