@import '../../../styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  > div {
    width: 100%;
  }

  h2 {
    color: #00aee5;
  }

  h3 {
    margin: 0;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #00aee5;
    cursor: pointer;
  }

  button {
    width: 100%;
    margin-top: 1rem;
  }

  input {
    text-align: center;
    margin: 2rem 0;

    &::placeholder {
      text-align: center;
    }
  }
  p {
    margin-bottom: 1.25rem;
    text-align: center;
  }
  .loginEmail {
    text-align: center;
  }
  .loginEmail::placeholder {
    margin-left: -3rem;
  }
}
