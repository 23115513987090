@import 'styles/variables';
@import 'styles/mixins';

.trendingWrapper {
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  .packContentPosition {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    margin-top: 0.375rem;
    .checkBox {
      display: flex;
      align-items: center;
      margin-left: 1rem;
    }
  }
  .newPack {
    position: absolute;
    bottom: 2rem;
    left: calc(50% - 0.625rem);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    .btn {
      height: auto;
      background: transparent;
      padding: 0.5rem 1.563rem;
      border-radius: 0;
      &:hover {
        background-color: unset;
      }
      & > span {
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 1.375rem;
        letter-spacing: 0.02em;
        z-index: 1;
        color: $gray950;
      }
      &.blue {
        & > span {
          color: $adminBlue;
        }
      }
      &.disabled {
        span {
          color: $gray300;
        }
        text-shadow: none;
        cursor: not-allowed;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .filter {
    display: flex;
    margin: 0 2rem;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;

    .third {
      width: 15rem;
      max-width: 33%;
      height: 2.5rem;

      input {
        margin-left: 1.438rem;
        width: 15rem;
        height: 100%;
        background-color: #ededed;
        border-radius: 2rem;
        border: 0px;
        outline: none;
      }

      input:disabled {
        cursor: not-allowed;
      }

      .selectGrayNoMargin {
        width: 19rem;
      }
    }
  }
  .checkbox {
    display: flex;
    span {
      margin-left: 1rem;
    }
  }

  .cardsDiv {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    width: 98%;
    margin: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 50vh;
    @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.675rem);

    .savedOrDeletedCards {
      display: flex;
      flex-direction: column;

      .cards {
        padding: 2rem;
        display: flex;
        gap: 2.4rem;
        flex-wrap: wrap;
      }
    }
  }

  .cardsDivSlider {
    @extend .cardsDiv;
    overflow: hidden;
  }
}
