.singleCampainWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1rem 0;

  .content {
    height: 2.75rem;
    width: 96%;
    border-radius: 2rem;
    background-color: #ededed;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    justify-content: space-between;
    font-size: 1.3rem;
    padding-left: 3rem;
    .innerContent {
      display: flex;
      width: 80%;

      .name {
        width: 35%;
      }

      .type {
        width: 35%;
      }
    }

    .pdf {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.75rem;
      width: 9.75rem;
      border-radius: 2rem;
      padding-right: 0.5rem;
      background-color: #00aee5;
      color: white;
      cursor: pointer;
    }
  }

  .edit {
    cursor: pointer;
    img {
      margin-left: 1rem;
      width: 2rem;
      height: 2rem;
    }
  }
}
