@import 'styles/_variables';

.cardWrapper {
  padding-top: 2.4rem;
  .wrapper {
    position: relative;
    .content {
      text-align: center;
      p {
        padding-top: 2.5rem;
        padding-bottom: 0.813rem;
        font-family: $font-secondary;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: $pink;
      }
    }
    .spanContent {
      padding-left: 1.875rem;
      padding-right: 1.875rem;
      span {
        font-family: $font-secondary;
        font-weight: 300;
        letter-spacing: 0.02em;
        line-height: 1.125rem;
        font-size: 1rem;
        color: #343434;
      }
    }
    .position {
      position: relative;
      padding-top: 2rem;
      width: 100%;
      @media (max-width: 765px) {
        padding-top: unset;
      }
      @media (max-width: 1024px) {
        padding-top: 1rem;
      }
      img {
        width: 100%;
        height: 13.3rem;
      }
      .btnCard {
        width: 95%;
        bottom: 1.6rem;
        left: 0.4rem;
        position: absolute;
        justify-content: center;
        text-align: center;
      }
    }
  }
}
