.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .headerText {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }
  .inputDiv {
    display: flex;
    justify-content: center;
    width: fit-content;
    .input {
      width: 24rem;
      background-color: #ededed;
      padding-left: 2rem;
    }
    .btn {
      width: 24rem;
      margin-top: 3.5rem;
    }
  }
  .inputDivPhone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    .phone {
      border: none;
      width: 24rem;
      background-color: #ededed;
      padding-left: 2rem;
      border-radius: 1.56rem;
      height: 2.5rem;
      font-size: 1.5rem;
    }
    .phone:focus {
      outline: none;
    }
    .label {
      text-align: left;
      font-size: 1.125rem;
      color: #bdbdbd;
      margin-top: 0.8rem;
      width: 100%;
      padding-left: 2rem;
      margin: 0.5rem 0 0.25rem 0;
    }
  }
}

.confirmationModal {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 1.8rem;
    color: #cb1c68;
    text-align: center;
    padding-top: 1.4rem;
    padding-bottom: 2rem;
  }
  .textDiv {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
    text-align: center;
    padding-bottom: 4rem;
    p {
      font-size: 1.5rem;
    }
  }
  .btn {
    margin-bottom: 1.5rem;
    height: 3rem;
    width: 60%;
    display: inline;
    span {
      font-size: 1.4rem;
    }
  }
}

@media only screen and (max-width: 480px) {
  .wrapper {
    padding-left: 0rem;
    padding-right: 0rem;
    .headerText {
      font-size: 1.7rem;
      padding-bottom: 1.5rem;
    }
    .btn {
      height: 3rem;
      span {
        font-size: 1.5rem;
      }
    }
  }
}
