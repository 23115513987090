@import '../../styles/variables';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  width: fit-content;
  cursor: pointer;
  .logo {
    img {
      height: 100%;
      border-radius: 50%;
    }
  }
  &.search {
    gap: 0.813;
    .logo {
      height: 2.563rem;
      width: 2.563rem;
    }
    .content {
      color: $black;
      .title {
        font-size: 1.125rem;
        line-height: 1rem;
        letter-spacing: 0.02em;
      }
      .subtitle {
        display: none;
      }
    }
  }
  &.heading {
    gap: 0.414rem;
    .logo {
      width: 3.262rem;
      height: 3.262rem;
    }
    .content {
      display: flex;
      flex-direction: column;
      color: $white;
      .title {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.875rem;
      }
      .subtitle {
        font-weight: 300;
        font-size: 1.125rem;
        line-height: 1.375rem;
      }
    }
  }
  &.share {
    flex-direction: column;
    gap: 0.555rem;
    .logo {
      width: 2.563rem;
      height: 2.5rem;
    }
    .content {
      text-align: center;
      color: $black;
      .title {
        font-size: 1.125rem;
        line-height: 1.375rem;
        letter-spacing: 0.02em;
      }
      .subtitle {
        display: none;
      }
    }
  }
}
