@import 'styles/mixins';

.wrapper {
  padding: 0.625rem;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.188rem 6.25rem 0.313rem 6.25rem;
    .saveBtn {
      margin-left: 2.5rem;
    }
    .btnAddNewCard {
      position: absolute;
      right: 5%;
    }
  }
  .positionBtn {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
  }
  .switchContent {
    width: 100%;
    height: 42.75rem;
    .packCreate {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 3.125rem;
      padding: 1.25rem 6.25rem;
      .form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1.25rem;
        width: calc(100% - 22.5rem);
        max-width: 40.625rem;
        height: 90%;
        margin-top: 10%;
        .colorPickerBtn {
          color: $labelGray;
        }
        .colorPickerContainer {
          width: 100%;
          background: $inputGray;
          height: 2.5rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 0.625rem;
          border-radius: 1.563rem;
          > div {
            width: 100% !important;
            span {
              > div {
                width: 1.2rem !important;
                height: 1.2rem !important;
              }
            }
          }
        }
        .headerDivCreation {
          display: flex;
          align-items: center;
          width: 100%;
          img {
            height: 2rem;
            display: flex;
            justify-content: flex-start;
          }
          p {
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 1.7rem;
            line-height: 1.875rem;
            letter-spacing: 0.02em;
            color: #323131;
          }
        }
      }
    }
    .packPreview {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 3.125rem;
      padding: 1.25rem 6.25rem;
      &.test {
        flex-direction: column !important;
      }
    }
  }
  .packSelection {
    &.container {
      display: flex;
      padding: 1.25rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      .headerTitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 1.25rem;
        .SelectedCards {
          font-size: 1.7rem;
          line-height: 1.875rem;
          letter-spacing: 0.02em;
          color: $gray950;
          padding-left: 1.563rem;
        }
        .createBtn {
          span {
            font-size: 1.125rem;
            line-height: 1rem;
            letter-spacing: 0.02em;
            color: $white;
            padding: 0;
          }
        }
        .addNewCardBtn {
          min-height: 2.5rem;
          height: 2.5rem;
        }
      }
      .selectedCardsContainer {
        width: 100%;
        height: 33.75rem;
        overflow: hidden;
        .scrollableContent {
          height: 100%;
          padding: 0.625rem 1.25rem;
          flex-direction: row;
          flex-wrap: wrap;
          row-gap: 0;
          column-gap: 1.25rem;
          overflow-x: auto;
          overflow-y: hidden;
          @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.625rem);
          &::after {
            content: '';
            flex: auto;
          }
          .mobileCards {
            display: flex;
            flex-wrap: wrap;
            padding-top: 3rem;
            row-gap: 1rem;
            column-gap: 0.1rem;
            & > div {
              padding-right: 0.6rem;
            }
          }
        }
      }
      .mobileBtns {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 2rem;
        .createBtnDown {
          padding: 0.5rem 5.648rem;
        }
      }
    }
  }
  .wrapFooter {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .footer {
      display: flex;
      padding-bottom: 1.438rem;
      max-width: 50rem;
      span {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 1.125rem;
        align-items: center;
        justify-content: center;
        word-break: break-word;
        strong {
          font-size: 1.125rem;
          font-family: 'Roboto';
          padding-right: 0.5rem;
        }
      }
      .fixMargins {
        & > div {
          h3 {
            margin-top: unset;
          }
          & > label {
            margin-top: unset;
          }
        }
      }
    }
  }
  .publishBtn {
    white-space: nowrap;
    font-size: 1.125rem;
    line-height: 1rem;
    letter-spacing: 0.02em;
    font-weight: 400;
    padding: 0.5rem 7rem;
  }
}

.cardShare {
  padding-top: 3.7rem;
  transform: scale(1.8);
}

.addNewCard {
  box-sizing: content-box;
  position: absolute;
  z-index: 99;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  background: white;
  padding: 0;
  border-radius: 1.875rem;
  box-shadow: inset 0 0 3px $gray700;
  .closeAddNewCard {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 2rem;
    background: white;
    padding: 0.188rem;
    cursor: pointer;
  }
}

.mobileUpdate {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 2rem;
}

@media only screen and (max-width: $breakpoint-desktops) {
  .switchContent {
    height: auto !important;
    .packCreate {
      flex-direction: column-reverse !important;
      margin-top: 1.875rem !important;
      .form {
        width: 100% !important;
        transform: none !important;
      }
    }
    .packPreview {
      flex-direction: column-reverse !important;
    }
  }
}
@media only screen and (max-width: $breakpoint-tablets) {
  .wrapper {
    padding: 0.625rem;
    .header {
      justify-content: space-around;
      padding: 2.1875rem 3.125rem 0.3125rem 3.125rem;
    }
    .switchContent {
      .packCreate {
        padding: 1.25rem 1.875rem;
        .form {
          .colorPickerContainer {
            height: auto;
            padding: 0.625rem;
          }
        }
      }
      .packPreview {
        padding: 1.25rem 1.875rem;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-phones) {
  .wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
    .switchContent {
      .packCreate {
        gap: 1rem;
        .form {
          margin-top: 0rem;
          gap: 0.8rem;
          .colorPickerContainer {
            > div {
              width: 100% !important;
              span {
                > div {
                  padding-left: 1rem;
                  width: 1.2rem !important;
                  height: 1.2rem !important;
                }
              }
            }
          }
        }
      }
      .packCreate {
        margin-top: 0rem !important;
        padding: 0;
      }
    }
    .saveBtnMobile {
      margin-top: 1rem;
    }
    .packSelection {
      &.container {
        gap: 0rem;
        padding: 0.75rem;
        .scrollableContent {
          transform: scale(0.8) translate(-10%, -12%);
          width: 120%;
          height: 125% !important;
        }
        .headerTitle {
          .createBtn {
            width: 100%;
          }
        }
        .selectedCardsContainer {
          .scrollableContent {
            padding: 0;
          }
        }
      }
    }
    .selectedCardsContainer {
      padding-bottom: 1rem;
      .scrollableContent {
        padding: 0;
      }
    }
  }

  .cardShare {
    position: relative;
    top: 6rem;
    transform: scale(1.65);
    padding: 0;
  }
}
