@import '/src/styles/_variables.scss';
.wrapper {
  width: calc(100% - 2.5rem);
  height: calc(100% - 2.5rem);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-sizing: content-box;
  padding: 1.25rem;
  background: white;
  border-radius: 3.75rem;
  .headerTitle {
    font-size: 2rem;
    color: $gray950;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.02em;
    cursor: default;
  }
  .searchContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    .search {
      width: 31.25rem;
      & > div {
        & > div {
          box-shadow: inset 0px 4px 4px rgba($black, 0.25);
          input {
            &::placeholder {
              color: $labelGray;
              font-weight: 400;
              font-size: 1.125rem;
              line-height: 1rem;
              letter-spacing: 0.02em;
            }
          }
        }
      }
    }
    .backBtn {
      position: absolute;
      left: 0;
      height: 2.5rem;
      margin-top: unset;
      span {
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1rem;
        letter-spacing: 0.02em;
      }
    }
    .addBtn {
      position: absolute;
      right: 0;
      height: 2.5rem;
      margin-top: unset;
      &.disabled {
        background-color: $inputGray;
        cursor: not-allowed;
      }
      span {
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1rem;
        letter-spacing: 0.02em;
      }
    }
  }
  .cardsContainer {
    margin: 1.25rem 0 0.313rem 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 1.875rem;
    row-gap: 2.813rem;
    padding: 2.25rem 0.625rem;
    .cardContainer {
      position: relative;
      transition: all 0.2s ease-out;
      &.selected {
        transform: translateY(-0.625rem) scale(1.05);
        opacity: 0.5;
        &:hover {
          transform: translateY(-0.625rem) scale(1.05);
        }
      }
      &:hover {
        transform: translateY(-0.625rem);
        cursor: pointer;
      }
      .cardCoverField {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: transparent;
        width: calc(100% + 0);
        height: calc(100% + -2.5rem);
        z-index: 1;
        margin: 1.25rem 0;
      }
    }
    &::-webkit-scrollbar-track {
      background: lighten($createBorder, 35%);
      border-radius: 2.5rem;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 0.625rem;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $pink;
      border-radius: 2.5rem;
    }
    //   SCROLL COLOR
    &::-webkit-scrollbar-track {
      background: $inputGray;
    }
  }
}
