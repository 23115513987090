.container {
  box-shadow: 0px 0px 1px rgba(75, 77, 82, 0.48), 0px 0.5rem 1rem #4b4d523d;
  border-radius: 2.5rem;
  width: 80%;
  margin: auto;
  max-height: 80%;
  padding: 5rem 1rem 3rem 1rem;
  display: flex;
  justify-content: space-around;

  .rightSide {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .avatar {
      width: 100%;
      display: flex;
      justify-content: center;

      .myAvatar {
        width: 5.5rem;
        height: 5.5rem;

        input {
          cursor: pointer;
        }
      }
    }

    button {
      width: 22.5rem;
      margin: 1rem auto;

      span {
        font-size: 0.9rem;
      }
    }
  }
}
