@import 'styles/variables';
@import 'styles/mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.938rem;
  gap: 0.625rem;
  .row {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    gap: 0.938rem;
    padding-bottom: 1.125rem;
    padding-left: 1.25rem;
    display: none;
    &.active {
      display: flex;
    }
    .contentWrapper {
      flex: auto;
      display: flex;
      justify-content: center;
      width: 35.313rem;
      max-height: 34rem;
      overflow: auto;
      align-items: flex-start;
      @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.625rem);
    }
  }

  .defaultToggle {
    h3 {
      margin-top: 0.625rem;
    }
    label {
      margin-top: 1.063rem;
    }
  }
  .contentHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    padding-top: 1.125rem;
    .positionUpdateBtn {
      position: absolute;
      right: 8rem;
      top: 3.4rem;
      width: fit-content;
    }
    .positionBack {
      position: absolute;
      left: 2.5rem;
      cursor: pointer;
      img {
        width: 50%;
        height: 50%;
      }
    }
  }
}
.prevWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  display: none;
  position: relative;
  &.active {
    display: flex;
  }
  .prevContent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1.875rem;
    gap: 1rem;
    &.doubleContent {
      transform: scale(0.9);
    }
  }
  .saveBtn {
    white-space: nowrap;
    font-size: 1.125rem;
    line-height: 1rem;
    letter-spacing: 0.02em;
    padding: 0.5rem 1.938rem;
  }
}
@media only screen and (max-width: 1100px) {
  .container {
    .row {
      align-items: center;
      flex-direction: column-reverse;
      .contentWrapper {
        width: 100%;
      }
    }
    .contentHeader {
      text-align: center;
      justify-content: center;
    }
  }
  .prevWrapper {
    .prevContent {
      flex-direction: column;
      align-items: center;
    }
  }
}
@media only screen and (min-width: 1100px) {
  .prevWrapper {
    .prevContent {
      &.doubleContent {
        transform: scale(0.75);
      }
    }
  }
}
@media only screen and (min-width: $breakpoint-wide-screens) {
  .prevWrapper {
    .prevContent {
      &.doubleContent {
        transform: scale(0.8);
      }
    }
  }
}
@media only screen and (max-width: 1220px) {
  .container {
    .contentHeader {
      padding-right: 0.625rem;
    }
  }
}
@media only screen and (min-width: 1300px) {
  .prevWrapper {
    .prevContent {
      &.doubleContent {
        transform: scale(0.9);
      }
    }
  }
}

.doubleMobileCard {
  > div:first-child {
    transform: scale(0.7);
    margin-left: -1rem;
  }
}
.mobileBtns {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 2rem;
}
.wrapFooter {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .explanation {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 1.125rem;
    align-items: center;
    word-break: break-word;
    margin: 0 0 2rem 0;
    display: flex;
    justify-content: center;

    span {
      color: #00aee5;
      text-decoration: underline;
      cursor: pointer;
    }

    .innerDiv {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
      border-radius: 2rem;
      width: fit-content;
      padding: 1rem;

      .closeExp {
        display: flex;
        justify-content: flex-end;

        img {
          height: 1.5rem;
          width: 1.5rem;
          cursor: pointer;
          margin: 0 0 1rem 0;
        }
      }
    }
  }

  .footer {
    display: flex;
    padding-bottom: 1.438rem;
    max-width: 50rem;
    span {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 1.125rem;
      align-items: center;
      justify-content: center;
      word-break: break-word;
      strong {
        font-size: 1.125rem;
        font-family: 'Roboto';
        padding-right: 0.5rem;
      }
    }
    .fixMargins {
      & > div {
        h3 {
          margin-top: unset;
        }
        & > label {
          margin-top: unset;
        }
      }
    }
  }
}
.cardShare {
  padding-top: 3.7rem;
  transform: scale(1.8);
}
.publishBtn {
  white-space: nowrap;
  font-size: 1.125rem;
  line-height: 1rem;
  letter-spacing: 0.02em;
  font-weight: 400;
}

@media only screen and (max-width: $breakpoint-web-mobile) {
  .cardShare {
    position: relative;
    top: 6rem;
    transform: scale(1.7);
    padding: 0;
  }
}

@media only screen and (max-width: $breakpoint-web-mobile) {
  .prevWrapper {
    .prevContent {
      &.doubleContent {
        transform: scale(0.9);
        & > div:nth-child(2) {
          margin-right: 0rem;
        }
      }
    }
  }
}
