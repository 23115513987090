@import 'styles/variables';

.pagination {
  display: flex;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      list-style: none;
      padding: 1rem;
      background: $gray280;
      border-radius: 5rem;
      cursor: pointer;
      &.dots {
        background: white;
        letter-spacing: 0.1rem;
        padding-top: 2rem;
        font-size: 2rem;
        color: lightgray;
        cursor: default;
      }
      &.activePage {
        background: $adminBlue;
        color: white;
      }
    }
  }
}
