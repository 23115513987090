@import '/src/styles/variables';

.wrapperSingleApplicant {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin: 0.5rem auto;
  background-color: $gray280;
  border-radius: 5rem;
  height: 2.5rem;
  padding: 0.25rem;

  .singleCell {
    width: 19%;
    word-break: break-all;
    margin: 0 1rem;

    &:first-child {
      padding-left: 2.5rem;
    }
  }

  button {
    height: 2rem;
    margin: 0;
  }
}