@import 'styles/_variables';
@import 'styles/_mixins';

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  border-bottom: 2px solid white;
  &:last-child {
    border-bottom: none;
  }
  .rightContainer {
    margin-left: 1.25rem;
    width: 100%;
    overflow: hidden;
    .nameAndActionContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      padding-right: 1rem;
      .author {
        font-size: 0.975rem;
        font-weight: 400;
        font-family: 'Roboto-medium';
        font-weight: 400;
        margin: 0.5rem;
      }
      .actionText {
        font-size: 0.875rem;
        letter-spacing: 0.02rem;
        font-family: 'Roboto-bold';
        font-weight: 500;
        text-align: right;
        padding-bottom: 0.0875rem;
        &.disable {
          color: $gray500;
        }
        &.enable {
          color: $adminBlue;
        }
      }
    }
    .reason {
      word-break: normal;
      font-size: 0.6875rem;
      margin: 0.1875rem 0;
      font-family: 'Roboto';
      font-weight: 300;
      max-height: 6.5rem;
      overflow-y: auto;

      @include changeScrollColor(lighten($gray950, 65%), lighten($gray280, 2.5%), 0.25rem);
      &:hover {
        @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.25rem);
      }
    }
    .time {
      font-size: 0.825rem;
      margin: 0;
      color: $gray500;
      text-align: right;
      padding: 0.25rem 0;
      padding-right: 1rem;
      font-family: 'Roboto-bold';
    }
  }
}
