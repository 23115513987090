@import '../../../../styles/variables';
.content {
  margin: 1.25rem 0 1.875rem 0;
  padding: 0 0.625rem;
  .dndFields {
    width: 100%;
    > div {
      height: 100%;
      > div {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 0.625rem;
        position: relative;
        padding: 0.625rem;
        .textarea {
          background-color: transparent;
          div {
            height: 100%;
            textarea {
              height: 100%;
              padding: 0.275rem 0.481rem;
              line-height: 2.375rem;
              font-size: 1.5rem;
              &::placeholder {
                color: $gray950;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
