@import 'styles/variables';

.wrapper {
  position: absolute;
  top: -0.375rem;
  z-index: -1;
  cursor: auto;
  display: none;

  &.active {
    display: block;
  }
  .top {
    width: 3.875rem;
    height: 5.625rem;
    background-color: #fff;
    border-radius: 2rem 2rem 0 0;
    z-index: 3;
  }

  .bottom {
    width: 3.125rem;
    position: absolute;
    left: 3.813rem;
    background: #fff;
    top: 1.625rem;
    height: 3.125rem;
    z-index: 3;
    clip-path: polygon(
      68% 78%,
      54% 85%,
      39% 91%,
      24% 95%,
      12% 98%,
      0 100%,
      100% 100%,
      100% 0,
      98% 22%,
      94% 36%,
      88% 52%,
      80% 66%
    );
    transform: rotate(90deg);
  }
  .bottom_right {
    left: unset;
    right: 3.8rem;
    top: 1.625rem;
    transform: none;
  }

  .content {
    position: absolute;
    min-width: 12.5rem;
    width: fit-content;
    height: fit-content;
    min-height: 6.25rem;
    background-color: #fff;
    top: 4.688rem;
    left: 0;

    border-radius: 0 2rem 2rem;
    box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.4);
    z-index: -1;

    padding: 1.438rem;
  }
  .content_right {
    @extend .content;
    right: 0;
    left: unset;
    border-radius: 2rem 0 2rem 2rem;
  }
}

.outOfModal {
  position: fixed;
  width: 200vw;
  height: 200vh;
  background-color: transparent;
  background-color: red;
  opacity: 0.5;
  top: -100vh;
  left: -100vw;
  z-index: -2;
  cursor: auto;
}

.setPaddingForModal {
  padding: 1rem;
}

@media only screen and (min-width: $breakpoint-desktops) {
  .wrapper {
    .bottom {
      left: unset;
      right: 3.875rem;
      top: 1.625rem;
      transform: none;
    }
    // .bottom_right {
    //   right: 3.85rem;
    // }

    .content {
      right: 0;
      left: unset;
      border-radius: 2rem 0 2rem 2rem;
    }
  }
}

@media only screen and (max-width: $breakpoint-web-mobile) {
  .wrapper {
    .top {
      width: 5rem;
      height: 9rem;
      border-radius: 2.6rem 2.6rem 0 0;
    }
    .bottom {
      width: 5.2rem;
      border-radius: 5%;
    }
    .content {
      display: flex;
      padding: 4rem;
      justify-content: center;
      align-items: center;
      margin-top: 0.5rem;
    }
  }
}

@media only screen and (max-width: 321px) {
  .wrapper {
    .content {
      padding: 2rem;
    }
  }
}
