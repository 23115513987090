@import 'styles/_variables';

.shareIcon {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 0.625rem;
  padding-bottom: 1.063rem;
  img {
    width: 3rem;
    height: 3rem;
  }
  .socialIcon {
    svg {
      width: 3rem;
      height: 3rem;
      margin: 0;
    }
  }
}
.shareXS {
  @extend .shareIcon;
  padding: 2.313rem 2.063rem 1.813rem 2.063rem;
  img {
    padding-right: 1.75rem;
  }
}
.paddingLastIcon {
  img {
    padding-right: 0;
  }
}
