@import '../../../../styles/variables';

.matureContent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  border: 0.625rem solid $gray280;
  border-radius: 2.5rem;
  background: rgba(black, 0.125);
  box-shadow: inset 0px -2px 5px 0px rgba(black, 0.35);
  p {
    width: 100%;
    text-align: center;
    overflow: auto;
    word-break: break-word;
    padding: 1rem 1.5rem;
    color: white;
    font-size: 1.275rem;
    font-family: 'Roboto';
    font-weight: 300;
    letter-spacing: 0.02rem;
    border-radius: 1.5rem;
    .btn {
      width: calc(100% - 2rem);
      border: 0.063rem solid $gray280;
      height: 2rem;
      background: $gray280;
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      right: 1rem;
      > span {
        color: black;
        font-weight: 300;
        font-family: 'Roboto';
        letter-spacing: 0.02rem;
        font-size: 1.25rem;
        line-height: unset;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &.matureExtraSmall {
    border: unset;
    p {
      padding: unset;
      font-size: 0.6rem;
      .btn {
        padding: unset;
        height: 1.5rem;
        > span {
          font-size: 0.7rem;
        }
      }
    }
  }
}
.container {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  // display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  padding-top: 1px;
  &.bluryContent {
    filter: blur(0.65rem);
    -webkit-filter: blur(0.65rem);
  }

  /* --- Background color for the "Sponsored" Cards --- */
  &.background-default {
    background-color: $createBg;
    color: $cardColor-standard-body-text;
  }
  &.background-bussines {
    background-color: $white;
    color: $adminBlue;
  }
  /* --- Background color for the "Sponsored" Cards --- */
  &.background-sponsored {
    background-color: $cardColor-sponsored-secondary;
    color: $cardColor-marketplace-body-text;
  }
  /* --- Background color for the "Event" Cards --- */
  &.background-event {
    background-color: $cardColor-event-secondary;
    color: $cardColor-event-body-text;
  }

  /* --- Background color for the "Poll" Cards --- */
  &.background-poll {
    background-color: $cardColor-poll-secondary;
    color: $cardColor-poll-body-text;
  }

  /* --- Background color for the "Marketplace" Cards --- */
  &.background-marketplace {
    background-color: $cardColor-marketplace-secondary;
    color: $cardColor-marketplace-body-text;
  }

  /* --- Background color for the "OneSidedTrivia" Cards --- */
  &.background-oneSidedTrivia {
    background-color: $cardColor-oneSidedTrivia-secondary;
    color: $cardColor-oneSidedTrivia-body-text;
  }

  /* --- Background color for the "doubleSidedTrivia" Cards --- */
  &.background-doubleSidedTrivia {
    background-color: $cardColor-doubleSidedTrivia-secondary;
    color: $cardColor-doubleSidedTrivia-body-text;
  }

  /* --- Background color for the "Collectible" Cards --- */
  &.background-collectible {
    background-color: $cardColor-collectible-secondary;
    color: $cardColor-collectible-body-text;
  }

  /* --- Background color for the "Mystery" Cards --- */
  &.background-mystery {
    background-color: $cardColor-mystery-secondary;
    color: $cardColor-mystery-body-text;
  }

  /* --- Background color for the "Blank" Cards --- */
  &.background-blank {
    background-color: $cardColor-blank-secondary;
    color: $cardColor-blank-body-text;
  }

  /* --- Background color for the "double" Cards --- */
  &.background-double {
    background-color: $cardColor-double-secondary;
    color: $cardColor-double-body-text;
  }

  /* --- Background color for the "Slide" Cards --- */
  &.background-slide {
    background-color: $cardColor-slide-secondary;
    color: $cardColor-slide-body-text;
  }

  /* --- Background color for the "Standard" Cards --- */
  &.background-standard {
    background-color: $cardColor-standard-secondary;
    color: $cardColor-standard-body-text;
  }

  /* --- Background color for the "General User" Cards --- */
  &.background-generalUser-standard {
    background-color: $cardColor-generalUser-secondary !important;
    color: $cardColor-generalUser-body-text !important;
  }
  /* --- Background color for the "Bisiness Subscriber - Wonder Shuffle Plus Subscribers" Cards --- */
  &.background-businessSubscriber-standard,
  &.background-businessSubscriber-slide {
    background-color: $cardColor-businessSubscriber-secondary !important;
    color: $cardColor-businessSubscriber-body-text !important;
  }
  /* --- Background color for the "Media" Cards --- */
  &.background-media-standard,
  &.background-media-slide,
  &.background-media-instacard {
    background-color: $cardColor-media-secondary !important;
    color: $cardColor-media-body-text !important;
  }

  /* --- Background color for the "Admin" Cards --- */
  &.background-admin-instacard {
    background-color: $cardColor-admin-secondary !important;
    color: $cardColor-admin-body-text !important;
  }

  /* --- Background color for the "Primerime Advertiser" Cards --- */
  &.background-primetimeAdvertiser-standard,
  &.background-primetimeAdvertiser-slide,
  &.background-primetimeAdvertiser-double,
  &.background-primetimeAdvertiser-poll,
  &.background-primetimeAdvertiser-instacard {
    background-color: $cardColor-primetimeAdvertiser-secondary !important;
    color: $cardColor-primetimeAdvertiser-body-text !important;
  }
  &.background-create {
    background-color: $createBg;
  }
  &.background-anchor {
    background-color: $standardBg;
  }
  &.background-first {
    background-color: $white;
  }
  &.size-extraSmall {
    border-radius: 0.7125rem;
  }
  &.size-normal {
    border-radius: 1.875rem;
  }
  &.size-large {
    border-radius: 2.5rem;
  }

  .loader {
    > div {
      display: flex;
      position: absolute;
      top: 0;
      z-index: 11;
      height: 100%;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
  }
}
//need this for newsletter
.containerNewsletter {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  // padding-top: 1px;
  &.bluryContent {
    filter: blur(0.65rem);
    -webkit-filter: blur(0.65rem);
  }

  /* --- Background color for the "Sponsored" Cards --- */
  &.background-default {
    background-color: $createBg;
    color: $cardColor-standard-body-text;
  }
  &.background-bussines {
    background-color: $white;
    color: $adminBlue;
  }
  /* --- Background color for the "Sponsored" Cards --- */
  &.background-sponsored {
    background-color: $cardColor-sponsored-secondary;
    color: $cardColor-marketplace-body-text;
  }
  /* --- Background color for the "Event" Cards --- */
  &.background-event {
    background-color: $cardColor-event-secondary;
    color: $cardColor-event-body-text;
  }

  /* --- Background color for the "Poll" Cards --- */
  &.background-poll {
    background-color: $cardColor-poll-secondary;
    color: $cardColor-poll-body-text;
  }

  /* --- Background color for the "Marketplace" Cards --- */
  &.background-marketplace {
    background-color: $cardColor-marketplace-secondary;
    color: $cardColor-marketplace-body-text;
  }

  /* --- Background color for the "OneSidedTrivia" Cards --- */
  &.background-oneSidedTrivia {
    background-color: $cardColor-oneSidedTrivia-secondary;
    color: $cardColor-oneSidedTrivia-body-text;
  }

  /* --- Background color for the "doubleSidedTrivia" Cards --- */
  &.background-doubleSidedTrivia {
    background-color: $cardColor-doubleSidedTrivia-secondary;
    color: $cardColor-doubleSidedTrivia-body-text;
  }

  /* --- Background color for the "Collectible" Cards --- */
  &.background-collectible {
    background-color: $cardColor-collectible-secondary;
    color: $cardColor-collectible-body-text;
  }

  /* --- Background color for the "Mystery" Cards --- */
  &.background-mystery {
    background-color: $cardColor-mystery-secondary;
    color: $cardColor-mystery-body-text;
  }

  /* --- Background color for the "Blank" Cards --- */
  &.background-blank {
    background-color: $cardColor-blank-secondary;
    color: $cardColor-blank-body-text;
  }

  /* --- Background color for the "double" Cards --- */
  &.background-double {
    background-color: $cardColor-double-secondary;
    color: $cardColor-double-body-text;
  }

  /* --- Background color for the "Slide" Cards --- */
  &.background-slide {
    background-color: $cardColor-slide-secondary;
    color: $cardColor-slide-body-text;
  }

  /* --- Background color for the "Standard" Cards --- */
  &.background-standard {
    background-color: $cardColor-standard-secondary;
    color: $cardColor-standard-body-text;
  }

  /* --- Background color for the "General User" Cards --- */
  &.background-generalUser-standard {
    background-color: $cardColor-generalUser-secondary !important;
    color: $cardColor-generalUser-body-text !important;
  }
  /* --- Background color for the "Bisiness Subscriber - Wonder Shuffle Plus Subscribers" Cards --- */
  &.background-businessSubscriber-standard,
  &.background-businessSubscriber-slide {
    background-color: $cardColor-businessSubscriber-secondary !important;
    color: $cardColor-businessSubscriber-body-text !important;
  }
  /* --- Background color for the "Media" Cards --- */
  &.background-media-standard,
  &.background-media-slide,
  &.background-media-instacard {
    background-color: $cardColor-media-secondary !important;
    color: $cardColor-media-body-text !important;
  }

  /* --- Background color for the "Admin" Cards --- */
  &.background-admin-instacard {
    background-color: $cardColor-admin-secondary !important;
    color: $cardColor-admin-body-text !important;
  }

  /* --- Background color for the "Primerime Advertiser" Cards --- */
  &.background-primetimeAdvertiser-standard,
  &.background-primetimeAdvertiser-slide,
  &.background-primetimeAdvertiser-double,
  &.background-primetimeAdvertiser-poll,
  &.background-primetimeAdvertiser-instacard {
    background-color: $cardColor-primetimeAdvertiser-secondary !important;
    color: $cardColor-primetimeAdvertiser-body-text !important;
  }
  &.background-create {
    background-color: $createBg;
  }
  &.background-anchor {
    background-color: $standardBg;
  }
  &.background-first {
    background-color: $white;
  }
  &.size-extraSmall {
    border-radius: 0.7125rem;
  }
  &.size-normal {
    border-radius: 1.875rem;
  }
  &.size-large {
    border-radius: 2.5rem;
  }

  .loader {
    > div {
      display: flex;
      position: absolute;
      top: 0;
      z-index: 11;
      height: 100%;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
  }
}

.triviaOverflow {
  overflow: auto;
}
