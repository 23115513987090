@import 'styles/variables';

.wrapper {
  width: 100%;
  padding: 1rem;
  min-height: 17rem;
  max-height: 65vh;
  overflow: hidden;
  overflow-y: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item {
    width: 25%;
    text-align: center;
  }
  .item:last-child {
    max-width: 7.75rem;
  }
  .noResultMessage {
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    font-size: 3.25rem;
    color: $gray500;
    font-family: 'Roboto-light';
  }
}

.row {
  background: #ededed;
  border-radius: 2rem;
  margin-bottom: 0.563rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;

  .item {
    width: 25%;
    font-size: 0.875rem;
    text-align: center;
    font-weight: 700;

    @media only screen and (min-width: 769px) {
      font-size: 1.125rem;
    }
  }


  .item:last-child {
    max-width: 7.75rem;

    > button {
      width: 100%;
      padding: 0.5rem;

      > span {
        font-size: 0.875rem;
        font-weight: 700;

        @media only screen and (min-width: 481px) {
          font-size: 1.125rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-web-mobile) {
  .wrapper {
    .table {
      .header {
        display: none;
      }
    }
  }
  .row {
    .button {
      width: 100%;
    }
  }
}
