.wrapper {
  display: flex;
  width: 40rem;
  padding: 2rem;
  background-color: white;
  border-radius: 2.2rem;
  .formWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }
}
