@import '../../styles/variables';
.wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  h6{
    color: $pink;
    font-size: 1.125rem;
    font-weight: 500;
    text-align: center;
  }
  @media only screen and (min-width: 768px) {
    h6{
      font-size: 1.5rem;
    }
  }
}