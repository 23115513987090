@import '../../styles/variables';

.wrapper {
  border-radius: 1.563rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  button {
    width: 100%;
  }

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;

    .icon {
      background: url('../../assets/icons/iconCalendar.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .icon {
    width: 4.688rem;
    height: 3.8rem;
    background: url('../../assets/icons/iconCalendar.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.875rem;
    font-weight: 600;
  }

  .info {
    padding-left: 0.625rem;
    font-size: 0.875rem;
  }

  .event {
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
  }

  .edit {
    display: flex;
    padding: 0.625rem;
    align-items: center;
    background: #fff;
    border-radius: 1.563rem;
    .icon {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    .info {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      width: 100%;
      padding-left: 0;
      text-align: left;
      margin-left: 1rem;
      > p {
        text-align: left;
      }
    }
  }

  .preview {
    background: #fff;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto;
    padding: 0.625rem 0.625rem 0 0.625rem;
    border-radius: 1.563rem;
    padding: 0.5rem;
    grid-gap: 0 0.625rem;
    .icon {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    .info {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      width: 100%;
      padding-left: 0;
      text-align: left;
      margin-left: 1rem;
      > p {
        text-align: left;
      }
    }

    button {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      padding: 0;
      span {
        font-size: 1rem;
      }
    }
  }
}
