.editUserWrapper {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    font-size: 1.5rem;
    color: #00aee5;
    font-weight: 700;
    align-items: center;
    transform: translateY(-1.5rem);
    .editText {
      transform: translateX(4.2rem);
    }
    .headerButtons {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .close {
      cursor: pointer;
      color: #00aee5;
      font-size: 2rem;
    }
    .delBtn {
      width: 12rem;
      height: 2.5rem;
      padding: 0.5rem 1rem;
      span {
        font-size: 1rem;
      }
    }
  }

  .content {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    gap: 1.5rem;
    padding-left: 1rem;
    & > div {
      width: 50%;
    }
    .leftSide {
      img {
        width: 11rem;
      }
    }

    .inputs {
      .avatarContainer {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .singleRowInput {
        margin: 1rem 0;
        .editInput {
          width: 26rem;
        }
      }
    }
  }

  .buttonDiv {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
