.wrapper {
  display: block;
  font-size: 1.875rem;
  position: relative;
  .currentPoints {
    display: flex;
    position: absolute;
    bottom: 1.25rem;
    justify-content: center;
    visibility: hidden;
    color: purple;
    padding-bottom: 0.525rem;
    opacity: 1;
    transform: translateX(0.5rem);
  }
  .animationPoints {
    position: absolute;
    bottom: 0.625rem;
    transform: translate(0.5rem, -3.575rem);
    transition: 0.8s;
    opacity: 0;
    .likeImg {
      width: 1.563rem;
    }
    .shareImg {
      width: 3.188rem;
      transform: translateX(-0.8rem);
    }
  }
}

// img { 
//   width: 20px;
// }