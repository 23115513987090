@import 'styles/_variables';

.wrapper {
  width: 100%;
  .container {
    position: relative;
    height: 35.125rem;
    overflow: hidden;
    padding-bottom: 2rem;
    .slider {
      position: relative;
      margin: 0 auto;
      max-width: 25rem;
      transform: scale(0.725);
      .content {
        position: absolute;
        display: flex;
        transition: transform 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
        margin-top: 5rem;

        p {
          text-align: center;
          margin-bottom: 0.1rem;
          font-weight: normal;
          font-size: 0.8rem;
        }

        .image {
          padding-top: 2.188rem;
          flex: 1;
          opacity: 0.5;
          transition: opacity 600ms linear;
          margin-left: 0.625rem;
          margin-right: 3.125rem;
          transition: transform 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
          &.active {
            padding-top: 2.188rem;
            opacity: 1;
            transform: scale(1.2);
            cursor: pointer;
          }
          &.doubleSpace {
            margin-right: 4.125rem;
            margin-left: 1.625rem;
          }
        }
      }
    }
    .position {
      top: 80%;
      position: relative;
      margin: 0 auto;
      width: 90%;
      .info {
        display: flex;
        justify-content: center;
        padding-bottom: 0.625rem;
      }
      .btns {
        display: flex;
        justify-content: center;
        align-items: center;
        .leftArrow {
          cursor: pointer;
          background: #ffffff;
          transition: opacity 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
          width: 3.125rem;
          height: 3.125rem;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0.063rem solid rgba(0, 0, 0, 0.03);
          padding-right: 0.313rem;
          img {
            width: 1.25rem;
            height: 1.75rem;
          }
          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }
        .rightArrow {
          cursor: pointer;
          background: #ffffff;
          transition: opacity 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
          width: 3.125rem;
          height: 3.125rem;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0.063rem solid rgba(0, 0, 0, 0.03);
          padding-left: 0.313rem;
          img {
            width: 1.25rem;
            height: 1.75rem;
          }
          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }
        .space {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
          padding: 1.725rem 2rem;
          width: 60%;
        }
      }
    }
  }
}
.title {
  padding-bottom: 1.25rem;
  margin: 0;
  h1 {
    font-size: 1.5rem;
    font-family: $font-secondary;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0;
  }
}

@media screen and (min-width: 481px) {
  .wrapper {
    .container {
      .slider {
        transform: scale(1);
        .content {
          margin-top: 0;
        }
      }
    }
    .position {
      .btns {
        .space {
          width: 20rem;
          margin-left: 2.5rem;
          margin-right: 2.5rem;
          padding: 0;
        }
      }
    }
  }
}
