.filtersContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  & > div {
    width: auto;
  }
}
.inputClass {
  width: 18rem;
  height: 2.5rem;
  background-color: #ededed;
  border-radius: 2rem;
  border: 0px;
  outline: none;
  font-size: 1rem;
}
