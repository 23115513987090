.scrapeAndSave {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  padding-top: 0;
  overflow-x: hidden;

  .back {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #00aee5;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
  }
  .explanation {
    display: flex;
    justify-content: center;
    text-align: center;

    .innerDiv {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
      border-radius: 2rem;
      width: fit-content;
      padding: 1rem;

      .closeExp {
        display: flex;
        justify-content: flex-end;

        img {
          height: 1.5rem;
          width: 1.5rem;
          cursor: pointer;
        }
      }
    }
  }

  p {
    text-align: center;

    span {
      color: #00aee5;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .inputAndButton {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    .divButton {
      margin: auto;
      margin-top: 2rem;
    }
  }
}
