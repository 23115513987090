@import 'styles/_variables';

.wrapper {
  border-radius: 0.75rem;
  overflow: hidden;

  .map {
    width: 90%;
    margin: 0 auto;
    height: 30rem;
  }

  .address {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.25rem;

    span {
      font-family: $font-secondary;
      font-weight: 400;
      text-align: center;
      margin-bottom: 0.313rem;
    }

    button {
      padding: 0.5rem 5rem;
    }
  }

  .marker {
    position: relative;
    // top:0;
  }
}

.autocompleteWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0.625rem;

  input {
    border: none;
    width: 80%;
    border-radius: 1.563rem;
    background: $inputGray;
    font-family: $font-secondary;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1rem;
    padding: 0 0.938rem 0 0.938rem;
    height: 2.188rem;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #c4c4c4;
      text-align: center;
    }
  }
}
