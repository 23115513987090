.emptyWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 1rem;
  gap: 1rem;
  h2 {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1.875rem;
    font-family: 'Roboto';
    font-weight: 500;
    letter-spacing: 0.02rem;
  }
  & > div {
    width: 100%;
    margin: 0;
    box-shadow: none;
  }
}
