$white: #ffffff;
$black: #000000;
$adminBlue: #00aee5;
$greyLight0: #e1e1e1;
$greyLight1: #979797;
$greyLight2: #f3f3f3;
$blue: #00aee5;
$pink: #cb1c68;

$bg-primary: $white;
$bg-secondary: $greyLight2;

$btn-primary: $pink;
$btn-secondary: $blue;
$btn-neutral: $greyLight0;

$color-primary: $black;
$color-secondary: $white;
$color-neutral: $greyLight1;

$font-primary: 'Inter';
$font-primary-bold: 'Inter';
$font-roboto: 'Roboto Mono', monospace;
$font-secondary: 'Roboto';
//WS colors
$pink500: #cb1c68;
$gray280: #ededed;
$gray300: #eeeeee;
//=======
$red: #ee3737;
$green: #00a78d;
$system-blue: #0199e5;
$yellow: #f2a80d;
$gray950: #323131;
$gray900: #333840;
$gray850: #333333;
$gray800: #52565c;
$gray700: #686b70;
$gray600: #878a8e;
$gray550: #919191;
$gray500: #abadaf;
$gray450: #c4c4c4;
$gray400: #cfcfcf;
$gray300: #e5e5e5;
$gray280: #ededed;
$gray250: #f2f2f2;
$gray200: #f4f4f4;
$gray100: #f2f2f2;
$brand-blue: #46a5e8;
$pink800: #930350;
$pink700: #940350;
$purple-dark: #675bb0;
$purple: #7b61ff;
$purple-hover: #8d77ff;
$purple-press: #6252ff;
$purple-disabled: #c7bdfc;
$purple-bright: #9480ff;
$purple-light: #d9dafd;
$gray: #fbfbfb;
$lightGray: #e6e6e6;
$light-pink: #f73589;
$light-blue: #6ad7f8;
$gray: #e1e1e1;
$resultGray: #f3f3f3;
$inputGray: #f2f2f2;
$labelGray: #bdbdbd;
$placeholderCenter: #c4c4c4;
$matureDisable: #e0e0e0;
$matureFontCOlor: #323131;
$black-russian: #090d28;
$grayish-navy: #181c3a;
$waikawa-grey: #646b9b;
$blue-violet: #8d29ff;
$pastel-green: #00c84a;
$fun-green: #048533;
$zuccini: #075a26;
$sunsent-orange: #ff5136;
$red-orange: #ff2626;
$soft-red: #fe6e6e;
$yellow-turbo: #ffd21c;
$turquoise: #2ee9d0;
$midnight-express: #131734;
$Bali-Hai: #8f9bb3;
$hoki: #5e8099;
$blueDianne: #264361;
$iron: #dadbdd;
$bombay: #b7bac0;
$codeGray: #161616;
$alto: #d6d6d6;
$purpleGradient: linear-gradient(#de60a4, #810062);
$peachCream: #ffecdd;
$fuelYellow: #e7ad29;
$chardonny: #ffd089;
$genoa: #18696d;
$jetStream: #c3dcd7;
$negroni: #ffdcc3;
$freshEggplant: #810062;
$mulberry: #b64991;
$mineShaft: #363636;
$codGray: #0d0d0d;
$gray93: #939393;
$vanillaIce: #f2d6e5;
$terracotta: #e08552;

//Card types
$createBorder: #ce494d;
$createBg: #f8f6f1;
$createColor: #ffffff;
$standardBorder: #cb1c68;
$standardBg: #f8f6f1;
$standardColor: #ffffff;
$collectibleBorder1: #f4ca68;
$collectibleBorder2: #db9915;
$collectibleBg: #f8f6f1;
$collectibleColor: #ffffff;
$mysteryBorder1: #de60a4;
$mysteryBorder2: #810062;
$verificationBorder: #cd4671;
$mysteryBg: #f8f6f1;
$mysteryColor: #ffffff;
$oneSidedTriviaBorder: #18696d;
$oneSidedTriviaBg: #f8f6f1;
$oneSidedTriviaColor: #ffffff;
$doubleSidedTriviaBorder: #18696d;
$doubleSidedTriviaBg: #f8f6f1;
$doubleSidedTriviaColor: #ffffff;
$marketplaceBg: #f8f6f1;
$marketplaceColor: #ffffff;
$marketplaceBorder1: #161616;
$marketplaceBorder2: #ffe06f;
$marketplaceBorder3: #d38700;
$anchorBorder1: #525252;
$anchorBorder2: #171717;
$mysteryBg: #f8f6f1;
$mysteryColor: #ffffff;
$eventBorder1: #d55f1c;
$eventBorder2: #9d2f16;
$eventBg: #f8f6f1;
$eventColor: #ffffff;
$sponsoredBorder: #810062;
$sponsoredBg: #f8f6f1;
$sponsoredColor: #ffffff;
$shareBorder: #ededed;

// WS--------
$bg-primary: $white;
$bg-secondary: $gray300;
$color-primary: $black;
$color-secondary: $white;
$color-grey: $grayish-navy;

//Links
$link: $pink;
$link-hover: $light-pink;
$link-active: $light-pink;

//Fonts
$font-primary: 'Roboto';
$font-primary-bold: 'Roboto-bold';
$font-roboto: 'Roboto';
$font-secondary: 'Roboto';
$error-color: $soft-red;

//Breakpoints
$breakpoint-wide-screens: 1200px;
$breakpoint-desktops: 992px;
$breakpoint-tablets: 767px;
$breakpoint-phones: 480px;
$breakpoint-web-mobile: 992px;

/* --- Card Colors depending on the card type --- */
// Default colors for a cards
$cardColor-default-primary: $standardBorder;
$cardColor-default-secondary: $vanillaIce;
$cardColor-default-bubble-text: $peachCream;
$cardColor-default-body-text: black;
$cardColor-default-action: $standardBorder;

// Colors for the "Sponsored" cards
$cardColor-sponsored-primary: $freshEggplant;
$cardColor-sponsored-secondary: $sponsoredBg;
$cardColor-sponsored-bubble-text: $white;
$cardColor-sponsored-body-text: $codeGray;
$cardColor-sponsored-action: $freshEggplant;

// Colors for the "Event" cards
$cardColor-event-primary: $eventBorder1;
$cardColor-event-secondary: $terracotta;
$cardColor-event-bubble-text: $eventColor;
$cardColor-event-body-text: $eventColor;

// Colors for the "Poll" cards
$cardColor-poll-primary: $genoa;
$cardColor-poll-secondary: $jetStream;
$cardColor-poll-bubble-text: $negroni;
$cardColor-poll-body-text: $negroni;

// Colors for the "Marketplace" cards
$cardColor-marketplace-primary: $codeGray;
$cardColor-marketplace-secondary: $sponsoredBg;
$cardColor-marketplace-bubble-text: $white;
$cardColor-marketplace-body-text: $codeGray;
$cardColor-marketplace-action: $gray93;

// Colors for the "OneSidedTrivia" cards
$cardColor-oneSidedTrivia-primary: $genoa;
$cardColor-oneSidedTrivia-secondary: $jetStream;
$cardColor-oneSidedTrivia-bubble-text: $negroni;
$cardColor-oneSidedTrivia-body-text: $negroni;

// Colors for the "doubleSidedTrivia" cards
$cardColor-doubleSidedTrivia-primary: $genoa;
$cardColor-doubleSidedTrivia-secondary: $jetStream;
$cardColor-doubleSidedTrivia-bubble-text: $negroni;
$cardColor-doubleSidedTrivia-body-text: $negroni;

// Colors for the "Collectible" cards
$cardColor-collectible-primary: $fuelYellow;
$cardColor-collectible-secondary: $chardonny;
$cardColor-collectible-bubble-text: $codGray;
$cardColor-collectible-body-text: $codGray;
$cardColor-collectible-action: $fuelYellow;

// Colors for the "Mystery" cards
$cardColor-mystery-primary: $cardColor-default-primary;
$cardColor-mystery-secondary: $standardBg;
$cardColor-mystery-bubble-text: $cardColor-default-bubble-text;
$cardColor-mystery-body-text: $cardColor-default-body-text;
$cardColor-mystery-action: $cardColor-default-action;

// Colors for the "Blank" cards
$cardColor-blank-primary: $cardColor-default-primary;
$cardColor-blank-secondary: $cardColor-default-secondary;
$cardColor-blank-bubble-text: $cardColor-default-bubble-text;
$cardColor-blank-body-text: $cardColor-default-body-text;
$cardColor-blank-action: $cardColor-default-action;

// Colors for the "Double" cards
$cardColor-double-primary: $cardColor-default-primary;
$cardColor-double-secondary: $cardColor-default-secondary;
$cardColor-double-bubble-text: $cardColor-default-bubble-text;
$cardColor-double-body-text: $cardColor-default-body-text;
$cardColor-double-action: $cardColor-default-action;

// Colors for the "Slide" cards
$cardColor-slide-primary: $cardColor-default-primary;
$cardColor-slide-secondary: $cardColor-default-secondary;
$cardColor-slide-bubble-text: $cardColor-default-bubble-text;
$cardColor-slide-body-text: $cardColor-default-body-text;
$cardColor-slide-action: $cardColor-default-action;

// Colors for the "Standard" cards
$cardColor-standard-primary: $cardColor-default-primary;
$cardColor-standard-secondary: $cardColor-default-secondary;
$cardColor-standard-bubble-text: $cardColor-default-bubble-text;
$cardColor-standard-body-text: $cardColor-default-body-text;
$cardColor-standard-action: $cardColor-default-action;

/* --- Card Colors depending on the user type --- */
// Colors for the "General User" Cards
$cardColor-generalUser-primary: $peachCream;
$cardColor-generalUser-secondary: $createBg;
$cardColor-generalUser-bubble-text: $blueDianne;
$cardColor-generalUser-body-text: $black;
$cardColor-generalUser-action: $mysteryBorder1;

// Colors for the "Business Subscriber - Wonder Shaffle Plus Subscribers" Cards
$cardColor-businessSubscriber-primary: $blueDianne;
$cardColor-businessSubscriber-secondary: $sponsoredBg;
$cardColor-businessSubscriber-bubble-text: $white;
$cardColor-businessSubscriber-body-text: $black;
$cardColor-businessSubscriber-action: $blueDianne;

// Colors for the "Media" Cards
$cardColor-media-primary: $bombay;
$cardColor-media-secondary: $iron;
$cardColor-media-bubble-text: $black;
$cardColor-media-body-text: $black;
$cardColor-media-action: $bombay;

// Colors for the "Primerime Advertiser" Cards
$cardColor-primetimeAdvertiser-primary: $white;
$cardColor-primetimeAdvertiser-secondary: $white;
$cardColor-primetimeAdvertiser-bubble-text: $black;
$cardColor-primetimeAdvertiser-body-text: $black;
$cardColor-primetimeAdvertiser-border: $purpleGradient;
$cardColor-primetimeAdvertiser-action: $purpleGradient;

// Colors for the "Admin" Cards
$cardColor-admin-primary: $pink500;
$cardColor-admin-secondary: $vanillaIce;
$cardColor-admin-bubble-text: $peachCream;
$cardColor-admin-body-text: $black;
$cardColor-admin-action: $pink500;
