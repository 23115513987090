@import '../../styles/variables';

.topicContainer {
  height: 1.875rem;
  background-color: $adminBlue;
  border-radius: 0.938rem;
  padding: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  span.label {
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: $white;
    height: 100%;
    &:hover {
      cursor: default;
    }
  }
  &.clickable {
    span.label {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .closeIcon {
    margin-left: 0.625rem;
    img {
      width: 0.625rem;
      height: 0.625rem;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.tagInput {
  @extend .topicContainer;
  background-color: $white;
  span.label {
    color: $black;
    font-family: 'Roboto';
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
  }
  .closeIcon {
    img {
      width: 1rem;
      height: 1rem;
    }
  }
}
