@import 'styles/_variables';

.wrapperSingleNewsletter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin: 5px auto;

  .singleCell {
    width: 19%;
    word-break: break-all;
    margin: 0 1rem;
  }
}

.wrapper {
  width: 100%;

  h3 {
    text-align: center;
  }

  p {
    word-break: break-word;
    h3 {
      text-align: left;
    }
  }
  .singleRow {
    .scrollContent {
      height: 55vh;
      background-color: $inputGray;
      padding: 1rem;
      border-radius: 1.563rem;
    }
  }

  img {
    max-width: 100%;
  }
  .loadingContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(white, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
