@import 'styles/variables';

.close {
  position: absolute;
  top: -2.5rem;
  right: -2.5rem;
  background-color: #f9f9f9;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;

  img {
    width: 1.55rem;
  }
}

/* -- Mobile style -- */
@media only screen and (max-width: $breakpoint-desktops) {
  .close {
    width: 2.5rem;
    height: 2.5rem;
    top: -3.25rem;
    right: 0rem;

    img {
      width: 1.25rem;
    }
  }
}
