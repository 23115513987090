@import 'styles/variables';
@import 'styles/mixins';

.trendingWrapper {
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.675rem);

  h2 {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .icons {
    display: flex;
    height: 2.125rem;
    align-items: center;
    img {
      margin: 0 0.5rem;
      cursor: pointer;
      height: 2.5rem;
      width: auto;
    }
  }

  .filter {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    padding-bottom: 1rem;

    .third {
      width: 19rem;
      max-width: 33%;
      height: 2.75rem;
      input {
        width: 18.375rem;
        height: 100%;
        background-color: #ededed;
        border-radius: 2rem;
        border: 0px;
        outline: none;
      }

      input:disabled {
        cursor: not-allowed;
      }

      .selectGrayNoMargin {
        width: 19rem;
      }
    }
  }

  .cardsDiv {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    overflow: auto;
    overflow-x: hidden;
    width: 98%;
    margin: 0 auto;
    @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.675rem);
    .savedOrDeletedCards {
      display: flex;
      flex-direction: column;

      .cards {
        display: flex;
        gap: 2.4rem;
        flex-wrap: wrap;
      }
    }
  }

  .cardsDivSlider {
    @extend .cardsDiv;
    overflow: hidden;
  }

  .topicsTrending {
    width: 90%;
    margin: auto;

    .tableTopic {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: auto;
      justify-content: center;
      padding-bottom: 5rem;
      h1 {
        margin-top: 2rem;
      }

      .tableHeader {
        width: 95%;
        display: flex;
        justify-content: space-between;
        margin: 0 3rem;
        margin-top: 2.313rem;
        padding-bottom: 1rem;

        span {
          font-size: 1.125rem;
          font-weight: 700;
          line-height: 1rem;
          letter-spacing: 0.02em;
        }
      }

      .singleTableRow {
        width: 100%;
        height: 2.813rem;
        border-radius: 22px;
        background-color: #e1e1e1;
        display: flex;
        align-items: center;
        padding-left: 3rem;
        margin: 0.5rem 0;
        justify-content: space-between;

        .dataRow {
          width: 95%;
          display: flex;
          justify-content: space-between;

          span {
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1rem;
            letter-spacing: 0.02em;
          }
        }

        .icons {
          img {
            margin: 0 0.5rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}
