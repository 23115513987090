@import 'styles/variables';

html,
body {
  overscroll-behavior-y: contain;
}

.wrapper {
  padding: 2.5rem 0 1.25rem 0;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  cursor: pointer;

  .gridPreviewCard {
    transform: scale(1.8);
    display: flex;
    justify-content: flex-start;
    padding-top: 5rem;
  }

  &.posRelative {
    position: relative;
  }
  &.addMarginForDouble {
    margin-right: 21.563rem;
  }
  .flipCard {
    perspective: 62.5rem;
    width: 18.75rem;
    height: 28.75rem;
    transition: all 0.6s;
    transform: rotateZ(0) rotateY(0);
    &.small {
      width: 7.125rem;
      height: 11rem;
    }
    .selectionCardCover {
      position: absolute;
      left: -0.9rem;
      top: -1.25rem;
      width: calc(100% + 1.5rem);
      height: calc(100% + 2.5rem);
      z-index: 1;
      transition: all 0.2s ease-in-out;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      &.selected {
        background: rgba(255, 255, 255, 0.6);
      }
    }
    &.doubleCard {
      .flipCardBack {
        transform: rotateZ(-90deg) rotateY(180deg);
      }
      &.doubleCardActive {
        transform: rotateZ(0) rotateY(0) translateX(-100%);
        .flipCardInner {
          transform: rotateZ(0) rotateY(0);
          &.flippedCard {
            transform: rotateZ(-90deg) rotateY(180deg);
          }
        }
        .withoutTopMargin {
          top: -0.6rem;
        }
      }
    }
    .flipCardInner {
      position: relative;
      height: 98.3%;
      transition: transform 1000ms cubic-bezier(0.71, -0.24, 0.23, 1.32) 0s;
      transform-style: preserve-3d;
      &.inlineShadow {
        & > div {
          & > div {
            & > div {
              & > div {
                box-shadow: 0px -1px 4px -1px #000000ad;
                border-radius: 2.5rem;
              }
            }
          }
        }
      }
      &.flippedCard {
        transform: rotateY(-180deg);
      }
    }
    .withoutTopMargin {
      top: -0.6rem;
    }
    .flipCardFront,
    .flipCardBack {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: width 1000ms cubic-bezier(0.71, -0.24, 0.23, 1.32) 0s;

      /* -- Flip back icon -- */
      .outerIcon {
        cursor: pointer;
        position: absolute;
        top: -2.3rem;
        right: -2.4rem;
        margin: auto;
        z-index: 100;
        opacity: 1;
        transition: opacity cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.2s;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        background-color: $white;
        &.largeCardOuterIcon {
          left: 22.5rem;
        }
      }
    }
    &.affectOthersWithWidth {
      &.cardIsFlipped {
        &.showComments {
          width: calc(18.75rem + 18.75rem);
        }
      }
    }
    &.doubleCard {
      .flipCardBack {
        left: -65%;
        top: 67.5%;
      }
      &.doubleCardActive {
        .outerIcon {
          right: -3.4rem;
        }
        .flipCardBack {
          width: 39.625rem;
          .flipCardBack {
            width: 100%;
            top: -0.875rem;
            .marketplaceCard {
              top: -0.9rem;
              margin-left: -0.45rem;
            }
          }
          .marketplaceCard {
            &.flipCardFront {
              margin-top: 1.7rem;
              margin-left: -0.45rem;
            }
          }
        }
      }
      &.affectOthersWithWidth {
        .flipCardBack {
          left: unset;
        }
        &.doubleCardActive {
          width: 40.625rem;
          &.showComments {
            width: calc(40.625rem + 18.75rem);
            .flipCardBack {
              width: auto;
            }
          }
        }
      }
    }
    .flipCardFront {
      .outerCloseIcon {
        padding: 0.5rem;
        opacity: 1;
      }
      &:hover {
        .editIcon {
          opacity: 1;
        }
        .exitIcon {
          opacity: 1;
        }
        .save {
          opacity: 1;
        }
        .deleteIcon {
          opacity: 1;
        }
        .restoreIcon {
          opacity: 1;
        }
      }
    }
    .flipCardBack {
      top: -0.3rem;
      &.inlineCard {
        display: flex;
        justify-content: center;
        left: 0.3rem;
        align-items: flex-start;
      }
      &.marketplaceCard {
        margin-left: -0.35rem;
        .flipCardFront {
          margin-left: -0.25rem;
          &.marketplaceCard {
            margin-left: -0.6rem;
          }
        }
        &.flippedCard {
          margin-left: -0.35rem;
        }
      }
      &:hover {
        .outerFlipBackIcon {
          opacity: 1;
        }
      }
    }
    .flipCardFront {
      z-index: -1;
      &.inlineCard {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: calc(100% - 0.5rem);
        &.marketplaceCard {
          width: calc(100% - 1rem);
          left: 0.1rem;
          margin-top: 0.8em;
        }
      }
      .editIcon {
        @extend .icon;
        right: 6%;
        &.marketplaceCard {
          bottom: 6.7%;
          right: 5.6%;
        }
      }
      /* ---- Close card icon ---- */
      .close {
        position: absolute;
        top: -2.813rem;
        right: -2.413rem;
        background-color: #f9f9f9;
        z-index: 99999999;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 50%;
        box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.08);
        cursor: pointer;
        .exitIcon {
          width: 1.55rem;
        }
      }

      .save {
        @extend .icon;
        right: 6%;

        @media only screen and (min-width: $breakpoint-web-mobile) and (max-width: $breakpoint-wide-screens) {
          width: 3.1rem;
          max-width: 3.1rem;
          height: 3.1rem;
          max-height: 3.1rem;
        }

        &.marketplaceCard {
          bottom: 6.7%;
          right: 5.6%;
        }
        .pointsContainer > div {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 60%;
            height: 60%;

            @media only screen and (min-width: $breakpoint-phones) and (max-width: $breakpoint-web-mobile) {
              width: 80%;
              height: 80%;
            }
          }
        }
      }
      .adminEdit {
        @extend .save;
        background: #cb1c68 !important;
        opacity: 1;

        img {
          width: 1.5rem !important;
          height: 1.5rem !important;
        }
      }
    }
    .flipCardBack {
      background-color: transparent;
      transform: rotateY(180deg);
      z-index: 100;
    }
    .infoContent {
      padding-top: 0.312rem;
      display: flex;
      text-align: center;
      justify-content: center;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 1.5rem;
      color: $black;
      p {
        font-weight: 300;
        padding-left: 0.313rem;
      }
    }
    .colorDays {
      @extend .infoContent;
      color: $black;
      opacity: 0.7;
    }
    .critical {
      @extend .infoContent;
      color: #ff0606;
      opacity: 0.7;
    }
  }
  .deleteIcon {
    @extend .icon;
    left: 6%;
    &.marketplaceCard {
      bottom: 7.7%;
      left: 5.6%;
    }
  }
  .extraSmallDelete {
    @extend .icon;
    bottom: unset;
    width: 1.5rem !important;
    height: 1.5rem !important;
    img {
      width: 1rem !important;
      height: 1rem !important;
      margin-right: 0.1rem;
    }
  }

  .extraSmallSave {
    @extend .extraSmallDelete;
    right: 6%;
  }
  .restoreIcon {
    @extend .icon;
    left: 6%;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
    &.restoreMobile {
      bottom: 12%;
    }
  }
}
.icon {
  position: absolute;
  z-index: 50;
  background: white;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.2s;
  cursor: pointer;
  max-width: 2.5rem;
  max-height: 2.5rem;
  bottom: 4.5%;
  img {
    width: 1.875rem;
    height: 1.875rem;
  }
}
.cardDelete {
  opacity: 0;
  animation: deleting 0.6s, moving 0.8s normal 0.6s forwards;
}

@keyframes deleting {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    margin-top: 0;
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    margin-top: -25rem;
    transform: scale(0.4);
  }
}

.adminEdit {
  @extend .save;
  background: #cb1c68;
  opacity: 1;

  img {
    width: 50%;
  }
}

.previewCard {
  transform: scale(1.5);
  padding-top: 5rem;
}

@keyframes moving {
  from {
    width: 20rem;
    margin-left: 0;
  }
  to {
    width: 0rem;
    margin-left: -3.125rem;
  }
}

@media only screen and (max-width: $breakpoint-web-mobile) {
  .wrapper {
    .flipCard {
      &.doubleCard {
        .flipCardBack {
          top: 67.5%;
        }
        &.doubleCardActive {
          .outerIcon {
            right: 27%;
            top: 18%;
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
    padding: 0;
    width: fit-content;
    .flipCard {
      .flipCardFront {
        height: unset;
        .close {
          right: -0.5rem;
          top: -3.5rem;
        }
      }
      .flipCardBack {
        height: unset;
        .outerIcon {
          top: -3.2rem;
          right: -0.2rem;
        }
      }
      &.doubleCardActive {
        .outerIcon {
          right: 10rem;
        }
      }
    }
  }
  .previewCard {
    justify-content: center;
    transform: scale(1.65);
  }
  .inlineCardWrapper {
    .flipCardFront,
    .flippedCard {
      top: -1.2rem;
    }
    .flipCardFront.marketplaceCard {
      margin-left: -0.5rem;
    }
  }
  .withoutTopMargin {
    top: -1.8rem !important;
    .flipCardFront {
      top: 0rem;
    }
  }
}
.longPress {
  transition: all 800ms ease;
  transform: scale(0.8) !important;
  height: 32rem;
  touch-action: none;
}

.trashIcon,
.saveIcon {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  img {
    width: 60%;
    height: 60%;
  }
}

.trashIcon {
  background-color: #ff0606;
  bottom: -7rem;
  left: 7rem;
}
.saveIcon {
  background-color: #40ff06;
  top: -6rem;
  left: 7rem;
}

.doubleCard .inlineCardWrapper {
  .flipCardBack {
    transform: rotateZ(0deg) rotateY(-180deg) !important;
  }
  .flippedCard {
    transform: rotateZ(0) rotateY(180deg) translateY(0.6rem) !important;
  }
}

.confirmationModal__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .confirmationModal {
    width: 90%;
    max-width: 25rem;
    padding: 1.25rem;
    > div {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 1.875rem;
    }
  }
}

@media only screen and (max-width: 480px) {
  .wrapper {
    padding-top: 1.5rem;
  }
  .gridPreviewCard {
    justify-content: center;
  }
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5rem;
  cursor: default;

  .single {
    display: flex;
    align-items: center;
  }

  img {
    margin: 0 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
