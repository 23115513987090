.wrapper {
  min-width: 59.063rem;
  width: 90%;
  margin: auto;

  h2 {
    text-align: center;
    margin-top: 0;
    text-transform: capitalize;
  }

  .buttonDiv {
    display: flex;
    justify-content: flex-end;
  }
}
