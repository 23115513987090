:global {
  .canvas-modal {
    .canvas-card-body {
      padding-top: 0;
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .inputFields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;
    }
  }

  button {
    margin: 10px 0;
  }

  input {
    width: 100% !important;
  }
}
.newsletterPack {
  height: 29rem;
}
