@import 'styles/variables';

.wrapper {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item {
    font-size: 1.125rem;
    font-weight: 700;
    width: 25%;
    text-align: center;
  }

  .item:last-child {
    max-width: 7.75rem;
  }
}

.row {
  background: #ededed;
  border-radius: 2rem;
  margin-bottom: 0.563rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;

  .item {
    width: 33%;
    text-align: center;
    font-weight: 700;
  }

  .item:nth-child(2) {
    width: 40%;
  }

  .item:last-child {
    max-width: 7.75rem;

    > button {
      width: 100%;
      padding: 0.5rem;

      > span {
        font-weight: 700;
      }
    }
  }
}
