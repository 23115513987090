@import 'styles/mixins';
@import 'styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  .headingClass {
    padding: 0;
  }
  .back {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #00aee5;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .mediaPickerContainer {
      position: relative;
      width: 100%;
      height: 15.625rem;
      border-radius: 2.5rem;
      overflow: hidden;
      & > div {
        background: $inputGray;
      }
    }

    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .confirm {
        display: flex;
        align-items: center;

        span {
          margin-left: 1rem;
        }

        .active {
          width: 5.188rem;
          height: 2.813rem;
          border-radius: 23px;
          background-color: #00aee5;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          transition: 1s;
          cursor: pointer;

          .inner {
            width: 2.313rem;
            height: 2.313rem;
            background-color: white;
            border-radius: 50%;
            margin: 0.2rem;
          }
        }

        .inActive {
          @extend .active;
          background-color: #ededed;
          justify-content: flex-start;
        }
      }

      .selector {
        width: 20%;
      }
    }

    .uploadImage {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .rows {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .tagInput {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;

        .singleInput {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .singleRow {
          display: flex;
          align-items: flex-start;

          .deleteRowIcon {
            background-color: #00aee5;
            width: 2.5rem;
            min-width: 2.5rem;
            height: 2.5rem;
            min-height: 2.5rem;
            padding: 0.5rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            cursor: pointer;
            margin-left: 0.75rem;
            img {
              width: 1.25rem;
            }
          }
        }
      }
    }

    .inputContainer {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      & > div {
        input {
          text-align: center;
        }
      }
      .toogleButton {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          margin-left: 1rem;
        }

        .active {
          width: 5.188rem;
          height: 2.813rem;
          border-radius: 23px;
          background-color: #00aee5;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          transition: 1s;
          cursor: pointer;

          .inner {
            width: 2.313rem;
            height: 2.313rem;
            background-color: white;
            border-radius: 50%;
            margin: 0.2rem;
          }
        }

        .inActive {
          @extend .active;
          background-color: #ededed;
          justify-content: flex-start;
        }
      }
    }
    .buttonDiv {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
