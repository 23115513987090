.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .headerText {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }
  .inputDiv {
    display: flex;
    justify-content: center;
    padding-top: 1.3rem;
    position: relative;

    .input {
      background-color: #ededed;
    }
    .btn {
      width: 22rem;
      margin-top: 1.5rem;
    }
  }
}

@media only screen and (max-width: 480px) {
  .wrapper {
    padding-left: 0rem;
    padding-right: 0rem;
    .headerText {
      font-size: 1.7rem;
      padding-bottom: 1.5rem;
    }
    .btn {
      height: 3rem;
      span {
        font-size: 1.5rem;
      }
    }
    .inputDiv {
      .input {
        height: 3rem;
      }
    }
  }
}
