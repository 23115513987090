.usersWrapper {
  height: 100%;

  .users {
    max-height: 90%;
    overflow-y: auto;
  }

  .buttonDiv {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
