@import 'styles/_variables';

.wrapper {
  height: 41.25rem;
  position: relative;
  // transform: scale(1.25);
  // margin: 8rem 0 8rem 0;
  margin: 0 auto;
  .slider {
    position: relative;
    margin: 0 auto;
    width: 100%;
    .content {
      padding: 0.625rem 0.625rem 0.625rem 0.625rem;
      display: flex;
      position: absolute;
      transition: transform 500ms cubic-bezier(0.35, 0.21, 0.59, 0.8) 0s;
      left: calc(50% - 11rem);
      gap: 3.125rem;
      // turn off select possibility on front cards
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      .sliderElement {
        padding-bottom: 1.25rem;
        padding-top: 2.188rem;
        flex: 1;
        transition: transform 550ms cubic-bezier(0.35, 0.21, 0.59, 0.8) 0s;
        &.pack {
          transform: translateY(0.65rem);
        }
        &.active {
          padding-top: 2.188rem;
          transform: scale(1.23);
          z-index: 10;
          transition: all 500ms ease;
        }
        &.packActive {
          @extend .active;
          transform: scale(1.13);
          transition: all 500ms ease;
        }
      }
    }
  }
  .btns {
    transition: all 300ms ease;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    .leftArrow {
      cursor: pointer;
      background: #ffffff;
      transition: opacity 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      width: 3.125rem;
      height: 3.125rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.063rem solid rgba(0, 0, 0, 0.03);
      padding-right: 0.313rem;
      margin-right: 2.5rem;
      img {
        width: 1.25rem;
        height: 1.75rem;
      }
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
    .rightArrow {
      @extend .leftArrow;
      padding-right: 0;
      margin-right: 0;
      padding-left: 0.313rem;
      margin-left: 2.5rem;
    }
  }
}
.mightyWonderShuffle {
  background: transparent;
  border: none;
  cursor: pointer;
}
.noWSButton {
  .btns {
    bottom: 1rem !important;
  }
}

.loadMoreBtn {
  margin: 2.5rem 0 1.25rem 0;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background-color: $labelGray;
  border-radius: 2.5rem;
  width: 18.75rem;
  height: 28.75rem;
  opacity: 0.5;
  span {
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    color: $gray950;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: bold;
  }
  &:hover {
    background-color: $labelGray;
    opacity: 1;
    span {
      color: rgba($labelGray, 0.5);
      color: $inputGray;
    }
  }
}

/* ---- Animations ---- */
.goLeft {
  animation: move_left 2s ease-in 0s forwards;
}
.goLeftFirst {
  animation: move_left 2s ease-in 0.5s forwards;
}
.goLeftS {
  animation: move_left 2s ease-in 0.25s forwards;
}
.goLeft,
.goLeftFirst,
.goLeftS,
.goRight,
.goRightFirst,
.goRightS {
  position: relative;
}
.goRight {
  animation: move_right 2s ease-in 0s forwards;
}
.goRightFirst {
  animation: move_right 2s ease-in 0.5s forwards;
}
.goRightS {
  animation: move_right 2s ease-in 0.25s forwards;
}

@keyframes move_left {
  from {
    left: 0;
  }
  to {
    left: -60vw;
  }
}
@keyframes move_right {
  from {
    right: 0;
  }
  to {
    right: -60vw;
  }
}

/* ---- Shuffle animation ---- */
.animationStart {
  animation: starting 3s linear forwards, moving 3s linear 3s forwards infinite;
}
@keyframes starting {
  to {
    transform: translateX(-860rem);
  }
}
@keyframes moving {
  from {
    transform: translateX(-50rem);
  }
  to {
    transform: translateX(-905rem);
  }
}

.animationStop {
  animation: stopping 3.8s ease-out forwards;
}
@keyframes stopping {
  from {
    transform: translateX(-70rem);
  }
  to {
    transform: translateX(-500rem);
  }
}
/* ---- End of Shuffle animation ---- */

.hidden {
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;
}

.backArray {
  width: 50%;
  position: relative;
  margin-left: 25%;
}
.backArrayHide {
  @extend .backArray;
  display: none;
}
.backArrayShow {
  @extend .backArray;
  display: block;
}

/* ---- Open Pack ---- */
.openPack {
  position: absolute;
  top: 7.5rem;
  left: 0rem;
  width: 100%;
  height: 20rem;
}

.backToWonder {
  position: absolute;
  bottom: 5.3rem;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white !important;
  padding: 0;
  max-width: none;
  height: auto;
  & > span {
    color: $adminBlue;
    text-decoration: underline;
  }
}
