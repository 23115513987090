@import '../../../styles/variables';
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .headerRow {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .leftSide {
      grid-area: 1 / 1 / 2 / 2;
    }
    .middle {
      grid-area: 1 / 2 / 2 / 3;
    }
    .rightSide {
      grid-area: 1 / 3 / 2 / 4;
    }
  }
  .filterContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 1rem 0.5rem;
    gap: 1rem;
    .filterValue {
      background: $gray280;
      border-radius: 1rem;
      padding: 0.5rem;
    }
  }
}
.sendPack {
  height: 2.5rem;
  margin: 0;
  margin-left: auto;
}
