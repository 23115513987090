@import 'styles/_variables';

.wrapper {
  position: relative;
  .edit {
    background: $white;
    border-radius: 50%;
    padding: 0.313rem;
    cursor: pointer;
    position: absolute;
    top: 1.375rem;
    right: 3.563rem;
    margin: auto;
    opacity: 0;
  }

  .bottomBtn {
    cursor: pointer;
    position: absolute;
    bottom: 0.625rem;
    margin: auto;
    z-index: 100;
    opacity: 1;
    transition: opacity cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.2s;
    box-shadow: 0 1px 4px 2px rgba($black, 0.25);
    border-radius: 50%;
    width: 1.5rem;
    left: 0.625rem;
  }
}
