@import 'styles/variables';

.typography {
  font-family: 'Roboto';
  font-weight: 400;
  letter-spacing: 0.02rem;
  display: flex;
  justify-content: center;
  &.variation-title {
    text-align: center;
    font-size: 2.25rem;
    margin-bottom: 4.25rem;
    text-transform: capitalize;
  }
  &.variation-subtitle {
    text-align: center;
    font-size: 1.875rem;
    margin: 1rem;
    margin-bottom: 1.5rem;
  }
  &.variation-subtitleWithoutMargin {
    text-align: center;
    font-size: 1.875rem;
    &.alignLeft {
      text-align: left;
      justify-content: flex-start;
    }
  }
  &.variation-largerParagraph {
    text-align: center;
    font-size: 1.5rem;
  }
  &.variation-mediumParagraph {
    text-align: center;
    font-size: 1.25rem;
  }
  &.variation-emptyMessageText {
    text-align: center;
    font-size: 1.5rem;
    color: $gray400;
    justify-content: center;
    width: 100%;
    &.marginY10 {
      margin: 10% 0;
    }
    &.marginY5 {
      margin: 5% 0;
    }
  }
  &.variation-errorMessageSmall {
    color: $error-color;
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.125rem 0.938rem;
    justify-content: flex-start;
  }
}
