@import '/src/styles/variables';

.commentWrapper {
  display: flex;
  justify-content: flex-start;
  margin: 0.2rem 0;
  gap: 0.2rem;
  position: relative;

  &:hover .threadComment {
    transition: opacity 0.4s;
    opacity: 1;
  }

  .threadComment {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    height: 1.8rem;
    width: 1.8rem;
    background-color: $gray500;
    padding: 0.4rem;
    border-radius: 0.4rem;
    transition: background-color 0.4s;
    cursor: pointer;
    &:hover {
      background-color: $gray800;
    }
    & path {
      fill: $white;
    }
  }
  .authorAvatar {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    margin-right: 0.2rem;
    img {
      width: 1.875rem;
      height: 1.875rem;
      object-fit: cover;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    .nameAndComment {
      padding: 0.2rem 0;
      font-size: 0.875rem;
    }
    .date {
      color: $gray500;
      font-size: 0.65rem;
    }
  }
}

.threadBox {
  background-color: $inputGray;
  color: $gray950;
  border-radius: 1rem;
  font-size: 0.8rem;
  line-height: 1rem;
  padding: 0.4rem 0.8rem;
  transition: background-color 0.4s;
  margin-bottom: 0.4rem;
  cursor: pointer;
}
