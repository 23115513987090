@import '../../styles/variables';
.quilField {
  border: 0.063rem dashed #000;
  font-family: $font-roboto;
  .ql-container {
    position: initial;
  }

  .ql-editor {
    background: #f8f6f1;
    min-height: 10.625rem;
    font-family: $font-roboto;
    text-align: justify;
  }
  .ql-snow .ql-editor h3 {
    font-size: 1.17rem;
  }
  .ql-editor p {
    font-size: 1rem;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
    content: 'Heading';
  }

  .ql-toolbar.ql-snow {
    padding: 0;
    background: #f8f6f1;
  }

  .ql-toolbar {
    position: relative;
  }

  .ql-formats {
    transform: scale(0.8);
    margin-right: 0 !important;
    background: #fff;
    border-radius: 0.625rem;
  }

  .ql-editor.ql-blank {
    border: none;
  }

  .ql-picker-options span {
    transform: scale(1);
  }

  .ql-picker-options {
    padding-top: 4rem !important;
  }

  .ql-tooltip.ql-editing {
    left: 0.438rem !important;
    top: 1.875rem !important;
    width: 95%;
  }

  .ql-snow {
    text-align: left;
    .ql-tooltip[data-mode='video'] {
      &::before {
        display: none;
      }
    }
    .ql-tooltip input[type='text'] {
      width: 80% !important;
      border: none;
      &:focus {
        outline: none;
      }
    }
  }
}
