@import '../../styles/variables';

.customModal {
  position: absolute;
  border: none;
  background: white;

  border-radius: 6px;
  outline: none;
  z-index: 99999;
  width: 75%;
  // width: 39.875rem;
  // height: 21.563rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
}

.customOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.53);
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.confirmationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border: 0.125rem solid #cd4671;
  border-radius: 1.875rem;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  padding: 1.875rem;
  left: 0;
  right: 0;
  top: calc(50% - 6.25rem);
  width: auto;
  max-width: 25rem;
  margin: 0 auto;

  > div {
    display: flex;

    button {
      margin: 0.625rem;
    }
  }

  h3 {
    text-align: center;
  }
}

.max300 {
  max-width: 18.75rem;
}

.max320 {
  max-width: 20rem;
}

.max320WithTransparentBg {
  max-width: 20rem;
  background: transparent;
}

.max500 {
  max-width: 31.25rem;
  transform: translateX(9rem);
}
.maxCenter500 {
  max-width: 31.25rem;
}

.max600 {
  max-width: 37.5rem;
}

.max610 {
  max-width: 32.5rem;
}

.max700 {
  max-width: 43.75rem;
  transform: translateX(9rem);
}

.max900 {
  max-width: 56.25rem;
  transform: translateX(9rem);
}

.testInstacard {
  max-width: 56.25rem;
}

.max1000 {
  max-width: 62.5rem;
}

.max1100 {
  max-width: 68.75rem;
}

.xlg {
  max-width: 75rem;
}

.max1300 {
  max-width: 81.25rem;
  transform: translateX(9rem);
}
.max1300centered {
  max-width: 81.25rem;
  transform: translateX(9rem);
}

.newsLetterDetailModal {
  height: 80vh;
  width: 90%;
  transform: translateX(2rem);
}

.pdfPreviewModal {
  height: 85vh;
  width: 90%;
}

.imgPreviewModal {
  width: auto;
  background: transparent;
  box-shadow: unset;
}

.overlayImgPreview {
  background-color: rgba(5, 16, 20, 0.53);
}

.modalBody {
  padding: 2rem;
  position: relative;

  .scrollWrapper {
    // width: 100%;
    //max-height: 600px;
  }

  .title {
    text-align: center;
    padding: 21px 0;
  }
}

.close {
  position: absolute;
  right: -2.5rem;
  top: -2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99999;
  background-color: white;
  width: 3.125rem;
  height: 3.125rem;

  img {
    width: 1.563rem;
  }
}

.undoClose {
  @extend .close;

  img {
    width: 2.1563rem;
  }
}

.modalFooter {
  display: flex;
  margin-top: 50px;
  justify-content: center;

  button {
    margin: 10px;
  }
}

.modalYesNo {
  .title {
    font-weight: normal;
    text-transform: initial;
  }
}

@media only screen and (min-width: 768px) {
  .modalBody {
    padding: 2rem 2rem 1rem 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .max1300centered {
    transform: none !important;
  }
}
