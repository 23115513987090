@import '../../styles/variables';

.toggleWrapper {
  display: flex;
  .switchLabel {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 4.375rem;
    height: 2.125rem;
    background: $matureDisable;
    border-radius: 6.25rem;
    transition: background-color 0.2s;
  }
  .switchLabel .switchButton {
    content: '';
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 2.813rem;
    transition: 0.2s;
    background: $white;
  }
  .switchCheckbox:checked + .switchLabel .switchButton {
    left: calc(100% - 0.125rem);
    transform: translateX(-100%);
  }
  .switchCheckbox:checked + .switchLabel {
    background: $adminBlue;
  }
  .switchLabel:active .switchButton {
    width: 1.375rem;
  }
}
.toggleLG {
  margin-top: 2.688rem;
  display: inline-block;
  position: relative;
  width: 2.25rem;
  height: 1.375rem;
  input {
    position: absolute;
    top: 0.8rem;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    @media (max-width: 1024px) {
      left: 0.5rem;
      height: 1rem;
    }
  }
}
.toggleXS {
  margin-top: 2.688rem;
  display: inline-block;
  position: relative;
  width: 2.25rem;
  height: 1.375rem;
  width: 3rem;
  height: 1.75rem;
  .switch-label {
    width: 3rem;
    height: 1.75rem;
  }
  .switch-label .switch-button {
    width: 1.5rem;
    height: 1.5rem;
  }
  .switch-label:active .switch-button {
    width: 1.75rem;
  }
}
.contentBlock {
  display: block;
  margin-left: 3.75rem;
  h3 {
    margin-top: 2.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.375rem;
    color: $matureFontCOlor;
  }
  p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 0.875rem;
    color: $matureFontCOlor;
    span {
      color: $pink;
    }
  }
}

.linkSpan {
  display: inline;
  cursor: pointer;
  color: $adminBlue;
}
