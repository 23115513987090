@import '../../styles/variables';

.container {
  display: flex;
  width: fit-content;
  height: 1.875rem;
  padding: 0.063rem;
  background: linear-gradient(204.93deg, $gray600 3.95%, $gray280 36.77%, $gray550 67.57%, $gray100 100.9%);
  border-radius: 1.25rem;
  .numberContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.125rem;
    border: 0.063rem solid $black;
    background-color: $black;
    border-radius: 1.25rem;
    .number {
      display: flex;
      height: 100%;
      width: 1.313rem;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, $pink500 0%, $pink700 99.99%, $pink800 100%);
      letter-spacing: 0.02em;
      font-size: 1.125rem;
      line-height: 1rem;
      color: $white;
      &:first-child {
        justify-content: flex-end;
        padding-right: 0.188rem;
        border-top-left-radius: 1.25rem;
        width: 1.438rem;
        border-bottom-left-radius: 1.25rem;
      }
      &:last-child {
        justify-content: flex-start;
        padding-left: 0.188rem;
        border-top-right-radius: 1.25rem;
        border-bottom-right-radius: 1.25rem;
      }
    }
  }
}
