@import '/src/styles/variables';

.globalWrapper {
  text-align: start;
  .CardWrapper {
    background-color: #ffffff;
    padding: 0rem;
    height: calc(28.75rem - 0.6rem);
    .bodyComment {
      display: flex;
      flex-direction: column;
      padding: 0.625rem;
      background-color: #ffffff;
      padding: 1.2rem 1.55rem;
      border-radius: 2.5rem;
      p,
      span {
        letter-spacing: 0.02em;
        font-family: 'Roboto';
      }
      .contentWrapper {
        width: 100%;
        height: calc(100% - 5rem);
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        .informations {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          .publishDate {
            font-size: 0.875rem;
          }
          .icons {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .iconContainer {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0.35rem;
              .viewIcon {
                width: 1.5rem;
                height: 1.875rem;
              }
              .likeIcon {
                width: 1.169rem;
              }
              .saveIcon {
                width: 0.813rem;
              }
              .shareIcon {
                width: 0.813rem;
              }
            }
          }
        }
        .comments {
          width: 100%;
          height: calc(100% - 2rem);
          display: flex;
          flex-direction: column;
          .backArrow {
            display: block;
            cursor: pointer;
            color: $gray950;
            transition: color 0.4s;
            margin: 0.4rem 0;
            &:hover {
              color: $black;
            }
          }
          .commentsTitle {
            justify-content: start;
            font-size: 1.125rem;
          }
          .scrollableContent {
            padding: 0.5rem 0;
            gap: 0;
          }
        }
      }
      .commentsFooter {
        position: absolute;
        bottom: 0.625rem;
        left: 1.55rem;
        right: 1.55rem;
        .inputComment {
          background-color: $inputGray;
          font-size: 0.875rem;
          padding-left: 1rem;
        }
        .inputComment::placeholder {
          font-size: 0.875rem;
          line-height: 2.5rem;
          padding-left: 1rem;
        }
        .reportCard {
          display: block;
          text-align: center;
          color: $labelGray;
          font-size: 0.875rem;
          padding-top: 0.25rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .globalWrapper {
    .CardWrapper {
      transform: scale(1.8);
      margin-top: 10rem;
    }
  }
}
