@import './styles/rules';

.App {
  font-family: 'Roboto';
  .main-wrapper {
    display: flex;

    .content {
      display: flex;
      flex-direction: column;
      height: 100vh;
      gap: 1rem;
      width: calc(100vw - 20.625rem);

      .scrolableContent {
        width: 100%;
        height: 95%;
        overflow: auto;
        display: flex;
        @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.675rem);
        & > div {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

iframe.ql-video {
  transform: scale(0.5) translate(-50%, -50%);
  width: 200%;
  max-width: 200% !important;
  height: 24rem;
  margin-bottom: -13rem;
}
