@import 'styles/variables';

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;

  .contentBody {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1.25rem;
    flex-direction: column;
    padding-top: 2.5rem;
    padding-right: 1.6rem;
    .group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-column-gap: 0.813rem;
      width: 100%;
    }

    .defaultInput {
      padding-left: 1rem;
      font-size: 1.5rem;
      line-height: 2.375rem;
      color: $gray950;

      &::placeholder {
        font-size: 1.5rem;
        line-height: 2.375rem;
        padding-left: 0;
        opacity: 1;
      }
    }

    .defaultTextarea {
      background-color: $inputGray;
      border-radius: 1.25rem;
      font-family: 'Roboto';
      width: 100%;

      textarea {
        border: none;
        font-size: 1.5rem;
        line-height: 2.375rem;
        font-weight: 300;
        font-family: 'Roboto';
        letter-spacing: 0.02em;
        padding: 0.563rem 1rem;

        &::placeholder {
          color: $gray450;
          font-size: 1.5rem;
          line-height: 2.375rem;
          font-weight: 300;
          font-family: 'Roboto';
          letter-spacing: 0.02em;
        }
      }
    }

    .fullWidth {
      width: 100%;
    }

    .heightInput {
      margin-top: 0.625rem;

      input {
        height: 3.125rem;
      }
    }
  }
}

.scrapeUrlWrapper {
  border-radius: 2.5rem;
  width: 18.75rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.buttonScrapeDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  button {
    height: 2.5rem;
  }
}

.googleMapBack {
  box-sizing: content-box;
  position: absolute;
  z-index: 99;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  background: white;
  padding: 0;
  border-radius: 1.875rem;
  box-shadow: inset 0 0 3px $gray700;

  .googleClose {
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    width: 2.575rem;
    background: white;
    width: 1.5rem;
    cursor: pointer;
  }
}
.inlineBack {
  box-sizing: content-box;
  position: absolute;
  z-index: 99;
  top: 1.25rem;
  left: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  background: white;
  width: calc(100% - 2.5rem);
  height: calc(100% - 2.5rem);
  padding: 0;
  border-radius: 1.25rem;
  box-shadow: inset 0 0 3px $gray700;

  .googleClose {
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    width: 1.5rem;
    background: white;
    cursor: pointer;
  }
}

.googleMapFront {
  box-sizing: content-box;
  position: absolute;
  z-index: 99;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  background: white;
  padding: 0;
  border-radius: 1.875rem;
  box-shadow: inset 0 0 3px $gray700;

  .googleClose {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1.5rem;
    background: white;
    padding: 0.188rem;
    cursor: pointer;
  }
}

@media only screen and (min-width: $breakpoint-desktops) {
  .inlineBack {
    // width: 62.5rem;
    border-radius: 2.13rem;
    .googleClose {
      right: 1.25rem;
      top: 1.313rem;
      width: 1.5rem;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .content {
    margin-bottom: 2.5rem;
    width: 100%;
    max-width: none !important;

    .contentBody {
      align-items: center !important;
    }
  }
}

@media only screen and (max-width: $breakpoint-web-mobile) {
  .inlineBack {
    .googleClose {
      right: 1rem;
      top: 1rem;
      // background-color: green;
    }
  }
  .content {
    .contentBody {
      align-items: flex-end !important;
      padding-top: 1rem;
      padding-right: 4rem;
      gap: 1.8rem;
      .defaultInput {
        font-size: 1rem;
        &::placeholder {
          font-size: 1rem;
        }
      }
      .group {
        display: block !important;
        > div {
          margin-bottom: 1.5rem;
          height: auto;
        }
      }
    }
    .heightInput {
      margin-top: 1.6rem !important;
    }
  }
  .googleMapBack {
    width: 100%;
    height: 100%;
  }

  .googleMapFront {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.75);

    .googleClose {
      width: 2.75rem;
      padding: 0.5rem;
      top: 0.2rem;
      right: 0.8rem;
      cursor: pointer;
      border-radius: 50%;
      z-index: 25;
    }
  }
}

.mobileBackFields {
  position: relative;
  z-index: 90;

  .mobileButton {
    position: relative;
    border-radius: 50%;
    padding: 0.7rem;
    background-color: #f2f2f2;
  }

  .mobileWrapper {
    box-shadow: 0px 1px 14px -6px #000000;
    border-radius: 3rem 3rem 0 0;
    left: -0.5rem;
    top: -0.5rem;
    > div:first-child {
      height: 5.625rem;
      width: 3.875rem;
    }
  }
}

.mobileContent {
  height: auto !important;
  display: block !important;
  padding: 2rem !important;
  width: 20rem !important;
  margin-top: 0 !important;
  > div {
    margin-bottom: 1.5rem;
    height: auto;
    width: 16rem;
  }
}
