@import 'styles/variables';

.container {
  width: fit-content;
  border-radius: 1.25rem;
  background-color: #ededed;
  display: flex;
  padding: 0.125rem;
  height: 2.5rem;
}

.containerNoBg {
  @extend .container;
  background-color: transparent;
}
