@import '/src//styles/variables';

//Closed Inline in edit
.wrapper {
  position: relative;
  height: 8.75rem;
  background: white;
  border-radius: 1.875rem;
  width: 100%;
  margin-top: 4.75rem;
  transition: all 0.3s cubic-bezier(0.49, -0.27, 0.58, 1);
  .openBtn {
    position: absolute;
    bottom: 0.313rem;
    left: 1.625rem;
    right: 1.625rem;
    z-index: 5;
    box-shadow: 0px -5px 5px 3px white;
    height: 2.5rem;
    width: 80%;
  }
  .bottomContainer {
    position: absolute;
    bottom: 0.625rem;
    height: calc(100% + 6.875rem);
    width: calc(100% - 1.625rem);
    left: 50%;
    transform: translateX(-50%);
    .cardFlipContainer {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      & > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        & > div {
          width: calc(100% - 0.625rem);
          margin-left: 0.313rem;
          transform: scale(0.8);
        }
      }
    }
  }
  //Closed Inline in preview
  &.preview {
    height: 6.875rem;
    .openBtn {
      height: 1.875rem;
    }
    .bottomContainer {
      height: calc(100% + 7.5rem);
      width: calc(100% + 1.25rem);
      .cardFlipContainer {
        & > div {
          & > div {
            width: 100%;
            margin-left: 0;
            transform: scale(0.7);
          }
        }
      }
    }
  }
  //Opened inline
  &.openCard {
    .bottomContainer {
      height: calc(100% + 1.25rem);
      width: calc(100% + 1.25rem);
      bottom: -0.625rem;
      left: -0.625rem;
      right: -0.625rem;
      top: -0.625rem;
      transform: none;
      .cardFlipContainer {
        & > div {
          padding: 0;
          padding-top: 0.625rem;
          bottom: 0;
          & > div {
            transform: scale(1);
          }
        }
      }
    }
    //Opened double card
    &.doubleFlipped {
      .bottomContainer {
        width: calc(100% + 23.125rem);
      }
    }
    //Inline in double card
    &.flippedInDouble {
      .cardFlipContainer {
        & > div {
          padding-top: 0;
        }
      }
    }
  }
}

.wrapper .cardIsOpen {
  overflow: hidden;
  opacity: 0;

  animation: animationAbsolute 0.2s normal forwards, animationFixed 0.5s normal 0.2s forwards;
}

.wrapper .cardIsClosed {
  display: none;
}

.openCardConainer {
  height: calc(100% - 1.5rem);
  position: fixed;
  top: 1rem;
  left: 0.3rem;
  z-index: 10000;
  overflow: hidden;
}

@keyframes animationAbsolute {
  0% {
    position: absolute;
    top: -7rem;
    left: -1.5rem;
    transform: scale(0.5) matrix3d(1, 0, 0, 0, 0, 1, 0, 0.002, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    height: 19rem;
  }
  99% {
    height: 33rem;
  }
  100% {
    opacity: 1;
    position: absolute;
    top: -19rem;
    left: -1.5rem;
    transform: scale(0.6) matrix3d(1, 0, 0, 0, 0, 1, 0, 0.002, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes animationFixed {
  from {
    position: fixed;
    top: unset;
    bottom: 0rem;
    left: 0.3rem;
    transform: scale(0.6) matrix3d(1, 0, 0, 0, 0, 1, 0, 0.002, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
  to {
    position: fixed;
    top: unset;
    bottom: -2rem;
    left: 0.3rem;
    transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    z-index: 1000;
    overflow: inherit;
  }
}
