@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .packsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    height: 35rem;
    overflow: auto;
    @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.675rem);
    .selectionContainer {
      cursor: pointer;
      transform: scale(0.48) translate(-10%, -30%);
      width: 10rem;
      height: 15rem;
      &.selectedPack {
        background: rgba(255, 255, 255, 0.2);
        opacity: 0.5;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.2);
        opacity: 0.5;
      }
    }
  }
  input {
    width: 15rem;
    height: 100%;
    background-color: #ededed;
    border-radius: 1.5rem;
    border: 0px;
    outline: none;
    height: 2rem;
  }
}
