@import 'styles/_variables';

.content {
  background: transparent;
  img {
    border-radius: 50%;
  }

  .avatar {
    object-fit: cover;
  }
}
