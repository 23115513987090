@import 'styles/_variables';

.scrollableContainer {
  justify-content: center;
  display: block;
  max-height: 65%;
  // padding: 0.938rem;
  // margin-top: 1.2rem;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  padding-bottom: 0.938rem;
  .optionsWrapper {
    margin-top: 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.625rem;
    align-items: center;
    // padding-bottom: 0.125rem;
    .options {
      width: 100%;
      border-radius: 1.875rem;
      height: 3.125rem;
      background-color: $doubleSidedTriviaBorder;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 0.3s;
      input {
        background: transparent;
        color: $white;
        font-size: 1.5rem;
        letter-spacing: 0.02em;
        text-align: center;
        padding: 0 0.625rem;
        &::placeholder {
          color: rgba($white, 0.6);
          font-size: 1.5rem;
          line-height: 1.625rem;
          letter-spacing: 0.02em;
          text-align: center;
          padding: 0 0.625rem;
        }
      }
      .optionClose {
        position: absolute;
        display: flex;
        left: 0;
        top: -0.625rem;
        z-index: 55;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .prevOptions {
      @extend .options;
      justify-content: normal;
      overflow: hidden;
      background-color: #cecbcb;
      height: 2.188rem;
      .percentage {
        background: linear-gradient(180deg, #4fa4f3 0%, #1d6f9c 100%);
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        transition: width 500ms ease-in-out;
        span {
          color: $white;
          position: absolute;
          bottom: 0;
          top: 0.438rem;
          left: 0;
          right: 0;
          margin: auto;
          font-size: 1.25rem;
          font-weight: 400;
          font-family: $font-secondary;
        }
        p {
          color: $white;
          opacity: 0;
          position: absolute;
          bottom: 0;
          top: 0.438rem;
          left: 0;
          right: 0;
          margin: auto;
          font-size: 1.25rem;
          font-weight: 400;
          font-family: $font-secondary;
        }
        &:hover {
          cursor: pointer;
          &.showAnswers {
            p {
              opacity: 1;
            }
            span {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
.content {
  justify-content: space-between;
  .pollHeader {
    max-height: 51%;
  }
}
