@import 'styles/_variables';

.searchWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.313rem;
  &.search-md {
    width: 40rem;
  }
  .wrapper {
    width: 100%;
    height: 2.5rem;
    border-radius: 1.25rem;
    background-color: $white;
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0.313rem 0.75rem 0.25rem 0.75rem;
      background-color: $gray280;
      border-radius: 1.25rem;
      .searchIcon {
        margin: 0.563rem 0.438rem 0.313rem 0.188rem;
        img {
          width: 1.563rem;
          height: 1.563rem;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .searchText {
        width: -moz-available;
        width: -webkit-available;
        height: 100%;
        width: 100%;
        display: flex;
        .selectedTopic {
          flex: none;
          margin-right: 0.5rem;
        }
        input {
          background-color: transparent;
          color: $black;
          box-sizing: border-box;
          outline: none;
          border: none;
          font-size: 1.125rem;
          line-height: 1rem;
          height: 100%;
          width: 100%;
        }
        &:focus,
        &:active {
          outline: none;
        }
      }
    }
  }
}
