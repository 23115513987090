.wrapper {
  border-radius: 2.5rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 9.125rem;
  padding: 30px;
  .my_slider {
    width: 60vw;
    .thanksUl {
      transform: translateY(5.6rem);
      .thanks {
        .shuffling {
          width: 100%;
          text-align: center;
        }
      }
    }
    .swipe {
      cursor: pointer;
    }
  }
  img {
    max-width: 100%;
    max-height: 37.625rem;
    width: 100%;
    object-fit: contain;
  }
}
