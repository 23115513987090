@import '../../styles/variables';
.wrapper {
  li {
    list-style-type: none;
  }
  .cardTitle {
    color: $blue;
    font-size: 2rem;
    line-height: 2.125rem;
    letter-spacing: 2%;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.6rem;
  }
  .cardPrice {
    text-align: center;
    p {
      color: $blue;
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
      margin-top: 1.3rem;
    }
  }
  .imgSize {
    width: 100%;
    height: 80vh;
    overflow: hidden;
    overflow-y: auto;
    img {
      width: 100%;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2.5rem;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 0.25rem;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $white;
      border-radius: 2.5rem;
    }
    //   SCROLL COLOR
    &::-webkit-scrollbar-track {
      background: $blue;
    }
  }
}
