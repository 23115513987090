@import '../../styles/variables';

nav {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-right: 5%;
  padding-top: 3%;

  .rightSide {
    display: flex;
    align-items: center;
    .chat {
      margin-right: 3rem;
      position: relative;
      width: 2.813rem;
      height: 2.813rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $inputGray;
      border-radius: 50%;
      img {
        width: 2rem;
        height: 2.3rem;
      }

      .point {
        width: 1vh;
        height: 1vh;
        position: absolute;
        z-index: 50;
        background-color: $adminBlue;
        border: 0.1vh solid $adminBlue;
        border-radius: 50%;
        top: 0.25rem;
        right: 0.3rem;
        transition: all 0.2s ease-in;
      }
    }

    .avatarAndLogoutOpen {
      background: #fff;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.4);
      // margin-right: 0.4rem;
      border-radius: 50%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      position: relative;
    }
    .avatarAndLogout {
      position: relative;
    }
  }
  .avatar {
    object-fit: cover;
  }

  img {
    width: 2.813rem;
    height: 2.813rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 0.2rem;
    cursor: pointer;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      padding: 0 10px;
    }
  }

  .active {
    color: $color-primary;
  }

  h3 {
    margin: 0;
  }

  .bottom {
    width: 3.125rem;
    position: absolute;
    background: #fff;
    right: 3rem;
    bottom: 0;
    height: 3.125rem;
    z-index: 311;
    clip-path: polygon(
      68% 78%,
      54% 85%,
      39% 91%,
      24% 95%,
      12% 98%,
      0 100%,
      100% 100%,
      100% 0,
      98% 22%,
      94% 36%,
      88% 52%,
      80% 66%
    );
  }
}

.wrapperModal {
  position: absolute;
  cursor: auto;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.4);
  border-radius: 1.8rem;
  border-top-right-radius: 0;
  z-index: 100;
  background: #fff;
  padding: 0 1rem;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  width: fit-content;
}
