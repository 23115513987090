@import 'styles/variables';

/* -- Web style -- */
.wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  .content {
    width: 90%;
    max-width: 68.75rem;
    background-color: $white;
    border-radius: 3rem;
    margin-top: 6.25rem;
    position: relative;
  }
}

/* -- Mobile style --*/
@media only screen and (max-width: $breakpoint-web-mobile) {
  .wrapper {
    .content {
      border-radius: 1.875rem;
      padding: 1rem;
    }
  }
}
