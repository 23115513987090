@import 'styles/_variables';

.registerWrapper {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: calc(100vh - 8rem);
  height: calc(100vh - 8rem);

  .cards {
    display: flex;
    justify-content: center;
    height: calc(100vh - 100px);
    align-items: center;
    margin-top: 3rem;

    li {
      list-style-type: none;
      margin: 0.675rem;
    }

    .leftRight {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 4em 1em;
      height: fit-content;

      .left,
      .right {
        font-size: 2rem;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0.2rem;
      }

      .left {
        padding-right: 0.2rem;
      }

      .right {
        padding-left: 0.2rem;
      }
    }
  }
}

.topRegister {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.125rem;

  .centeredDiv {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 1.5rem;
      font-weight: normal;
      letter-spacing: 2%;
      margin: 0;
      margin-top: 0.25rem;
      padding: 0;
    }
  }
}

.topRegisterCenter {
  @extend .topRegister;

  justify-content: center;
}

@media only screen and (max-width: $breakpoint-phones) {
  .topRegister {
    align-items: center;
    button {
      margin-bottom: 1.75rem;
      padding: 0.5rem 1rem;
    }
  }
}
