@import 'styles/_variables';

.content {
  height: 100%;
  .header {
    padding: 0.625rem 0.625rem 0 0.625rem;
    .info {
      p {
        font-size: 0.75rem;
        font-weight: 400;
        padding: 0.25rem 0.188rem 0 0.625rem;
      }
    }
  }
  .icons {
    padding: 0.625rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .users {
    padding: 0.313rem 0.625rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content: space-between;
    overflow-y: hidden;
    height: 8.75rem;
    column-gap: 1.25rem;
    row-gap: 0.625rem;
    .usersColumn {
      // flex: 1;
      width: 19%;
      flex-wrap: wrap;
      flex-direction: column;
    }
    img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
  h6 {
    font-size: 1.125rem;
    font-style: bold;
  }
}
.buttonPosition {
  width: 90%;
  bottom: 0.313rem;
  left: 0.875rem;
  position: absolute;
  justify-content: center;
  text-align: center;
}

.backShareCard {
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 1.4rem;
  padding-top: 0.5rem;
}