@import 'styles/variables';

.confirmationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: white;
  border: 0.125rem solid $labelGray;
  border-radius: 1.875rem;
  position: fixed;
  z-index: 9999999999;
  left: 0;
  right: 0;
  top: calc(40% - 12.25rem);
  padding: 1rem;
  width: 95%;
  height: auto !important;
  max-width: 25rem;
  margin: 0 auto;
  transform: scale(1.35);
  > div {
    display: flex;
  }
  h3 {
    text-align: center;
    margin: 0;
    font-weight: 400;
    letter-spacing: 0.02rem;
    font-family: 'Roboto';
  }
  .buttonsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    padding-top: 0.75rem;
    box-shadow: inset 0px 5px 7px -10px black;
  }
}
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999998;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

@media only screen and (max-width: $breakpoint-web-mobile) {
  .confirmationModal {
    transform: none;
    max-width: 95%;
    padding: 1.7rem;
    h3 {
      font-size: 2rem;
    }
    p {
      font-size: 1.65rem;
    }
    .buttonsContainer {
      & > div {
        height: 2.5rem;
      }
      span {
        font-size: 1.65rem;
      }
    }
  }
}
