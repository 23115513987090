@import '../../styles/variables';

.upperPledgeWrapper {
  max-width: 70rem;
  margin: auto;

  .clickBack {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .back {
      width: 3.125rem;
      height: 3.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      color: #00aee5;
      font-size: 2rem;
      img {
        width: 1.7rem;
      }
    }
  }

  .pledgeWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 62.5rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin: auto;
    border-radius: 2rem;

    .pledgeTitle {
      font-size: 1.75rem;
      font-weight: 700;
      letter-spacing: 0.02em;
      text-align: center;
      color: $blue;
      margin: 1rem 0;
    }

    .pledgeInner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 77%;
      margin-bottom: 2rem;

      .imgAndText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;

        .text {
          @extend .pledgeTitle;
          margin-top: 1rem;
          margin-bottom: 0.5rem;
        }

        img {
          width: 6.25rem;
          height: 6.25rem;
        }
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .centeredDiv {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h3 {
      margin: 0;
    }
  }
}
