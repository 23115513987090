@import '/src/styles/variables';

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  margin-top: -3.125rem;
  .header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1em;
    margin-top: 3rem;
    font-size: 1.5rem;
    color: $adminBlue;
    font-weight: 700;
    width: 100%;
    .close {
      color: $adminBlue;
      font-size: 2rem;
      img {
        cursor: pointer;
      }
    }
    .title {
      text-align: center;
    }
  }

  .applyWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 2rem;
    flex-direction: column;
    width: 52.5rem;

    .form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .fieldsContainer {
        display: flex;
        gap: 2rem;
        align-items: flex-start;
        justify-content: center;
        .halfRow {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: calc(50% - 1rem);
        }
      }
      input {
        margin: 0.594rem 0;
        border-color: #979797 !important;
        text-align: center;
        &::placeholder {
          transform: translateX(0rem);
        }
      }
      .topicSelectionSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 20rem;
        .switchContainer {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .topicSelectionWrapper {
          min-height: 5rem;
          gap: 0.5rem;
          display: flex;
          flex-direction: column;
          .topicSelectionContainer {
            input {
              margin: 0.594rem 0;
              border: 1px solid #979797;
              text-align: center;
              &::placeholder {
                transform: translateX(0.5rem);
              }
            }
          }
        }
      }
    }
  }

  button {
    margin-top: 2.594rem;
  }

  .toLogin {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
    margin: 1.125rem 0;

    span {
      cursor: pointer;
      color: $blue;
      margin-left: 0.25rem;
    }
  }
}

.centeredDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h3 {
    margin: 0;
  }
}
