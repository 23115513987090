.wrapper {
  h1 {
    text-align: center;
    font-size: 1.5rem;
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
  }
}
