@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .packBack {
    position: absolute;
    height: 45.75rem;
    margin-left: 1.75rem;
    z-index: 0;
    margin-top: -9rem;
    animation: slideDownAndFadeOutBackground 1s linear 500ms forwards;
  }

  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1rem;
    position: relative;
    min-height: 35rem;
    transform: scale(0.5);
    margin-top: -7rem;
    animation: cardScale 500ms ease-in-out 1750ms forwards;
  }

  .card {
    position: absolute;
    transition: all 1s ease;
  }
}

@keyframes cardScale {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(0.868);
  }
}

.slider {
  margin-top: -8rem;
  z-index: 1000;
  transition: all ease 250ms;
}

/* ---- Unwrap animation ---- */
.lottie {
  position: absolute;
  top: -13.25rem;
  width: 43.55rem;
  z-index: 1000;
  margin-left: 0.125rem;
  animation: slideDownAndFadeOut 1s linear 500ms forwards;
}
@keyframes slideDownAndFadeOut {
  0% {
    margin-top: 0rem;
  }
  95% {
    opacity: 1;
  }
  100% {
    margin-top: 40rem;
    opacity: 0;
  }
}
@keyframes slideDownAndFadeOutBackground {
  0% {
    margin-top: -16rem;
  }
  95% {
    opacity: 1;
  }
  100% {
    margin-top: 65.5rem;
    opacity: 0;
  }
}

.packActions {
  position: absolute;
  right: 15%;
  bottom: -17rem;
  display: flex;
  align-items: center;
  z-index: 200;
  .close {
    background-color: #fff;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.08);
    margin-left: 1.75rem;
    cursor: pointer;
    img {
      width: 1.25rem;
    }
    &:hover {
      background-color: #cb1c68;
      img {
        filter: invert(100%) sepia(100%) saturate(100%) brightness(200%) contrast(100%);
      }
    }
    &.closeBlue {
      &:hover {
        background-color: $adminBlue;
      }
    }
  }

  .packActions > div > div > div {
    background-color: white;
  }

  .packOptions {
    position: relative;
    width: 10rem;
    > div > div > div > div {
      background: #f9f9f9;
      box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.08);
      &:hover {
        background-color: #cd4d68;
      }
    }
    &.packOptionsBlue {
      > div > div > div > div {
        background-color: #f9f9f9;
        @include changeActionsColor($adminBlue);
        &:hover,
        &:active {
          @include changeActionsColor($adminBlue);
        }
      }
    }
    &.packOptionsBlue {
      > div:first-child > div > div > div {
        background-color: #f9f9f9;
        svg path {
          fill: #f9f9f9 !important;
          stroke: $adminBlue !important;
        }
      }
    }
    &.packOptionsBlue {
      > div:first-child > div > div > div {
        &:hover {
          background-color: $adminBlue;
          svg path {
            fill: #f9f9f9 !important;
            stroke: $adminBlue !important;
          }
        }
      }
    }

    &.activateCard > div:first-child > div > div {
      svg path {
        fill: $adminBlue !important;
        stroke: $adminBlue !important;
      }
    }
    &.activateCard > div:first-child > div > div:hover {
      background-color: #f9f9f9;
    }
    &.activateCard > div:first-child > div > div > div {
      &:hover {
        background-color: #f9f9f9;
        svg path {
          fill: $adminBlue !important;
          stroke: $adminBlue !important;
        }
      }
    }

    &.activateCard > div:nth-child(2) > div > div > div {
      background-color: #f9f9f9;
      @include changeActionsColor($adminBlue);
      &:hover,
      &:active {
        @include changeActionsColor($adminBlue);
      }
    }

    .cardActionOption_content__8daX7 .cardActionOption_option__5qaa8 .cardActionOption_iconWrapper__DXslo.cardActionOption_activable-icons__w1C0M.cardActionOption_active__vGi-3 path {
        fill: $adminBlue;
        stroke: #f9f9f9;
    }
  }
}

@media only screen and (max-width: $breakpoint-web-mobile) {
  .packActions {
    bottom: 20.3rem;
    left: 5rem;
  }
}
