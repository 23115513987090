.wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  background: rgba(128, 125, 125, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  z-index: 11000;

  .pack {
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 5rem;
    position: relative;
  }
}
