@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  .switchAccountsContainer {
    max-height: 15rem;
    overflow: auto;
    font-size: 1rem;
    @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.475rem);
    .switchAccountContainer {
      position: relative;
      .accountTitle {
        font-size: 1.125rem !important;
      }
      .accountSubtitle {
        font-size: 0.8875rem !important;
      }
      .removeSwitchAccountIcon {
        position: absolute;
        top: -0.3rem;
        right: 0;
        width: 2rem;
        padding: 0;
        height: 2rem;
        background-color: white;
        span {
          font-weight: 700;
          color: $pink500;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .addAccountButton {
    margin: 0.313rem 0;
    background: white;
    border: none;
    text-align: left;
    padding: 0 0.6rem 1rem 0.6rem;
    cursor: pointer;
    &:hover {
      background-color: $white;
      opacity: 0.7;
    }
    span {
      color: $pink;
    }
    &.isBussines {
      span {
        color: $adminBlue;
      }
    }
  }
  .removeAccountButton {
    margin: 0.313rem 0;
    width: 100%;
    border: 0.063rem solid $gray950;
    background-color: $white;
    padding: 0 0.5rem;
    &:hover {
      background-color: $white;
    }
    span {
      color: $gray950;
    }
  }
  .removeIcon {
    height: 1.25rem;
    width: auto;
  }
}
