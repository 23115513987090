@charset "UTF-8";
@import 'styles/variables';
@import 'styles/mixins';

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 0.188rem 0.188rem 0 0;
  content: '';
  display: block;
  height: 0.563rem;
  position: absolute;
  top: 0.75rem;
  width: 0.563rem;
}

.react-datepicker__aria-live {
  display: none;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  margin-left: -0.25rem;
  position: absolute;
  width: 0;
  display: none;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 0.5rem solid transparent;
  height: 0;
  width: 0.063rem;
  content: '';
  z-index: -1;
  border-width: 0.5rem;
  left: -0.5rem;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-bottom-color: #e5e5e5;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  top: 0;
  margin-top: -0.5rem;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  top: 0;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  top: -0.063rem;
  border-bottom-color: #e5e5e5;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -0.5rem;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
  bottom: -0.063rem;
  border-top-color: #e5e5e5;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
  input {
    background: $inputGray;
    width: 100%;
    height: 2.5rem;
    outline: none;
    border: none;
    border-radius: 1.875rem;
    padding: 0.625rem;
    padding-left: 1rem;
    font-size: 1.5rem;
    line-height: 2.375rem;
    color: $gray950;
    &::placeholder {
      font-size: 1.5rem;
      line-height: 2.375rem;
      padding-left: 0;
      opacity: 1;
      color: $gray450;
      text-align: left;
    }
  }
}

.react-datepicker {
  font-family: 'Roboto';
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 0.063rem solid #e5e5e5;
  border-radius: 0.375rem;
  display: inline-block;
  position: relative;
  box-shadow: 0 1.5rem 3rem rgba(117, 107, 107, 0.16);
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 2.188rem;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 3.125rem;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^='bottom'] {
  padding-left: 1rem;
  margin-top: -1rem;
}

.react-datepicker-popper[data-placement='bottom-end'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement='top-end'] .react-datepicker__triangle {
  left: auto;
  right: 3.125rem;
}

.react-datepicker-popper[data-placement^='top'] {
  padding-bottom: 0.625rem;
  padding-left: 1rem;
}

.react-datepicker-popper[data-placement^='right'] {
  padding-left: 0.5rem;
}

.react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle {
  left: auto;
  right: 2.625rem;
}

.react-datepicker-popper[data-placement^='left'] {
  padding-right: 0.5rem;
}

.react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
  left: 2.625rem;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff;
  border-bottom: 0.063rem solid #e5e5e5;
  border-top-left-radius: 0.375rem;
  padding: 0.875rem 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 0.5rem;
  padding-left: 0.313rem;
  padding-right: 0.313rem;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.375rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 0.125rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #02011e;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: 'Roboto';
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 0.125rem;
  padding: 0;
  border: none;
  z-index: 1;
  height: 2rem;
  width: 2rem;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 0.125rem;
}

.react-datepicker__navigation--next {
  right: 0.125rem;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 5.313rem;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 0.25rem;
}

.react-datepicker__navigation--years-upcoming {
  top: -0.25rem;
}

.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -0.063rem;
  font-size: 1.25rem;
  width: 0;
}

.react-datepicker__navigation-icon--next {
  left: -0.125rem;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -0.438rem;
}

.react-datepicker__navigation-icon--previous {
  right: -0.125rem;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -0.438rem;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 11.25rem;
}

.react-datepicker__year-select {
  padding: 0.3rem;
  justify-content: center;
  font-size: 1rem;
  border: 0.063rem solid #e5e5e5;
  border-radius: 0.375rem;
  display: inline-block;
  position: relative;
  box-shadow: 0 1.5rem 3rem rgba(117, 107, 107, 0.16);
  @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.25rem);
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 0.125rem;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 0.125rem;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 0.313rem 0 0.625rem;
  padding-left: 1rem 0.938rem;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 0.625rem;
  padding-left: 1rem;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input[type='time']::-webkit-inner-spin-button,
.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input[type='time']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input[type='time'] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 0.313rem;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 0.063rem solid #e5e5e5;
  width: 5.313rem;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 0.063rem solid #e5e5e5;
  border-radius: 0.375rem;
  position: absolute;
  right: -4.5rem;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: #fff;
  border-bottom-right-radius: 0.375rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 5.313rem;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.375rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(12.188rem + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
  overflow: hidden;
  overflow-y: auto;
  @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.25rem);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 1.875rem;
  padding: 0.313rem 0.625rem;
  padding-left: 1rem;
  white-space: nowrap;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $pink;
  color: white;
  font-weight: bold;
}

.isBussines
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $adminBlue;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: $pink;
}
.isBussines
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: $adminBlue;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.375rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -0.5rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #686b70;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-family: 'Roboto';
  font-weight: 500;
}

.react-datepicker__day-name {
  font-weight: 400;
  margin-top: 1rem;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 50%;
  background-color: $pink;
  color: #fff;
}
.isBussines .react-datepicker__month--selected,
.isBussines .react-datepicker__month--in-selecting-range,
.isBussines .react-datepicker__month--in-range,
.isBussines .react-datepicker__quarter--selected,
.isBussines .react-datepicker__quarter--in-selecting-range,
.isBussines .react-datepicker__quarter--in-range {
  background-color: $adminBlue;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
  transition: all 0.3s;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
  background-color: #e5e5e5;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
  background: $gray200;
  border-radius: 50%;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.375rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50%;
  background-color: $pink;
  color: #fff;
}

.isBussines .react-datepicker__day--selected,
.isBussines .react-datepicker__day--in-selecting-range,
.isBussines .react-datepicker__day--in-range,
.isBussines .react-datepicker__month-text--selected,
.isBussines .react-datepicker__month-text--in-selecting-range,
.isBussines .react-datepicker__month-text--in-range,
.isBussines .react-datepicker__quarter-text--selected,
.isBussines .react-datepicker__quarter-text--in-selecting-range,
.isBussines .react-datepicker__quarter-text--in-range,
.isBussines .react-datepicker__year-text--selected,
.isBussines .react-datepicker__year-text--in-selecting-range,
.isBussines .react-datepicker__year-text--in-range {
  background-color: $adminBlue;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: lighten($pink, 10);
}

.isBussines .react-datepicker__day--selected:hover,
.isBussines .react-datepicker__day--in-selecting-range:hover,
.isBussines .react-datepicker__day--in-range:hover,
.isBussines .react-datepicker__month-text--selected:hover,
.isBussines .react-datepicker__month-text--in-selecting-range:hover,
.isBussines .react-datepicker__month-text--in-range:hover,
.isBussines .react-datepicker__quarter-text--selected:hover,
.isBussines .react-datepicker__quarter-text--in-selecting-range:hover,
.isBussines .react-datepicker__quarter-text--in-range:hover,
.isBussines .react-datepicker__year-text--selected:hover,
.isBussines .react-datepicker__year-text--in-selecting-range:hover,
.isBussines .react-datepicker__year-text--in-range:hover {
  background-color: lighten($adminBlue, 10);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50%;
  background-color: $pink;
  color: #fff;
}

.isBussines .react-datepicker__day--keyboard-selected,
.isBussines .react-datepicker__month-text--keyboard-selected,
.isBussines .react-datepicker__quarter-text--keyboard-selected,
.isBussines .react-datepicker__year-text--keyboard-selected {
  background-color: $adminBlue;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: lighten($pink, 10);
}

.isBussines .react-datepicker__day--keyboard-selected:hover,
.isBussines .react-datepicker__month-text--keyboard-selected:hover,
.isBussines .react-datepicker__quarter-text--keyboard-selected:hover,
.isBussines .react-datepicker__year-text--keyboard-selected:hover {
  background-color: lighten($adminBlue, 10);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range
  .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
  .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: $pink;
}
.isBussines .react-datepicker__month-text.react-datepicker__month--selected:hover,
.isBussines .react-datepicker__month-text.react-datepicker__month--in-range:hover,
.isBussines .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.isBussines .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.isBussines .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.isBussines .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.isBussines .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.isBussines .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: $adminBlue;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 0.063rem solid transparent;
  border-radius: 0.375rem;
  position: relative;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -1rem;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 1.875rem;
  z-index: 1;
  text-align: center;
  border-radius: 0.375rem;
  border: 0.063rem solid #e5e5e5;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 9.375rem;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 1.25rem;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 0.938rem;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 0.375rem 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: $pink;
  color: #fff;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  padding: 0.125rem;
  font-size: 0.75rem;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: '×';
}
.isBussines .react-datepicker__close-icon::after {
  background-color: $adminBlue;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 0.063rem solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 0.313rem 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.wrapper-hide-input {
  height: 0;
}

.hide-input {
  display: none;
}
