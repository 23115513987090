@import '../../styles/variables';
@import '../../styles/mixins';

.wrapper {
  min-width: 20.625rem;
  max-width: 20.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #f3f3f3;
  height: fit-content;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  min-height: 100vh;
  max-height: 100vh;
  height: fit-content;
  overflow: hidden;
  overflow-y: auto;
  @include changeScrollColor(lighten($adminBlue, 1.5%), lighten($gray280, 2.5%), 0.275rem);

  .wonderLogo {
    margin-top: 2rem;
    cursor: pointer;
    width: 20rem;
  }

  ul {
    // height: calc(100% - 190px);
    // overflow: hidden;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .inactive {
      background: #e1e1e1;
      border-radius: 2rem;
      min-height: 3.125rem;
      width: 15.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.75rem 0;
      color: black;
      font-size: 1.125rem;
      font-weight: 400;
      cursor: pointer;
    }

    .active {
      @extend .inactive;
      background: #00aee5;
      color: white;
      cursor: pointer;
      a {
        color: white;
      }
    }
  }

  .reportsOpened {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin-left: 2rem;

    a {
      margin: 0.25rem 0;
      letter-spacing: 0.02rem;
    }

    .activeNav {
      font-weight: bolder;
    }

    .spanActive {
      font-weight: bolder;
    }

    .spanInactive {
      font-weight: normal;
    }
  }

  .reportsClosed {
    display: none;
  }

  .sidebarImg {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    filter: blur(0px);
    .upperBottom {
      height: 7.5rem;
      width: 15.625rem;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 2rem;
      margin-bottom: 1rem;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      span {
        height: 2.5rem;
        width: 100%;
        color: white;
        font-size: 1.125rem;
        line-height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &:hover {
      transform: scale(1.048);
      transform-origin: 50% 0;
    }
    &.hide {
      opacity: 0;
      cursor: default;
    }
  }
}
