.form {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h3 {
    margin: 0;
    text-align: center;
  }

  .text {
    font-size: 0.8rem;
    text-align: justify;
    margin-bottom: 1rem;

    span {
      cursor: pointer;
      text-decoration: underline;
      color: #1138e7;
    }
  }

  .cardElement {
    margin: 2rem 0;
  }

  .button {
    width: 100%;
    display: flex;
    justify-content: center;

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loading::after {
      content: '';
      width: 15px;
      height: 15px;
      border: 5px solid white;
      border-top-color: #1138e7;
      border-radius: 50%;
      animation: loading 1s linear infinite;
    }

    @keyframes loading {
      to {
        transform: rotate(1turn);
      }
    }
  }
}
