@import '/src/styles/variables';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .icon {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-left: 5px;
  }
}
