@import '../../../styles/variables';
@import '../../../styles/mixins';

.applicantsWrapper {
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  .filterWrapper {
    padding: 0 2rem;
    padding-bottom: 1rem;
  }
  .bottomFilterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    padding-bottom: 1rem;
    .clearAndTotalContainer {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }

  .tableHeader {
    display: flex;
    align-items: center;
    font-weight: 800;

    .singleCell {
      width: 22%;
      letter-spacing: 0.05rem;

      &:first-child {
        padding-left: 2.5rem;
      }
    }
    .showButton {
      letter-spacing: 0.05rem;
    }
  }
  .tableRows {
    height: 76%;
    overflow: auto;
  }

  .editButton {
    width: 2rem;
    height: 2rem;
    background-color: $adminBlue;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 50%;
    }
  }
}
.actionButton {
  margin: 0;
  width: 100%;
  height: 2rem;
}

.active {
  width: 4rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: $adminBlue;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: 1s;
  cursor: pointer;

  .inner {
    width: 1.6rem;
    height: 1.6rem;
    background-color: white;
    border-radius: 50%;
    margin: 0.2rem;
  }
}
.inActive {
  @extend .active;
  background-color: $gray400;
  justify-content: flex-start;
}
.clearButton {
  height: 2.5rem;
  width: 3rem;
  background: $gray280 !important;
  span {
    color: $gray800;
  }
}
.filterReplace {
  height: 2.5rem;
  width: 2rem;
  margin-bottom: 1rem;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
