.wrapper {
  width: 100%;

  .content {
    width: 98%;
    margin: 0 auto;

    .contentHeader {
      width: 56.8%;
      display: flex;
      justify-content: space-between;
      margin-left: 3.2rem;

      span {
        font-family: 'Roboto', sans-serif;
        font-size: 1.125rem;
        font-weight: 700;
      }
    }

    .innerContent {
      margin-top: 1.8rem;
      width: 100%;
      max-height: 80%;
      overflow-y: auto;
      .emptyMessageMargin {
        margin: 10% 0;
      }
    }
  }
}
