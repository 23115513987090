@import '../../../../styles/variables';

.scrollable {
  margin-top: 0.625rem;
  margin-bottom: 1.825rem;
  height: calc(40% - 2.45rem);
  padding: 0 0.938rem;
  gap: 0.188rem;
  justify-content: flex-start;
  color: $cardColor-event-body-text;
  &.withFooterButton {
    height: calc(40% - 4.45rem);
  }
  span {
    font-size: 1.5rem;
    line-height: 1.375rem;
    text-align: left;
    &.description {
      font-weight: 300;
    }
    &.location,
    &.timeAndDate {
      font-weight: 600;
    }
  }
  .captionContainer {
    // position: absolute;
    bottom: 4rem;
    left: 0;
    right: 0;
    text-align: left;
    &.bottomBorder {
      border-bottom-left-radius: 2.5rem;
      border-bottom-right-radius: 2.5rem;
    }
    span {
      width: 100%;
      font-size: 0.75rem;
      line-height: 1.188rem;
      letter-spacing: 0.02em;
      color: $white;
      word-break: break-word;
    }
    .openLinkButton {
      margin: 1rem auto 0;
      font-size: 0.75rem;
      height: unset;
      padding: 0.25rem 0.5rem;
    }
  }
}
.footerButton {
  width: auto;
  position: absolute;
  bottom: 0.625rem;
  left: 0.625rem;
  right: 0.625rem;
}
.previewTab {
  .image {
    width: 100%;
    height: 60%;
    background-size: cover;
    background-position: center;
    border-radius: 1.875rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: relative;
    background-color: $cardColor-event-secondary;
  }
  .emptyDiv {
    width: 100%;
    height: 60%;
    border-radius: 1.875rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: relative;
    background-color: $eventBg;
  }
  .scrollable {
    span {
      font-size: 1.125rem;
      line-height: 1.125rem;
      text-align: left;
      &.description {
        font-weight: 300;
      }
      &.location,
      &.timeAndDate {
        font-weight: 600;
      }
    }
  }
}

.topGap {
  margin-top: 0.5rem;
}

.loader {
  > div {
    display: flex;
    position: absolute;
    top: 0;
    z-index: 11;
    height: 100%;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
}
