@import 'styles/variables';

.container {
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  max-height: 40%;
  .row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.438rem;
    .headingContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
      .heading {
        width: 100%;
        font-family: 'Roboto-bold';
        &.showElipsisForUsername {
          margin: unset;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      .subheading {
        width: 100%;
      }
      .dialog {
        max-width: 88%;
        border-radius: 0.875rem;
        font-size: 1rem;
        line-height: 1.3rem;
        font-family: 'Roboto';
        font-weight: 300;
        background-color: white;
        color: black;
        border: 0.063rem solid rgb(216, 215, 215);
        -webkit-box-shadow: 0px 5px 13px -5px #000000;
        box-shadow: 0px 5px 9px -9px #000000;
        margin-top: -4rem;
        margin-left: -0.8rem;
        padding-left: 0.7rem;
        padding-right: 0.7rem;
        opacity: 1;
      }
    }
  }
  &.position-block {
    width: calc(100% - 1.25rem);
    margin: 0.625rem 0.625rem 0 0.625rem;
  }
  &.position-floating {
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
    right: 0.625rem;
    width: auto;
    z-index: 10;
  }
  .cloudCorner {
    position: absolute;
    left: 0;
    bottom: -1.271rem;
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.15));
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    // svg {
    //   width: 25%;
    // }
  }
  //Type - create
  &.color-create {
    background: $createBorder;
    color: $createColor;
    .cloudCorner {
      svg path {
        fill: $createBorder;
      }
    }
  }
  // Type - standard
  &.color-standard {
    background: $cardColor-standard-primary;
    color: $standardColor;
    .cloudCorner {
      svg path {
        fill: $cardColor-standard-primary;
      }
    }
  }
  //Type - instacard
  &.color-instacard {
    background: $standardBorder;
    color: $standardColor;
    .cloudCorner {
      svg path {
        fill: $standardBorder;
      }
    }
  }
  //Type - blank
  &.color-blank {
    background: $cardColor-blank-primary;
    color: $cardColor-blank-bubble-text;
    .cloudCorner {
      svg path {
        fill: $cardColor-blank-primary;
      }
    }
  }
  &.color-bussines {
    background: $adminBlue;
    color: $white;
    .cloudCorner {
      svg path {
        fill: $adminBlue;
      }
    }
  }
  //Type - slide
  &.color-slide {
    background: $cardColor-slide-primary;
    color: $cardColor-slide-bubble-text;
    .cloudCorner {
      svg path {
        fill: $cardColor-slide-primary;
      }
    }
  }
  //Type - double
  &.color-double {
    background: $cardColor-double-primary;
    color: $cardColor-double-bubble-text;
    .cloudCorner {
      svg path {
        fill: $cardColor-double-primary;
      }
    }
  }
  //Type - collectible
  &.color-collectible {
    background: $cardColor-collectible-primary;
    color: $cardColor-collectible-bubble-text;
    .cloudCorner {
      svg path {
        fill: $cardColor-collectible-primary;
      }
    }
  }
  //Type - marketplace
  &.color-marketplace {
    background: $cardColor-marketplace-primary;
    color: $cardColor-marketplace-bubble-text;
    .cloudCorner {
      svg path {
        fill: $cardColor-marketplace-primary;
      }
    }
  }
  //Type - mystery
  &.color-mystery {
    background: linear-gradient(180deg, $mysteryBorder1 0%, $mysteryBorder2 100%);
    color: $cardColor-mystery-bubble-text;
    .cloudCorner {
      svg path {
        fill: $mysteryBorder2;
      }
    }
  }
  //Type - doubleSidedTrivia
  &.color-doubleSidedTrivia {
    background: $cardColor-doubleSidedTrivia-primary;
    color: $cardColor-doubleSidedTrivia-bubble-text;
    .cloudCorner {
      svg path {
        fill: $cardColor-doubleSidedTrivia-primary;
      }
    }
  }
  //Type - poll
  &.color-poll {
    background: $cardColor-poll-primary;
    color: $cardColor-poll-bubble-text;
    .cloudCorner {
      svg path {
        fill: $cardColor-poll-primary;
      }
    }
  }
  //Type - oneSidedTrivia
  &.color-oneSidedTrivia {
    background: $cardColor-oneSidedTrivia-primary;
    color: $cardColor-oneSidedTrivia-bubble-text;
    .cloudCorner {
      svg path {
        fill: $cardColor-oneSidedTrivia-primary;
      }
    }
  }
  //Type - event
  &.color-event {
    background: $cardColor-event-primary;
    color: $cardColor-event-bubble-text;
    .cloudCorner {
      svg path {
        fill: $cardColor-event-primary;
      }
    }
  }
  //Type - sponsored
  &.color-sponsored {
    background: $cardColor-sponsored-primary;
    color: $cardColor-sponsored-bubble-text;
    .cloudCorner {
      svg path {
        fill: $cardColor-sponsored-primary;
      }
    }
  }
  //Type - partnership
  &.color-partnership {
    background: #00aee5;
    color: $white;
    .cloudCorner {
      svg path {
        fill: #00aee5;
      }
    }
  }
  //Type - sceleton
  &.color-sceleton {
    background: $gray;
    color: $gray;
    .cloudCorner {
      svg path {
        fill: $gray;
      }
    }
  }
  &.size-extraSmall {
    padding: 0.1665rem 0;
    border-radius: 0.475rem 0.475rem 0.475rem 0;
    .scrollableContent {
      padding: 0.0714rem 0.2424rem;
      overflow: hidden;
      .headingContainer {
        gap: 0.131rem;
        padding-left: 0.1425rem;
      }
      .heading {
        font-size: 0.665rem;
        line-height: 0.76rem;
        font-weight: bold;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &.showElipsisForUsername {
          margin: unset;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      .subheading {
        font-size: 0.38rem;
        line-height: 0.475rem;
        font-weight: 300;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &.position-floating {
      top: 0.2165rem;
      left: 0.2165rem;
      right: 0.2165rem;
    }
    &.position-block {
      width: calc(100% - 0.6rem);
      margin: 0.325rem 0.325rem 0 0.325rem;
    }
  }
  &.size-normal {
    padding: 0.438rem 0;
    border-radius: 1.25rem 1.25rem 1.25rem 0;
    .scrollableContent {
      padding: 0.188rem 0.638rem;
      overflow: hidden;
      .headingContainer {
        gap: 0.344rem;
        padding-left: 0.375rem;
      }
      .heading {
        font-size: 1.75rem;
        line-height: 2.2rem;
        font-weight: bold;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &.showElipsisForUsername {
          margin: unset;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
      .subheading {
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 300;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  &.size-large {
    padding: 1.25rem 0 0.875rem 0;
    border-radius: 1.875rem 1.875rem 1.875rem 0;
    .scrollableContent {
      padding: 0.188rem 1.75rem 0.188rem 1.75rem;
      .headingContainer {
        gap: 0.625rem;
        .heading {
          font-size: 1.5rem;
          line-height: 1.875rem;
          font-weight: bold;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          &.showElipsisForUsername {
            margin: unset;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .subheading {
          font-size: 1rem;
          line-height: 1.688rem;
          font-weight: 300;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
  &.size-large-with-logo {
    padding: 0.938rem 0;
    border-radius: 1.875rem 1.875rem 1.875rem 0;
    margin: 0;
    .scrollableContent {
      padding: 0.188rem 0.625rem 0.188rem 1.125rem;
      max-height: 100%;
      .row {
        gap: 0.5rem;
        .headingContainer {
          gap: 0.313rem;
        }
        .heading {
          font-size: 1.875rem;
          line-height: 1.875rem;
          font-weight: bold;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          &.showElipsisForUsername {
            margin: unset;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .subheading {
          font-size: 1.5rem;
          line-height: 1.688rem;
          font-weight: 100;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
  &.m-10 {
    margin: 0.625rem;
  }
  &.m-5 {
    width: calc(100% - 0.625rem);
    margin: 0.313rem;
  }
  /* --- Bubble color for the "General User" Cards --- */
  &.color-generalUser-standard {
    background-color: $cardColor-generalUser-primary !important;
    color: $cardColor-generalUser-bubble-text !important;
    .cloudCorner {
      svg path {
        fill: $cardColor-generalUser-primary !important;
      }
    }
  }
  /* --- Bubble color for the "Wonder Shuffle Plus Subscribers" Cards --- */
  &.color-businessSubscriber-standard,
  &.color-businessSubscriber-slide {
    background-color: $cardColor-businessSubscriber-primary !important;
    color: $cardColor-businessSubscriber-bubble-text !important;
    .cloudCorner {
      svg path {
        fill: $cardColor-businessSubscriber-primary !important;
      }
    }
  }
  /* --- Bubble color for the "Media" Cards --- */
  &.color-media-standard,
  &.color-media-slide,
  &.color-media-instacard {
    background-color: $cardColor-media-primary !important;
    color: $cardColor-media-bubble-text !important;
    .cloudCorner {
      svg path {
        fill: $cardColor-media-primary !important;
      }
    }
  }
  /* --- Bubble color for the "Primetime Advertiser" Cards --- */
  &.color-primetimeAdvertiser-standard,
  &.color-primetimeAdvertiser-slide,
  &.color-primetimeAdvertiser-double,
  &.color-primetimeAdvertiser-poll,
  &.color-primetimeAdvertiser-instacard {
    background-color: $cardColor-primetimeAdvertiser-primary !important;
    color: $cardColor-primetimeAdvertiser-bubble-text !important;
    .cloudCorner {
      svg path {
        fill: $cardColor-primetimeAdvertiser-primary !important;
      }
    }
    &.staticBoxShadowForNewsletter {
      background-color: rgb(233, 233, 233) !important;
      .cloudCorner {
        svg path {
          fill: rgb(233, 233, 233) !important;
        }
      }
    }
  }
}
.autoHeight {
  &.container {
    &.size-normal {
      .scrollableContent {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        overflow-y: auto;
        .heading {
          -webkit-line-clamp: unset;
        }
        .subheading {
          -webkit-line-clamp: unset;
        }
      }
    }
    &.size-large {
      .scrollableContent {
        .headingContainer {
          .heading {
            -webkit-line-clamp: unset;
          }
          .subheading {
            -webkit-line-clamp: unset;
          }
        }
      }
    }
    &.size-large-with-logo {
      .scrollableContent {
        .row {
          .heading {
            -webkit-line-clamp: unset;
          }
          .subheading {
            -webkit-line-clamp: unset;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1921px) {
  .container {
    .cloudCorner {
      bottom: -1.23rem;
    }
  }
}
@media screen and (max-width: 1920px) and (min-width: 1681px) {
  .container {
    .cloudCorner {
      bottom: -1.23rem;
    }
  }
}
@media screen and (max-width: 1680px) and (min-width: 1600px) {
  .container {
    .cloudCorner {
      bottom: -1.32rem;
    }
  }
}
@media screen and (max-width: 1599px) and (min-width: 1360px) {
  .container {
    .cloudCorner {
      bottom: -1.42rem;
    }
  }
}
@media screen and (max-width: 1359px) and (min-width: 1024px) {
  .container {
    .cloudCorner {
      bottom: -1.9rem;
    }
  }
}
@media screen and (max-width: 1023px) and (min-width: $breakpoint-desktops) {
  .container {
    .cloudCorner {
      bottom: -2.33rem;
    }
  }
}

@media screen and (max-width: $breakpoint-desktops) and (min-width: $breakpoint-tablets) {
  .container {
    .cloudCorner {
      bottom: -2.4rem;
      // svg {
      //   width: auto;
      // }
    }
  }
}

@media screen and (max-width: $breakpoint-tablets) {
  .container {
    .cloudCorner {
      bottom: -2.86rem;
    }
    &.size-normal {
      .scrollableContent {
        .heading {
          font-size: 1.4rem;
        }
      }
    }
  }
}
