@import 'styles/_variables';
.container {
  height: calc(100% - 0.625rem);
  // display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 30;
  margin: 0.313rem;
  border-radius: 1.563rem;
  position: relative;
  .activeContent {
    padding: 0 0.313rem 0 0.313rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.625rem;
    h6 {
      text-align: left;
      font-size: 1.25rem;
      font-weight: 700;
      color: $black;
      padding: 0.313rem 0 0.313rem 0.563rem;
    }
    .showImg {
      background-color: transparent;
      border-radius: 10%;
      width: 100%;
      height: auto;
      padding-left: 0.313rem;
    }
    h3 {
      margin: unset;
    }
    p {
      font-size: 14;
      font-weight: 400;
      line-height: 1.5rem;
      color: $black;
      text-align: justify;
      margin: unset;
    }
  }
  .headerFont {
    font-size: 1.5rem;
  }
  .avatarSize {
    width: 3.25rem;
    height: 3.25rem;
  }
  .backFields {
    margin-bottom: 3.75rem;
    margin-top: 1.563rem;
    width: 100%;
  }
}
