.typesContainer {
  box-shadow: 0px 0px 1px rgba(75, 77, 82, 0.48), 0px 0.5rem 1rem #4b4d523d;
  border-radius: 2rem;
  width: 80%;
  margin: 0 auto;
  max-height: 80%;
  padding: 3rem 1rem 3rem 1rem;
  display: flex;
  justify-content: space-around;
}

.buttonContainerCenter{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  h3 {
    margin-top: 1rem;
  }
}
