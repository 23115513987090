@import 'styles/_variables';
@import 'styles/_mixins';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .selectedTopicsContainer {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 8rem;
    gap: 0.5rem 0.75rem;
    padding: 0.25rem 0;
    @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.675rem);
  }
}
