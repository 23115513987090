@import '../../styles/variables';

.videoPicker {
  position: relative;
  border: 1px dashed #000000;
  background-color: #f8f6f1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  img.noVideoIcon {
    margin: 2.9375rem 0;
    width: 4.375rem;
  }
  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($white, 0.8);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loadingContainer {
    position: absolute;
    z-index: 110;
    width: 100%;
    height: 0.875rem;
    bottom: 1px;
    left: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .loadingBar {
      background: $pink;
      width: 0;
      height: 100%;
      transition: width 0.3s ease-in;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-radius: 0.625rem;
    }
    .loadingText {
      font-size: 0.75rem;
      font-weight: 500;
      &.loadingTextInner {
        color: white;
        padding-right: 0.625rem;
      }
      &.loadingTextOuter {
        color: $gray950;
        padding-left: 0.3125rem;
      }
    }
  }
}

.videoPickerXS {
  @extend .videoPicker;
  height: 6.875rem;
  img {
    width: 3.5625rem;
    height: 3.4375rem;
  }
}

.prevFoto {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  left: 0.625rem;
  top: 0.625rem;
}
.autoHeight {
  height: auto;
}
