@import '../../../../styles/variables';
@import 'styles/mixins';

.container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .slidesContainer {
    width: 100%;
    height: 100%;
    .slide {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      transition: opacity ease-in-out 0.4s;
      padding: 1.25rem 0.938rem 2.5rem 0.938rem;
      // display: flex;
      flex-direction: column;
      gap: 0.625rem;
      justify-content: space-between;
      &.noDots {
        padding: 1.25rem 0.938rem 0.938rem 0.938rem;
      }
      .question {
        font-size: 1.25rem;
        line-height: 1.25rem;
        text-align: left;
        color: $doubleSidedTriviaBorder;
        flex-shrink: 2.5;
        overflow-y: auto;
        @include changeScrollColor(
          darken($doubleSidedTriviaBorder, 5%),
          lighten($doubleSidedTriviaBorder, 70%),
          0.25rem
        );
      }
      .scrollableQuestionContainer {
        flex-shrink: 2.5;
        overflow-y: auto;
        span {
          text-align: left;
          font-size: 1.25rem;
          line-height: 1.25rem;
          word-break: break-word;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: lighten($createBorder, 35%);
          border-radius: 2.5rem;
        }
        /* width */
        &::-webkit-scrollbar {
          width: 0.25rem;
        }
        &::-webkit-scrollbar-track {
          background: lighten($doubleSidedTriviaBorder, 60%);
        }
        &::-webkit-scrollbar-thumb {
          background: darken($doubleSidedTriviaBorder, 5%);
          border-radius: 2.5rem;
        }
      }
      .scrollableOptionsContainer {
        padding-left: 0.313rem;
        padding-right: 0.313rem;
        justify-content: center;
        display: block;
        flex-shrink: 1;
        min-height: 20%;
        .answersContainer {
          margin-top: 0.625rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 0.625rem;
          align-items: center;
          .answer {
            width: 100%;
            border-radius: 1.875rem;
            height: 3.025rem;
            background-color: $doubleSidedTriviaBorder;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 0.3s;
            &.clickable {
              cursor: pointer;
              height: 2.188rem;
            }
            span {
              color: $white;
              font-size: 1.175rem;
              letter-spacing: 0.02em;
              text-align: center;
              padding: 0 0.313rem;
              word-break: break-all;
            }
            &.disabledClass {
              cursor: not-allowed;
              opacity: 0.5;
            }
            input {
              background: transparent;
              color: $white;
              font-size: 1.375rem;
              letter-spacing: 0.02em;
              text-align: center;
              padding-left: 2rem;
              &::placeholder {
                color: rgba($white, 0.6);
                font-size: 1.375rem;
                line-height: 1.625rem;
                letter-spacing: 0.02em;
                text-align: center;
                padding: 0 0.625rem;
              }
            }
            &.notChoosenAnswer {
              background: $labelGray;
            }
            &.correctAnswer {
              background: linear-gradient(258.47deg, #dddb4c -11.21%, #9bb111 128.95%);
            }
            &.wrongAnswer {
              background: linear-gradient(180deg, #b92e11 0%, #a62715 100%);
            }
            .optionClose {
              position: absolute;
              display: flex;
              left: 0;
              top: -0.625rem;
              z-index: 55;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      &.activeAnim {
        opacity: 1;
        gap: 0.5rem;
        padding-top: 1.5rem;
      }
    }
  }
  &.previewSlider {
    height: calc(100% - 3.75rem);
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    box-shadow: 0px 4px 4px rgba($black, 0.25);
  }
  &.size-extraSmall {
    .slidesContainer {
      .slide {
        padding: 0.5rem 0.238rem 0.5rem 0.238rem;
        .scrollableQuestionContainer {
          height: 32%;
          span {
            font-size: 0.6rem;
            padding-left: 0.3rem;
            line-height: 0.8rem;
          }
          /* width */
          &::-webkit-scrollbar {
            width: 0.15rem;
          }
        }
        .scrollableOptionsContainer {
          padding-left: 0.313rem;
          padding-right: 0.313rem;
          .answersContainer {
            margin-top: 0.625rem;
            gap: 0.2rem;
            .answer {
              &.clickable {
                height: 0.9rem;
              }
              span {
                font-size: 0.6rem;
                padding: 0 0.313rem;
              }
              input {
                background: transparent;
                color: $white;
                font-size: 1.375rem;
                letter-spacing: 0.02em;
                text-align: center;
                padding-left: 2rem;
                &::placeholder {
                  color: rgba($white, 0.6);
                  font-size: 1.375rem;
                  line-height: 1.625rem;
                  letter-spacing: 0.02em;
                  text-align: center;
                  padding: 0 0.625rem;
                }
              }
              .optionClose {
                position: absolute;
                display: flex;
                left: 0;
                top: -0.625rem;
                z-index: 55;
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
.closeContainer {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  z-index: 50;
  .closeIcon {
    cursor: pointer;
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    z-index: 10;
    display: none;
    &.active {
      display: block;
    }
  }
}
.containerDots {
  position: absolute;
  z-index: 45;
  bottom: 0.625rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 0.125rem;
  .dot {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin: 0 0.313rem;
    background: $lightGray;
    transition: background-color ease-in-out 0.2s;
    &:hover {
      background-color: $doubleSidedTriviaBorder;
      &.disableDot {
        cursor: not-allowed;
        background: $lightGray;
      }
    }
    &.active {
      background-color: $doubleSidedTriviaBorder;
    }
  }
}
