@import 'styles/variables';

.wrapper {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    .mediaPicker {
      max-height: 10rem;
    }

    .leftContent {
      width: 25rem;
      object-fit: contain;
      height: auto;
      max-height: 25rem;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;

      .buttonContainer {
        width: 100%;
        position: absolute;
        bottom: 0.5rem;

        display: flex;
        justify-content: center;

        .button {
          max-width: 80%;
        }
      }

      .imageError {
        position: absolute;
        top: 0.25rem;
        text-align: left;
        left: 0.25rem;
        color: #fe6e6e;
      }
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;

      > div {
        margin-bottom: 2rem;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-web-mobile) {
  .content {
    flex-direction: row !important;
    justify-content: space-around !important;
    align-items: stretch !important;

    .leftContent {
      margin-left: 5rem !important;
    }

    .rightContent {
      margin-top: 0 !important;
      width: 48% !important;

    }
  }
}
