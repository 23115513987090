.instaHelpWrapper {
  text-align: left;

  .oneProp {
    .instcardHelpTitle {
      font-weight: 600;
      margin-bottom: 0;
      text-align: left;
      text-decoration: underline;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    ul {
      li {
        margin: 0;
        margin-bottom: 0.2rem;
        span {
          font-style: italic;
          cursor: unset;
          color: unset;
          // font-weight: 600;
        }
      }
    }
  }
}
