@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 0 0.625rem;
  margin-bottom: 1.438rem;

  .iconWrapper {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    svg {
      width: 60%;
      height: 60%;
    }
    // TYPE CREATE
    @include changeActionsColor;
  }
  &.size-small {
    .iconWrapper {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  // TYPE SPONSORED
  &.color-sponsored {
    .iconWrapper {
      @include changeActionsColor($sponsoredBorder);
    }
  }
  // TYPE SLIDE
  &.color-slide {
    .iconWrapper {
      @include changeActionsColor($standardBorder);
    }
  }
  // TYPE INSTACARD
  &.color-instacard {
    .iconWrapper {
      @include changeActionsColor($standardBorder);
    }
  }
  // TYPE BLANK
  &.color-blank {
    .iconWrapper {
      @include changeActionsColor($standardBorder);
    }
  }
  // TYPE DOUBLE
  &.color-double {
    .iconWrapper {
      @include changeActionsColor($standardBorder);
    }
  }
  // TYPE COLLECTIBLE
  &.color-collectible {
    .iconWrapper {
      @include changeActionsColor($collectibleBorder2);
    }
  }
  // TYPE MYSTERY
  &.color-mystery {
    .iconWrapper {
      @include changeActionsColor($mysteryBorder2);
    }
  }
  &.color-marketplace {
    .iconWrapper {
      @include changeActionsColor(darken($marketplaceBorder2, 20));
    }
  }
}

@media only screen and (max-width: 480px) {
  .content {
    .iconWrapper {
      width: 2.525rem;
      height: 2.525rem;
    }
  }
}
