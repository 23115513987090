@import './variables';

@mixin xxs {
  @media only screen and (max-width: 450px) {
    @content;
  }
}

@mixin xs {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin md {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}

@mixin xlg {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (max-width: 1920px) {
    @content;
  }
}
//Usage:
// 1. Import:
// @import "../../styles/mixins";
// 2. Using:
// &:hover {
//     transition: 0.1s;
//     @include xl {
//       transition: 1s;
//     }
//   }
@mixin noUserSelect {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@mixin paddingContainer {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 2rem;
  padding-bottom: 3.625rem;
  @include lg {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  @include md {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  @include xs {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  @include xxs {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}

@mixin textOneLine($numOfLines: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $numOfLines;
  line-clamp: $numOfLines;
  -webkit-box-orient: vertical;
}
@mixin changeScrollColor($scrollCollotr, $trackCollor, $scrollWidth: 0.25rem, $scrollHeight: 0.75rem) {
  /* Track */
  &::-webkit-scrollbar-track {
    background: $trackCollor;
    border-radius: 2.5rem;
  }
  /* width */
  &::-webkit-scrollbar {
    width: $scrollWidth;
    height: $scrollHeight;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $scrollCollotr;
    border-radius: 2.5rem;
  }
}
@mixin changeActionsColor($primaryColor: $standardBorder) {
  path {
    fill: $primaryColor;
  }
  circle {
    fill: $primaryColor;
  }
  &.activable-icons {
    path {
      fill: none;
      stroke: $primaryColor;
    }
    circle {
      fill: none;
      stroke: $primaryColor;
    }
  }
  rect {
    fill: $primaryColor;
  }
  &:hover {
    cursor: pointer;
    background-color: $primaryColor;
    path {
      fill: $white;
    }
    circle {
      fill: $white;
    }
    &.activable-icons {
      path {
        fill: none;
        stroke: $white;
      }
      circle {
        fill: none;
        stroke: $white;
      }
    }
    rect {
      fill: $white;
    }
  }
  &.activable-icons {
    &.active {
      path {
        stroke: $primaryColor;
        fill: $primaryColor;
      }
      circle {
        stroke: $primaryColor;
        fill: $primaryColor;
      }
      rect {
        fill: $white;
        fill: $white;
      }
      &:hover {
        path {
          stroke: $white;
          fill: $white;
        }
        circle {
          stroke: $white;
          fill: $white;
        }
        rect {
          fill: $primaryColor;
          fill: $primaryColor;
        }
      }
    }
  }
}
