@import '../../styles/variables';
@import '../../styles/mixins';

.btn {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.563rem;
  padding: 0.5rem 2.5rem;
  transition: 0.4s;
  cursor: pointer;
}

// BtnClass

.primary {
  @extend .btn;
  background: $pink;
  &.isBussines {
    background-color: $adminBlue;
  }
  &:active,
  &:hover {
    background-color: lighten($pink, 15%);
    &.isBussines {
      background-color: lighten($adminBlue, 15%);
    }
  }
  .icon {
    width: 2.5rem !important;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  span {
    color: $white;
    font-size: 1.125rem;
    line-height: 1rem;
  }
  i {
    margin-left: 0.313rem;
  }
}

.secondary {
  @extend .btn;
  background: $blue;

  &:hover {
    background: $light-blue;
  }

  &:active {
    background: $light-blue;
  }

  &:disabled {
    background: $gray500;
    cursor: not-allowed;
  }

  span {
    color: $white;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1rem;
  }
}

.tertiary {
  @extend .btn;
  background: $gray !important;
  &.isBussines {
    background-color: $adminBlue;
  }
  &:active,
  &:hover {
    background-color: darken($gray, 5%);
    &.isBussines {
      background-color: lighten($adminBlue, 15%);
    }
  }
  .icon {
    width: 2.5rem !important;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  span {
    color: $gray950;
    font-size: 1.125rem;
    line-height: 1rem;
  }
  i {
    margin-left: 0.313rem;
  }
}

.gray {
  @extend .btn;
  background: $gray;

  &:active {
    background: $blue;

    span {
      color: $white;
    }
  }

  &:hover {
    background: $blue;

    span {
      color: $white;
    }
  }

  span {
    color: $black;
  }
}

.darkGray {
  @extend .btn;
  background: $gray850;

  &:hover {
    background: $gray800;
  }

  &:active {
    background: $gray800;
  }

  &:disabled {
    background: $gray500;
    cursor: not-allowed;
  }

  span {
    color: $white;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1rem;
  }
}

.transparent {
  @extend .btn;
  background: transparent;
  border: 0.063rem solid $pink500;

  &:hover {
    background: transparent;
  }

  span {
    color: $pink500;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1rem;
  }
}

.ribbonColor {
  outline: none;
  display: flex;
  align-items: center;
  justify-content: left;
  transition: 0.4s;
  cursor: pointer;
  border: none;
  width: 100%;
  height: 2.5rem;
  border-radius: 1.563rem;
  font-size: 1.5rem;
  background: $inputGray;
  font-weight: 400;
  line-height: 1rem;
  padding-left: 1rem;
}

// Size
.xs {
  height: 2.313rem;

  @media only screen and (min-width: $breakpoint-desktops) {
    height: 2.5rem;
  }
}

.md {
  height: 2.5rem;
  width: 15.625rem;
  @media only screen and (min-width: $breakpoint-desktops) {
    height: 2.5rem;
  }
}

.twenty {
  height: 2.5rem;
  width: 20%;
  @media only screen and (min-width: 992px) {
    height: 2.5rem;
  }
}
.stripeButton {
  height: 2.5rem;
  width: 40%;
}
.fitContent {
  height: 2.5rem;
  width: fit-content;
}
.xxs {
  height: 2rem;
  width: 8rem;
}

.labelMax {
  max-width: 10ch;

  @media only screen and (min-width: $breakpoint-desktops) {
    max-width: 12ch;
  }
}
