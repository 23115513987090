.thanksWrapper {
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-align: center;

  h2 {
    font-size: 1.625rem;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: center;
    color: #00aee5;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }

  .button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
