@import 'styles/_variables';
@import '/src/styles/mixins';

.contactFormWrapper {
  h2 {
    text-align: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    font-size: 2.25rem;
    font-weight: 400;

    .close {
      cursor: pointer;
      color: #00aee5;
    }
  }
  .singleInputRow {
    display: flex;
    margin: 1em;
    justify-content: space-around;
    width: 90%;
    .textAreaContainer {
      width: 88%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      border: 0.063rem solid #c4c4c4 !important;
      background: white;
      border-radius: 2rem;
      textarea {
        font-family: 'Roboto';
        letter-spacing: 0.02rem;
        font-weight: 400;
        font-size: 1.125rem;
        width: 100%;
        min-height: 4.813rem;
        height: -moz-fit-content;
        height: fit-content;
        padding: 1rem 0.5rem;
        margin: 0.594rem 0;
        resize: none;
        background: transparent;
        color: $gray900;
        border: none;
        @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.325rem);
        &:focus {
          border: none;
          outline: none;
        }
        &:disabled {
          color: $gray700 !important;
          cursor: not-allowed;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
  }
}
