@import 'styles/variables';

.container {
  background-color: transparent;
  color: $black;
  font-size: 1.125rem;
  letter-spacing: 0.02em;
  border-radius: 1.25rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0 2rem;
  flex-grow: 1;
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.size-normal {
    min-width: 6.25rem;
  }
  &.size-large {
    min-width: 8.125rem;
  }
  &.authTab-medium {
    min-width: 15.125rem;
  }
  &.active {
    background-color: $adminBlue;
    color: $white;
  }
  &:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: $breakpoint-web-mobile) {
  .container {
    &.size-normal {
      min-width: 10rem;
      font-size: 0.875rem;
    }
  }
}
