@import '/src/styles/variables';

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 63rem;
  height: 100%;
  margin: auto;
  margin-top: -3.125rem;

  .clickBack {
    width: 110%;
    display: flex;
    justify-content: flex-end;

    .back {
      width: 3.125rem;
      height: 3.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      color: $blue;
      font-size: 2rem;
      img {
        width: 1.563rem;
      }
    }
  }

  .form {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .applyWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 63.25rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2rem;
    flex-direction: column;

    .title {
      font-weight: 400;
      font-size: 1.125rem;
      font-family: 'Roboto';
      margin: 1rem 0;
      margin-top: 1.5rem;
    }

    .info {
      text-align: center;
      font-size: 1.125rem;
      margin: 10px 0;
    }

    .applyLeftSide {
      max-width: 22.25rem;
      display: flex;
      flex-direction: column;

      input {
        margin: 0.594rem 0;
        border-color: #979797 !important;
        text-align: center;
        &::placeholder {
          transform: translateX(0rem);
        }
      }
    }

    .applyRightSide {
      display: flex;
      flex-direction: column;
      max-width: 22.25rem;

      textarea {
        border: 1px solid #979797;
        border-radius: 2rem;
        min-height: 11.8rem;
        height: fit-content;
        padding: 1rem;
        margin: 0.594rem 0;
        resize: none;
        font-family: 'Roboto';
        font-size: 1.125rem;
        &::placeholder {
          font-size: 1.125rem;
          line-height: 2rem;
          font-weight: 400;
          text-align: center;
          vertical-align: baseline;
          color: #c4c4c4;
          font-family: 'Roboto';
        }
      }

      input {
        margin: 0.594rem 0;
        border-color: #979797 !important;
        text-align: center;
        &::placeholder {
          transform: translateX(0rem);
        }
      }
    }
  }

  button {
    margin-top: 2.594rem;
  }

  .toLogin {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
    margin: 1.125rem 0;

    span {
      cursor: pointer;
      color: $blue;
      margin-left: 0.25rem;
    }
  }
}

.centeredDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h3 {
    margin: 0;
  }
}
