@import 'styles/_variables';
@import 'styles/_mixins';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  .reportReasons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 30rem;
    height: 34rem;
    overflow: hidden;
    background: $gray100;
    border-radius: 2.5rem;
    gap: 1.5rem;
    box-shadow: inset 0 1px 7px -1px $gray400;
    & > h2 {
      width: 100%;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 0.02em;
      color: $gray950;
    }
    .container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      overflow: auto;
      margin-bottom: 2rem;
      width: 100%;
      @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.475rem);
    }
  }
  .itemEdit {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .header {
      h2 {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1rem;
        letter-spacing: 0.02em;
        color: #00aee5;
        margin-top: 0;
      }

      h3 {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.3rem;
        letter-spacing: 0.02em;
        text-align: center;
        padding: 1rem 0;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;

      .rightSide {
        display: flex;
        flex-direction: column;
        width: 32rem;
        justify-content: space-around;

        .buttonAndDescription {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            width: 14.875rem;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 0.875rem;
            letter-spacing: 0.02em;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-tablets) and (max-width: $breakpoint-web-mobile) {
  .wrapper {
    .reportReasons {
      width: 25rem;
    }
  }
}
@media only screen and (min-width: $breakpoint-phones) and (max-width: $breakpoint-tablets) {
  .wrapper {
    .reportReasons {
      width: 20rem;
    }
  }
}
@media only screen and (max-width: $breakpoint-phones) {
  .wrapper {
    .reportReasons {
      display: none;
    }
  }
}
