@import 'styles/variables';

form {
  .confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    > label {
      padding-top: unset !important;
      padding-left: unset !important;
    }
    .checboks {
      padding-top: 1.738rem;
      padding-left: 1.6rem;
      padding-right: 1rem;
    }
    span {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.1rem;
      color: #979797;
    }

    .forgotPassword {
      color: $gray450;
      font-size: 1.125rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .inputSingle {
    margin-top: 1.466rem;
    & > div {
      & > div {
        .loginEmail {
          text-align: center;
        }
        .loginEmail::placeholder {
          margin-left: -3rem;
        }
      }
    }
  }

  .inputSingle2 {
    margin-top: 1.466rem;
    margin-bottom: 0.5rem;
    & > div {
      & > div {
        .loginEmail {
          text-align: center;
        }
        .loginEmail::placeholder {
          margin-left: -3.2rem;
        }
      }
    }
  }

  .buttonSingle {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2.46rem;
    margin-top: 0.5rem;
  }

  .goRegister {
    margin-top: 0;
    font-family: 'Roboto';
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.02em;
    text-align: center;
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .authTitle {
    color: $blue;
    font-size: 1.125rem;
    margin: 0;
    font-weight: 400;
  }

  > div {
    width: 100%;
    margin-bottom: 1.25rem;
  }

  button {
    width: 100%;
    margin-top: 0.625rem;
  }

  .link {
    font-size: 0.875rem;
    padding-top: 0.75rem;
    // color: $pink500;
    // font-family: $font-secondary;
    cursor: pointer;
  }
}

input {
  text-align: center;

  &::placeholder {
    text-align: center;
  }
}
