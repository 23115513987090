@font-face{
  font-family: 'Roboto';
  src: local('Roboto'), url('../assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-display: block;
}
@font-face{
  font-family: 'Roboto-medium';
  src: local('Roboto'), url('../assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
  font-display: block;
}
@font-face{
  font-family: 'Roboto-bold';
  src: local('Roboto'), url('../assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-display: block;
}
// @font-face{
//   font-family: 'Titillium';
//   src: local('Titillium'), url('../assets/fonts/titillium/TitilliumWeb-Black.ttf') format('truetype');
// }

// @font-face{
//   font-family: 'Titillium-Regular';
//   src: local('Titillium'), url('../assets/fonts/titillium/TitilliumWeb-Regular.ttf') format('truetype');
// }

// @font-face{
//   font-family: 'TitilliumWeb-SemiBold';
//   src: local('Titillium'), url('../assets/fonts/titillium/TitilliumWeb-SemiBold.ttf') format('truetype');
// }

// @font-face{
//   font-family: 'TitilliumWeb-Bold';
//   src: local('Titillium'), url('../assets/fonts/titillium/TitilliumWeb-Bold.ttf') format('truetype');
// }

// @font-face{
//   font-family: 'Arial-Black';
//   src: local('Arial'), url('../assets/fonts/Arial-Black.ttf') format('truetype');
// }
