.wrapper {
  display: flex;
  justify-content: space-between;
  width: 96.5%;
  margin: auto;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 0.5rem;

  .selectors {
    width: 90%;
    display: flex;
    justify-content: space-between;
    .singleSelector {
      min-width: 16.625rem;
      height: 3.125rem;
    }
  }

  .circle {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    background: #00aee5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 2rem;
      height: 2rem;
    }
  }
}

.contentForModal {
  display: flex;
  justify-content: space-around;

  .confirm {
    display: flex;
    align-items: center;

    span {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.1rem;
      color: #979797;
      margin-left: 1rem;
    }

    .active {
      width: 5.188rem;
      height: 2.813rem;
      border-radius: 23px;
      background-color: #00aee5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      transition: 1s;
      cursor: pointer;

      .inner {
        width: 2.313rem;
        height: 2.313rem;
        background-color: white;
        border-radius: 50%;
        margin: 0.2rem;
      }
    }

    .inActive {
      @extend .active;
      background-color: #ededed;
      justify-content: flex-start;
    }
  }
}
