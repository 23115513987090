.wrapper {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 2rem;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .uploader {
      box-shadow: 0.1rem 0.25rem 0.5rem rgba(0, 0, 0, 0.4);
      border-radius: 2.5rem;
      max-width: 48%;
      max-height: 100%;

      h2,
      h3 {
        text-align: center;
      }
    }
  }
}
