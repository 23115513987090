@import 'styles/mixins';
@import 'styles/variables';

.wrapper {
  max-width: 98%;
  margin: 0 auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 2rem;

  .filterContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;

    input {
      margin-left: 1.438rem;
      width: 18.375rem;
      height: 100%;
      background-color: #ededed;
      border-radius: 2rem;
      border: 0px;
      outline: none;
      height: 3rem;
    }

    button {
      margin: 0;
    }
  }

  .tableHeader {
    width: 92%;
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
    margin-bottom: 1rem;
    .singleCell {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        height: 1rem;
        width: 1rem;
        margin-left: 0.5rem;
      }
      span {
        font-size: 1rem;
      }
    }
  }

  .tableRowsContainer {
    height: 60vh;
    overflow: auto;

    @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.675rem);
  }
}
