.cardWrapper {
  cursor: pointer;
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .position {
      .images {
        width: 8rem;
      }
    }
  }
}
