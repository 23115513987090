@import '../../styles/variables';

.wrapper {
  textarea::-webkit-outer-spin-button,
  textarea::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  textarea[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  .textarea {
    background-color: #f8f6f1;
    border: 0.063rem dashed #000000;
    font-family: 'Roboto';
    width: 100%;
    padding: 0.625rem 1rem;
    font-family: $font-roboto;
    font-size: 1.5rem;
    line-height: 2.375rem;
    font-weight: 400;
    outline: none;
    resize: none;
    &::placeholder {
      font-size: 1.5rem;
      line-height: 2.375rem;
      font-weight: 400;
      color: $matureFontCOlor;

      font-family: 'Roboto';
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:active {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &[type='password'] {
      letter-spacing: 0.063rem;
    }

    &[type='password']::placeholder {
      letter-spacing: initial;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 0.25rem;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: $white;
      border-radius: 2.5rem;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $pink;
      border-radius: 2.5rem;
    }
  }

  .error,
  .errorMsg {
    color: $error-color;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 0.75rem;
  }
  .errorMsg {
    padding-left: 0.938rem;
    display: inline-block;
  }
}

.textareaDefault {
  @extend .wrapper;
}

.textarea-gray {
  @extend .wrapper;
  width: 100%;
  .textarea {
    background-color: $inputGray;
    border-radius: 1.25rem;
    border: none;
    font-size: 1.5rem;
    line-height: 1.625rem;
    font-weight: 300;
    font-family: 'Roboto';
    letter-spacing: 0.02em;
    padding: 0.563rem 1rem;
    &::placeholder {
      color: $gray450;
      font-size: 1.5rem;
      line-height: 2.375rem;
      font-weight: 300;
      font-family: 'Roboto';
      letter-spacing: 0.02em;
    }
  }
}

.textarea-gray-100 {
  @extend .textarea-gray;

  .textarea {
    height: 6.125rem;
  }
}

.textarea-customize-feed-left {
  @extend .wrapper;
  width: 100%;
  margin-top: 0.313rem;
  .textarea {
    background-color: #ededed;
    border-radius: 1.563rem;
    border: none;
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 400;
    font-family: 'Roboto';
    letter-spacing: 0.019rem;
    height: 12.5rem;
    color: #030303;
    padding: 1.313rem;
    &::placeholder {
      color: $gray450;
      font-size: 1.5rem;
      line-height: 2.375rem;
      font-weight: 300;
      font-family: 'Roboto';
      letter-spacing: 0.019rem;
    }
  }
}

.textarea-customize-feed-right {
  @extend .wrapper;
  width: 100%;
  margin-top: 0.313rem;
  .textarea {
    background-color: #ededed;
    border-radius: 1.563rem;
    border: none;
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 400;
    font-family: 'Roboto';
    letter-spacing: 0.019rem;
    height: 5rem;
    color: #030303;
    padding: 1.313rem;
    &::placeholder {
      color: $gray450;
      font-size: 1.5rem;
      line-height: 2.375rem;
      font-weight: 300;
      font-family: 'Roboto';
      letter-spacing: 0.019rem;
    }
  }
}

.report-input {
  @extend .wrapper;
  width: 100%;
  & > div {
    width: 100%;
    .textarea {
      background-color: $inputGray;
      border-radius: 1.25rem;
      border: none;
      font-size: 1.1275rem;
      font-weight: 300;
      font-family: 'Roboto';
      letter-spacing: 0.02em;
      padding: 0.563rem 1rem;
      height: 6.125rem;
      &::placeholder {
        color: $gray450;
        font-size: 1.1275rem;
        line-height: 2.375rem;
        font-weight: 300;
        font-family: 'Roboto';
        letter-spacing: 0.02em;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-web-mobile) {
  .report-input {
    & > div {
      .textarea,
      .textarea::placeholder {
        font-size: 1.6rem;
        line-height: normal;
      }
    }
  }
}
