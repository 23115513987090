@import '../../styles/variables';

.quilFieldForNewsletter {
  font-family: $font-roboto;
  position: relative;
  overflow-x: hidden;
  width: 100%;

  .ql-container {
    position: relative;
    height: 50vh;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .ql-editor {
    background: #fff;
    font-family: $font-roboto;
    text-align: justify;
  }

  .ql-snow .ql-editor h3 {
    text-align: left;
  }
  .ql-snow .ql-picker {
    margin-top: 0.625rem;
    border: 2px solid gray;
    border-radius: 1rem;
    &.ql-color-picker,
    &.ql-icon-picker {
      border: none;
    }
  }

  .ql-toolbar.ql-snow {
    padding: 0.5rem 1rem;
    background: #f2f2f2;
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
    button {
      margin-top: 0.625rem;
    }
  }

  .ql-toolbar {
    position: relative;
  }

  .ql-formats {
    position: relative;
    background: #f2f2f2;
    border-radius: 0.625rem;
  }

  .ql-editor.ql-blank {
    border: none;
  }

  .ql-tooltip.ql-editing {
    left: 0.438rem !important;
    transform: translateY(-60%);
    width: 95%;
    top: 30px;

    input {
      width: 87%;
    }
  }

  .ql-header.ql-picker {
    width: 15rem;
  }

  .ql-color.ql-picker.ql-color-picker {
    margin-top: 0.69rem;
  }

  .ql-background.ql-picker.ql-color-picker {
    margin-top: 0.625rem;
  }

  .ql-align.ql-picker.ql-icon-picker {
    margin-top: 0.625rem;
  }

  .ql-snow .ql-editor a {
    display: inline-block;
  }
}
