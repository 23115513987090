@import 'styles/variables';
@import 'styles/mixins';
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .container {
    background-color: $inputGray;
    padding: 1.875rem;
    border-radius: 1.25rem;
    width: 31.25rem;
    padding: 0.5rem 1rem;
    &.full-width {
      width: 100%;
    }
    &.full-width-fixed {
      width: 100%;
    }
    .content {
      border-radius: 0.625rem;
      overflow: auto;

      @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.25rem);
      .tagContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 0.313rem;
        margin-bottom: 0.8rem;
        min-height: 1.875rem;
      }
      input {
        background-color: transparent;
        border: none;
        width: 100%;
        font-size: 1.4rem;
        color: $gray950;
        text-align: left;
        padding-bottom: 2rem;
        &::placeholder {
          opacity: 1;
          color: #c4c4c4;
          white-space: pre-line;
          position: relative;
          text-align: left;
        }
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  .error,
  .errorMsg {
    color: $error-color;
    // font-family: $font-primary;
    font-weight: 500;
    font-size: 0.75rem;
    position: absolute;
    bottom: -1.125rem;
  }
  .errorMsg {
    padding: 0.125rem 0.938rem;
    display: inline-block;
  }
}
@media only screen and (max-width: $breakpoint-web-mobile) {
  .container {
    &.full-width-fixed {
      width: 100%;
      .content {
        height: 6.5rem;
      }
    }
    .content {
      input {
        font-size: 16px;
        height: 7rem;
        &::placeholder {
          top: -10px;
        }
      }
    }
  }
  .error,
  .errorMsg {
    font-size: 1.2rem;
    bottom: -1.5rem;
  }
}
