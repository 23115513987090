@import '../../../styles/variables';

.wrapper {
  display: flex;
  border: 0.063rem solid #c4c4c4;
  border-radius: 1.563rem;
  align-items: center;
  position: relative;
  margin: 0.594rem 0;
  height: 3.125rem;
  input {
    text-align: left !important;
    &::placeholder {
      color: #c4c4c4;
      font-size: 1.125rem;
      text-align: left !important;
    }
  }

  .input {
    border: none;
    width: 100%;
    border-top-right-radius: 1.563rem;
    border-bottom-right-radius: 1.563rem;
    font-size: 1.125rem;
    padding: 0 1.563rem;
    // height: 3.125rem;
    height: 100%;
    padding-left: 2.5rem;
    border-radius: 1.563rem;

    &:disabled {
      cursor: not-allowed;
    }

    &:active {
      outline: none;
    }

    &:focus {
      outline: none;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  .selectForPhone {
    width: 12rem;
    & > div {
      & > div {
        background-color: white;
      }
    }
  }

  .prefixSelectContainer {
    width: 6.25rem;
    height: 3rem;
    display: flex;
    align-items: center;
    background-color: white;
    border-top-left-radius: 1.563rem;
    border-bottom-left-radius: 1.563rem;
    position: absolute;
    z-index: 999;

    :global(.prefix-select__placeholder) {
      color: $placeholderCenter;
      width: 6.25rem;
    }

    :global(.prefix-select__control) {
      width: 6.25rem;
      border: none;
      box-shadow: none;
      border-top-left-radius: 1.563rem;
      border-bottom-left-radius: 1.563rem;
      display: flex;
      div:nth-child(1) {
        width: 6.25rem;
      }
      div:nth-child(2) > span {
        display: none;
      }
    }
  }

  .error,
  .errorMsg {
    color: $error-color;
    font-weight: 500;
    font-size: 0.688rem;
    position: absolute;
    bottom: -1.125rem;
    width: 100%;
    text-align: left;
  }

  .errorMsg {
    padding: 0.125rem 0.938rem;
    display: inline-block;
  }
}

.error {
  p {
    color: #fe6e6e;
    font-weight: 500;
    font-size: 0.688rem;
    position: absolute;
    padding-left: 0.625rem;
    margin-top: -0.5rem;
  }
}
.phoneSelect {
  width: 22.25rem;
  margin: 0.594rem 0;
  border-color: #979797 !important;
  text-align: center;
  &::placeholder {
    transform: translateX(0rem);
  }
}
.phoneSelectGray-24 {
  width: 24rem;
  background-color: $inputGray !important;
  text-align: center;
  border: none;
  height: 2.5rem;
  margin: 0.25rem;
  &::placeholder {
    transform: translateX(0rem);
  }
  input {
    background-color: transparent !important;
    font-size: 1.25rem !important;
    margin-top: 0.25rem;
  }
  .selectForPhone {
    & > div {
      & > div {
        background-color: transparent !important;
      }
    }
  }
}
.phoneSelectLarge {
  @extend .phoneSelect;
  width: 25.25rem;
}

@media screen and (max-width: 480px) {
  .wrapper {
    .error,
    .errorMsg {
      font-size: 1.2rem;
      bottom: -1.5rem;
    }
  }
}
