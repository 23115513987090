.inviteUserWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    font-size: 1.5rem;
    color: #00aee5;
    font-weight: 700;

    .close {
      cursor: pointer;
      color: #00aee5;
      font-size: 2rem;
    }
  }

  .text {
    max-width: 27.375rem;
    font-size: 1.125rem;
    margin: auto;
    text-align: center;
    font-weight: 400;
  }

  .inputsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    button {
      width: 2rem;
      height: 2rem;
      color: white;
      background-color: #00aee5;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      margin-left: 1rem;
    }

    .inputs {
      height: 12.5rem;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      .singleInput {
        display: flex;
        align-items: center;

        input {
          background-color: #ededed;
          border-radius: 28px;
          border: none;
          margin: 0.5rem;
          min-height: 2rem;
          width: 16.25rem;
          text-align: start;
          padding-left: 1rem;
        }
        ::placeholder {
          text-align: start;
        }

        span {
          font-size: 1.5rem;
          color: #00aee5;
          margin-left: -2rem;
          cursor: pointer;
        }
      }
    }
  }

  .buttonDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 1rem;
    button {
      width: 12.125rem;
    }
  }
}
