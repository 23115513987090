@import '../../styles/variables';
.wrapper {
  height: 100%;
  .list {
    position: relative;
    width: 100%;
    .iconBlock {
      position: absolute;
      right: 0.375rem;
      top: 0.25rem;
      z-index: 9;
      display: flex;
      i {
        cursor: pointer;
        color: #cc4469;
        padding: 0.125rem;
        margin-left: 0.313rem;
      }
      &.icon-standard {
        i {
          color: $standardBorder;
        }
      }
      &.icon-slide {
        i {
          color: $standardBorder;
        }
      }
      &.icon-double {
        i {
          color: $standardBorder;
        }
      }
      &.icon-collectible {
        i {
          color: $collectibleBorder2;
        }
      }
      &.icon-mystery {
        i {
          color: $mysteryBorder2;
        }
      }
      &.icon-sponsored {
        i {
          color: $sponsoredBorder;
        }
      }
    }
  }
}
