.userSingleWrapper {
  width: 95%;
  margin: 1rem auto;
  display: flex;
  justify-content: space-evenly;

  .innerWrapper {
    width: 90%;
    // max-width: 52.813rem;
    height: 3rem;
    background-color: #ededed;
    border-radius: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    .edit {
      width: 2.5rem;
      height: 2.5rem;
      background-color: #cb1c68;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50%;
      }
    }

    span {
      font-size: 1.125rem;
      width: 24%;
      word-break: break-all;
      // width: fit-content;
    }

    img {
      width: 2.313rem;
      height: 2.313rem;
      border-radius: 50%;
    }
  }

  .active {
    width: 5.188rem;
    height: 2.813rem;
    border-radius: 23px;
    background-color: #00aee5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 1s;
    cursor: pointer;

    .inner {
      width: 2.313rem;
      height: 2.313rem;
      background-color: white;
      border-radius: 50%;
      margin: 0.2rem;
    }
  }

  .inActive {
    @extend .active;
    background-color: #ededed;
    justify-content: flex-start;
  }
}

.userSingleWrapper100 {
  @extend .userSingleWrapper;

  width: 100%;

  .innerWrapper {
    width: 100%;
  }
}
