.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin: 1rem auto;

  .innerWrapper {
    width: 100%;
    height: 2.813rem;
    background-color: #ededed;
    border-radius: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    .singleCell {
      width: 19%;
      margin: 0 1rem;
    }

    .edit {
      width: 2.5rem;
      height: 2.5rem;
      background-color: #00aee5;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50%;
      }
    }
  }
}
