@import '/src/styles/_variables.scss';

.wrapper {
  background: white;
  border-radius: 1.875rem;
  border: 0.625rem solid #ededed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 18.75rem;
  height: 28.75rem;
  align-items: center;
  padding: 0.625rem;
  // margin: 0 -2em;

  .cardTitle {
    color: $blue;
    font-size: 2rem;
    line-height: 2.125rem;
    letter-spacing: 2%;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0.6rem;
  }

  .cardContent {
    height: 70%;
    overflow-y: auto;
    li {
      list-style-type: none;
      margin: 0.675rem;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2.5rem;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 0.25rem;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $white;
      border-radius: 2.5rem;
    }
    //   SCROLL COLOR
    &::-webkit-scrollbar-track {
      background: $blue;
    }
  }

  .cardPrice {
    text-align: center;
    p {
      color: $blue;
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
      margin-top: 1.3rem;
    }
  }

  iframe,
  img {
    width: 100%;
    height: 100%;
  }
}

.scaledWrapper {
  @extend .wrapper;
  transition: 1s;
  transform: scale(1.2);

  iframe,
  img {
    width: 100%;
    height: 100%;
  }
}
