@import '../../styles/variables';
@import '../../styles/mixins';

.checkbox {
  display: flex;
  align-items: center;
  height: 1.25rem;
  @include noUserSelect;

  @include sm {
    height: 1rem;
  }
  input {
    margin: 0;
    border: 0.125rem solid #abadaf;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  img {
    height: 1rem;
    display: none;
  }
  
  .checkmark,
  .checkmarkDisabled {
    display: flex;
    height: 1.25rem;
    width: 1.25rem;
    // border-radius: 0.25rem;
    border: 0.125rem solid $gray500;
    background-color: $white;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    @include sm {
      width: 1rem;
      height: 1rem;
    }
  }

  input:checked ~ .checkmark {
    height: 1.5rem;
    width: 1.5rem;
    img {
      display: block;
    }
  }
  .checkmarkDisabled {
    background-color: $gray800;
    // border: none;
    
  }
}

@media screen and (max-width: 480px) {
  .checkbox {
    .checkmark {
      @include sm {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}