@import '../../styles/variables';

.selectErr {
  color: $red !important;
  margin: 0;
}

.selectPrimary {
  width: 100%;
  height: 2.5rem;
  position: relative;
  // font-family: $font-primary;
  &.isDisabled {
    opacity: 0.85;
    &:hover {
      cursor: pointer;
    }
    cursor: not-allowed !important;
    .selectPrimary__input-container {
      cursor: not-allowed;
    }
  }
  label {
    text-align: left;
    display: block;
    color: $gray600;
    font-size: 0.75rem;
    padding-bottom: 0.375rem;
    // font-family: $font-primary;
    span {
      color: $red !important;
    }
  }

  .selectPrimary__value-container {
    position: relative;
    padding: 0.125rem 0.5rem;
    width: 8.75rem;
  }

  .selectPrimary__input-container {
    margin: 0.125rem;
    padding-bottom: 0.125rem;
    padding-top: 0.125rem;
    padding-left: 0.9rem;
  }

  .selectPrimary__container:focus {
    outline: none;
  }

  .selectPrimary__control {
    border: none;
    background-color: none;
    min-height: 2.5rem;
    padding: 0 0.313rem;
    // background-color: $inputGray;
    background-color: transparent;
    border-radius: 1.25rem;
    cursor: pointer;
    box-shadow: none;
  }

  .selectPrimary__single-value {
    font-size: 1.438rem;
    line-height: 2.375rem;
    // font-family: $font-primary;
    background-color: transparent;
    font-weight: 400;
    color: $gray950;
  }

  .selectPrimary__option {
    text-align: left;
    cursor: pointer;
    background: $white;
    @extend .selectPrimary__single-value;
    display: flex;
    align-self: center;
    transition: all 0.3s;
    margin: 0 0.563rem !important;
    width: calc(100% - 1.125rem);
    padding: 0.5rem;
    border-radius: 0.375rem;
    font-size: 1.25rem;
    line-height: 0.875rem;
    &:active {
      color: $white;
    }

    &:hover {
      background: rgba($pink, 0.25);
      color: $black;
    }
  }

  .selectPrimary__menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    z-index: 2;
    border-radius: 0.313rem;
    outline: none !important;
    margin-top: 0.375rem;

    .selectPrimary__option {
      color: $gray700;
    }

    &-list {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      font-size: small;
      background: $white;
      overflow: auto;
      max-height: 18.625rem;
      overflow-x: hidden;
      border-radius: 0.313rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      // font-family: $font-primary;
      outline: 0.063rem solid $gray300;
    }
  }

  .selectPrimary__indicator-separator {
    display: none !important;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .selectPrimary__dropdown-indicator {
    color: $gray500;
    transition: color 0.3s;
    padding: 0.5rem;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
    &:hover {
      color: $gray700;
    }
  }
  .selectPrimary__placeholder {
    color: #c4c4c4;
    font-family: 'Roboto';
    letter-spacing: 0.02em;
    font-size: 1rem;
    padding-left: 1.1rem;
  }

  //   MULTI VALUE
  .selectPrimary__multi-value {
    background-color: $pink;
    color: $white;
    border-radius: 0.25rem;
  }

  .selectPrimary__multi-value__label {
    color: $white;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    padding: 0.313rem 0.5rem;
    display: inline-block;
  }

  .selectPrimary__multi-value__remove {
    transition: all 0.3s;
    &:focus,
    &:hover {
      background-color: darken($pink, 10);
      svg {
        stroke: $white !important;
        fill: $white;
      }
    }
  }
}

.selectGray {
  @extend .selectPrimary;
  margin: 1rem 0;

  .selectPrimary__single-value {
    color: black;
    font-size: 1.3rem;
  }

  .selectPrimary__control {
    background-color: $gray300;
  }
}

.selectGrayBorder {
  @extend .selectPrimary;
  margin: 0.594rem 0;
  border: 0.063rem solid #979797;
  border-radius: 1.563rem;
  text-align: center;
  height: 3.125rem;
  .selectPrimary__placeholder {
    text-align: center;
  }

  .selectPrimary__single-value {
    color: black;
    font-size: 1.125rem;
  }

  .selectPrimary__input-container {
    height: 2.5rem;
  }
}

.selectGrayBorder-centered {
  @extend .selectGrayBorder;
  .selectPrimary__placeholder,
  .selectPrimary__single-value {
    padding-left: 2.5rem;
  }
  .selectPrimary__menu {
    .selectPrimary__option {
      padding-left: 0.5rem !important;
    }
  }
}
.selectGrayBorder-centered-24 {
  @extend .selectGrayBorder-centered;
  width: 24rem;
  background-color: $inputGray;
  border: none;
  height: 2.5rem;
  margin: 0.25rem;
  .selectPrimary__single-value {
    font-size: 1.5rem;
    text-align: left;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
  }
  .selectPrimary__dropdown-indicator {
    margin-bottom: 0.5rem;
  }
}

.selectGrayInput {
  @extend .selectPrimary;

  .selectPrimary__placeholder {
    font-size: 1.5rem;
    padding-left: 0;
  }

  .selectPrimary__control {
    background-color: $inputGray;
  }
}

.selectGrayColorBlue {
  @extend .selectPrimary;

  .selectPrimary__single-value {
    color: $blue;
  }

  .selectPrimary__dropdown-indicator {
    color: $blue;
    &:hover {
      color: $blue;
    }

    .selectPrimary__control {
      background-color: $gray300;
    }
  }
}

.selectGrayNoMargin {
  @extend .selectGray;
  margin: 0;

  .selectPrimary__control {
    background-color: #f2f2f2;
  }
}

.normalFont {
  @extend .selectGrayNoMargin;
  .selectPrimary__single-value {
    font-size: 1rem;
  }
  .selectPrimary__menu {
    .selectPrimary__option {
      color: $gray700;
      font-size: 1rem;
      line-height: 1.1rem;
    }
  }
}

.filterSizeMd {
  @extend .normalFont;
  width: 18rem !important;
}

.filterSizeLg {
  @extend .selectGrayNoMargin;
  width: 22rem !important;
}

.selectGrayHeight100 {
  @extend .selectGray;
  margin: 0;
  .selectPrimary__control {
    background-color: #ededed;
    height: 90%;
  }

  .selectPrimary__value-container,
  .selectPrimary__input-container,
  .selectPrimary__single-value {
    height: 100%;
    overflow: hidden;
  }
}

.selectGrayNoMarginFit {
  @extend .selectGrayNoMargin;

  .selectPrimary__value-container {
    width: fit-content;
  }
}

.selectGrayLightNoMargin {
  @extend .selectGrayNoMargin;
  & > div {
    & > div {
      height: 3rem;
      & > div {
        & > div {
          padding-left: 2rem;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .selectPrimary__control {
    background-color: $greyLight0;
  }
}

.error,
.errorMsg {
  color: $error-color;
  // font-family: $font-primary;
  font-weight: 500;
  font-size: 0.75rem;
  position: absolute;
  left: 0;
  bottom: -1.2rem;
}
.errorMsg {
  padding: 0.125rem 0.938rem;
  display: inline-block;
}

.isBussines {
  .selectPrimary__option {
    &:hover {
      background: rgba($adminBlue, 0.25);
    }
  }
}
@media screen and (max-width: $breakpoint-web-mobile) {
  .error,
  .errorMsg {
    font-size: 1.2rem;
    bottom: -1.5rem;
  }
  .gridView-menuList {
    .selectPrimary__menu-list {
      column-gap: 3.5rem;
    }
  }
  .selectSecondary {
    .errorMsg {
      margin-top: 0.4rem;
    }
  }
}
