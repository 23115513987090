.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    flex-direction: column;
    width: 90%;
    margin: auto;

    .upperHeader {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .charts {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: column;

      .singleRow {
        display: flex;
        height: 50rem;
        margin: 0.5rem 0;

        .table {
          display: flex;
          justify-content: start;
          width: 62.5%;
          margin: 1rem;
          height: 100%;
        }
      }
    }

    span {
      display: flex;
      align-items: center;
    }

    .select {
      background: transparent;
      color: blue;
      border: none;
      font-size: 1.5rem;
      width: 13rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .wrapper {
    .header {
      .charts {
        .singleRow {
          height: 45rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    .header {
      .charts {
        .singleRow {
          height: 37rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1360px) {
  .wrapper {
    .header {
      .charts {
        .singleRow {
          height: 32rem;
        }
      }
    }
  }
}
@media only screen and (min-width: 1500px) {
  .wrapper {
    .header {
      .charts {
        .singleRow {
          height: 28rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .wrapper {
    .header {
      .charts {
        .singleRow {
          height: 27rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1680px) {
  .wrapper {
    .header {
      .charts {
        .singleRow {
          height: 25rem;
        }
      }
    }
  }
}
