@import 'styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  h2 {
    font-weight: bold;
  }
  .link {
    border-bottom: 1px solid $adminBlue;
    color: $adminBlue;
    cursor: pointer;
    color: $adminBlue;
  }
  &.isBussines {
    color: $adminBlue;
    border-bottom: 1px solid $adminBlue;
  }
}
