.createCardAndList {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  padding-top: 0;

  .backButton {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #00aee5;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
  }

  .inputFilter {
    width: 100%;
    display: flex;
    justify-content: center;

    input {
      width: 28.125rem;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    height: 80%;
    overflow: auto;

    .singleCard {
      margin: 2rem;
      cursor: pointer;
    }
  }
}

.packToggle {
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 5px;
  }
}