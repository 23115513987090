.confirmationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: white;
  border: 0.125rem solid #cd4671;
  border-radius: 1.875rem;
  position: fixed;
  z-index: 999999999;
  left: 0;
  right: 0;
  top: calc(50% - 12.25rem);
  padding: .7rem;
  width: 95%;
  height: auto !important;
  max-width: 25rem;
  margin: 0 auto;
  > div {
    display: flex;
  }
  h3 {
    text-align: center;
    margin: 0;
  }
}
