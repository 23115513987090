.showImg {
  background-color: transparent;
  border-radius: 1rem;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}
.borderRadius1 {
  border-radius: 1rem;
}
.cardIsOpen {
  overflow: hidden;
  opacity: 0;
  animation: cardLink 0.8s normal forwards;
}

.cardIsClosed {
  display: none;
}

.openCardConainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin-left: -0.725rem;
  z-index: 5;
  overflow: hidden;
  animation: changeOverflow 0.1s linear 0.4s normal forwards;
}

@keyframes cardLink {
  0% {
    position: absolute;
    bottom: -30rem;
    left: -0.5rem;
    transform: scale(0.5) matrix3d(1, 0, 0, 0, 0, 1, 0, 0.002, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
  50% {
    bottom: 2rem;
    transform: scale(0.5) matrix3d(1, 0, 0, 0, 0, 1, 0, 0.002, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    position: absolute;
    bottom: -2.5rem;
    left: -0.5rem;
    transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    overflow: inherit;
  }
}

@keyframes changeOverflow {
  from {
    overflow: hidden;
  }
  to {
    overflow: inherit;
  }
}
