@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0.625rem;
  .option {
    margin: 0;
    padding: 0;
    position: relative;
    width: 3.125rem;
    height: 3.125rem;
    .iconWrapper {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      svg {
        width: 60%;
        height: 60%;
      }
      // TYPE CREATE
      @include changeActionsColor;
    }
    .disabledOption {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: white;
      opacity: 0.7;
      border-radius: 50%;
      cursor: not-allowed;
    }
  }
  &.size-small {
    .option {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  // TYPE SPONSORED
  &.color-sponsored {
    .iconWrapper {
      @include changeActionsColor($sponsoredBorder);
    }
  }
  // TYPE SLIDE
  &.color-slide {
    .iconWrapper {
      @include changeActionsColor($standardBorder);
    }
  }
  // TYPE DOUBLE
  &.color-double {
    .iconWrapper {
      @include changeActionsColor($standardBorder);
    }
  }
  // TYPE INSTACARD
  &.color-instacard {
    .iconWrapper {
      @include changeActionsColor($standardBorder);
    }
  }
  // TYPE BLANK
  &.color-blank {
    .iconWrapper {
      @include changeActionsColor($standardBorder);
    }
  }
  // TYPE COLLECTIBLE
  &.color-collectible {
    .iconWrapper {
      @include changeActionsColor($collectibleBorder2);
    }
  }
  // TYPE MYSTERY
  &.color-mystery {
    .iconWrapper {
      @include changeActionsColor($mysteryBorder2);
    }
  }
  &.color-marketplace {
    .iconWrapper {
      @include changeActionsColor(darken($marketplaceBorder2, 20));
    }
  }
  /* --- Action colors for the "General User" Cards --- */
  &.action-generalUser-standard {
    .iconWrapper {
      @include changeActionsColor($cardColor-generalUser-action);
    }
  }
  /* --- Action colors for the "Bisiness Subscriber - Wonder Shuffle Plus Subscribers" Cards --- */
  &.action-businessSubscriber-standard,
  &.action-businessSubscriber-slide {
    .iconWrapper {
      @include changeActionsColor($cardColor-businessSubscriber-action);
    }
  }
  /* --- Action colors for the "Media" Cards --- */
  &.action-media-standard,
  &.action-media-slide,
  &.action-media-instacard {
    .iconWrapper {
      @include changeActionsColor($cardColor-media-action);
    }
  }
  /* --- Action colors for the "Primerime Advertiser" Cards --- */
  &.action-primetimeAdvertiser-standard,
  &.action-primetimeAdvertiser-slide,
  &.action-primetimeAdvertiser-double,
  &.action-primetimeAdvertiser-poll,
  &.action-primetimeAdvertiser-instacard {
    .iconWrapper {
      @include changeActionsColor($cardColor-primetimeAdvertiser-action);
    }
  }
}

.cardShare {
  padding-top: 3.7rem;
  transform: scale(1.8);
}

.modalForShare {
  transform: translateY(8.65rem);
}
