@import 'styles/variables';
@import 'styles/mixins';

.tableWrapper {
  width: 100%;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  padding: 1rem;

  .header {
    background-color: #e1e1e1;
    font-size: 1rem;
    font-weight: 400;
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    border-radius: 2rem;
    margin: 0.5rem 0 1rem 0;
  }

  .content {
    margin: 0;
    height: 80%;

    .contentHeader {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      font-size: 0.75rem;
      margin-bottom: 1rem;

      span,
      div {
        width: 15%;
      }
      div {
        padding: 0 1rem;
      }
    }
    .cards {
      height: 90%;
      overflow: auto;
      @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 10%), 0.675rem);
    }
  }
}
