@import '/src/styles/variables';
.wrapper {
  width: 18.75rem;
  height: 31.875rem;
  background-color: transparent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  .selectionPackCover {
    position: absolute;
    left: -0.9rem;
    top: -1.25rem;
    width: calc(100% + 1.5rem);
    height: calc(100% + 2.5rem);
    z-index: 1;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
      cursor: not-allowed;
    }
    &.packSelected {
      background: rgba(255, 255, 255, 0.6);
    }
  }
  .editIcon {
    position: absolute;
    bottom: 6%;
    right: 6%;
    background: white;
    border-radius: 50%;
    padding: 0.3125rem;
    width: 2.8125rem;
    cursor: pointer;
    opacity: 0;
    transition: all cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.2s;
  }
  .trashIcon {
    @extend .editIcon;
    left: 6%;
  }
  .restoreIcon {
    @extend .editIcon;
    left: 6%;
    padding: 0.3125rem 0.1rem 0.3125rem 0.3125rem;
    height: 2.8125rem;
    width: 2.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  &:hover {
    .editIcon,
    .trashIcon,
    .restoreIcon {
      opacity: 1;
    }
  }
  .logoContainer {
    margin: 3.75rem 1.875rem 0;
    height: calc(50% - 3.75rem);
    width: calc(100% - 3.75rem);
    display: flex;
    justify-content: center;
    align-items: center;
    .previewLogo {
      max-width: 100%;
      max-height: 100%;
      border-radius: 1.25rem;
      // turn off select possibility on front pack
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      &.invisible {
        opacity: 0;
      }
    }
  }
  .loadingContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(white, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .packDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // height: calc(50% - 6.25rem);
    height: 9.5rem;
    margin: 1.25rem 1.875rem 5rem 1.875rem;
    background: rgba($black, 0.4);
    border-radius: 1.25rem;
    overflow: hidden;
    padding: 0.313rem;
    padding-right: 0;
    width: calc(100% - 3.75rem);
    .scrollableContent {
      align-items: center;
      justify-content: flex-start;
      max-height: 85% !important;
      span {
        letter-spacing: 0.02em;
        color: $white;
        text-align: center;
        word-break: break-word;
        &.packName {
          font-family: 'Roboto-bold';
          font-weight: 700;
          font-size: 1.875rem;
          line-height: 1.75rem;
        }
        &.packDescription {
          font-size: 1.5rem;
          line-height: 1.75rem;
          margin-top: -0.5rem;
        }
      }
    }
  }
}

.adminEdit {
  position: absolute;
  z-index: 50;
  background: $adminBlue;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.2s;
  cursor: pointer;
  max-width: 2.5rem;
  max-height: 2.5rem;
  bottom: 6%;
  right: 6%;

  img {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}
.flipPack {
  width: 18.75rem;
  height: 31.875rem;
  perspective: 1000px;
}

.flipPackInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipPackFlipped .flipPackInnerFlipped {
  transform: rotateY(180deg);
}

.flipPackFlipped {
  animation: disapear 100ms linear 2s forwards;
}

.flipPackFront,
.flipPackBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipPackBack {
  top: -13.5rem;
  left: -12.75rem;
  position: relative;
  width: 43.75rem;
  height: 57.75rem;
  transform: rotateY(180deg);
}

.loaderContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 1.75rem;
  }
}

.infoContent {
  padding-top: 2.312rem;
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 1.875rem;
  color: $black;
  p {
    font-weight: 300;
    padding-left: 0.313rem;
  }
}
.colorDays {
  @extend .infoContent;
  color: $black;
  opacity: 0.7;
}
.critical {
  @extend .infoContent;
  color: #ff0606;
  opacity: 0.7;
}

@keyframes disapear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes moving {
  from {
    width: 20rem;
    margin-left: 0;
  }
  to {
    width: 0rem;
    margin-left: -3.125rem;
  }
}

.wrapperSelectable {
  @extend .wrapper;

  &:hover {
    .editIcon,
    .trashIcon,
    .restoreIcon {
      opacity: 0;
    }
  }
}

.wrapperNotOpenable {
  @extend .wrapper;
  cursor: default;
}

@media screen and (min-width: $breakpoint-desktops) {
  .wrapper {
    .packDetails {
      height: 8.6rem;
    }
  }
}
