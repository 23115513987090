@import '../../styles/variables';

.testWrapper {
  .header {
    font-size: 2rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .inputAndButton {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .divButton {
      margin: 2rem auto;
    }
  }

  .explanationClick {
    width: 100%;
    margin: 0;
    margin-top: 1rem;
    text-align: center;

    span {
      color: $blue;
      cursor: pointer;
    }
  }

  .heightWrapper {
    height: 32rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .explanation {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 2rem;

      .innerDiv {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
        border-radius: 2rem;
        width: fit-content;
        padding: 1rem;

        p {
          font-size: 1rem;
        }

        .closeExp {
          display: flex;
          justify-content: flex-end;

          img {
            height: 1.5rem;
            width: 1.5rem;
            cursor: pointer;
          }
        }
      }
    }

    .previewCard {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: center;
    }
  }
}
