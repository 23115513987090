@import '/src/styles/variables';
.wrapper {
  width: 18.75rem;
  height: 31.875rem;
  background-color: transparent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  .editIcon {
    position: absolute;
    bottom: 6%;
    right: 6%;
    background: white;
    border-radius: 50%;
    padding: 0.3125rem;
    width: 2.8125rem;
    cursor: pointer;
    opacity: 0;
    transition: all cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.2s;
  }
  &:hover {
    .editIcon {
      opacity: 1;
    }
  }
  .logoContainer {
    margin: 3.75rem 1.875rem 0;
    height: calc(50% - 3.75rem);
    width: calc(100% - 3.75rem);
    display: flex;
    justify-content: center;
    align-items: center;
    .previewLogo {
      max-width: 100%;
      max-height: 100%;
      border-radius: 1.25rem;
      &.invisible {
        opacity: 0;
      }
    }
  }
  .packDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(50% - 6rem);
    margin: 1.25rem 1.875rem 5rem 1.875rem;
    background: rgba($black, 0.4);
    border-radius: 1.25rem;
    overflow: hidden;
    padding: 0.313rem;
    padding-right: 0;
    width: calc(100% - 3.75rem);
    .scrollableContent {
      align-items: center;
      justify-content: flex-start;
      max-height: 85% !important;
      span {
        letter-spacing: 0.02em;
        color: $white;
        text-align: center;
        word-break: break-word;
        &.packName {
          font-weight: 600;
          font-size: 1.875rem;
          line-height: 1.75rem;
        }
        &.packDescription {
          font-size: 1.5rem;
          line-height: 1.75rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .wrapper {
    width: 15rem;
    height: 22rem;
    & > div {
      padding-bottom: 3rem;
      img {
        height: 5rem;
      }
    }
    .packDetails {
      .scrollableContent {
        gap: 0rem;
       span {
        &.packName {
          font-size: 1.1rem;
        }
        &.packDescription {
          font-size: 1rem;
        }
       }
      }
    }

  }
}
.packPreviewContainer {
  transform: scale(0.6);
}
