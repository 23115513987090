@import '../../styles/variables';

.wrapper {
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  .input {
    border: none;
    width: 100%;
    border-radius: 1.563rem;
    font-size: 1.5rem;
    padding-left: 0.625rem;
    text-align: left;
    padding-left: 2rem;

    &:disabled {
      cursor: not-allowed;
    }

    &:active {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &[type='password'] {
      letter-spacing: 0.063rem;
    }

    &[type='password']::placeholder {
      letter-spacing: initial;
    }

    &::placeholder {
      color: #c4c4c4;
      font-size: 1rem;
      font-family: 'Roboto';
      letter-spacing: 0.02rem;
      transform: translateX(-1.6rem);
    }
  }

  label {
    text-align: left;
    display: block;
    color: $labelGray;
    padding: 0.625rem 1.875rem;
    font-size: 1.125rem;
  }

  .error,
  .errorMsg {
    color: $error-color;
    font-weight: 500;
    font-size: 0.688rem;
    position: absolute;
    bottom: -1.125rem;
    width: 100%;
  }

  .errorMsg {
    padding: 0.125rem 0.938rem;
    display: inline-block;
  }
  .errorMsgUp {
    @extend .errorMsg;
    bottom: -0.52rem;
  }
}

.cardInput {
  @extend .input;
  background: $inputGray;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1rem;
  padding-left: 1.25rem;
  &::placeholder {
    color: red;
  }
}

.formInput {
  @extend .input;
  background: transparent;
  font-size: 1.125rem !important;
  font-weight: 400;
  line-height: 1rem;
  border: 0.063rem solid #c4c4c4 !important;
  padding: 0 1.25rem !important;

  &::placeholder {
    font-size: 1.125rem;
    padding-left: 0 !important;
  }
}

.xsInput {
  @extend .wrapper;

  .input {
    height: 2.5rem;
  }
}

.lgInput {
  @extend .wrapper;

  .input {
    height: 3.125rem;
  }
}

.lgInput100 {
  @extend .lgInput;
  width: 100%;
  .input {
    height: 3.125rem;
  }
}

.lgInputLeft {
  @extend .lgInput;

  .errorMsg {
    left: 0.375rem;
    width: fit-content;
  }
}

.customInput {
  @extend .lgInput;

  .input {
    width: 18rem;
  }
}

.biggerInput {
  @extend .lgInput;

  .input {
    width: 25rem;
  }
}

.customApplyInput {
  @extend .lgInput;

  .input {
    width: 22.25rem;
  }
}

.editUserProfile {
  @extend .lgInput;

  .input {
    width: 25.25rem;
  }
}

.placeholderLeft {
  @extend .input;

  &::placeholder {
    text-align: left;
    font-size: 1.125rem;
    color: $placeholderCenter;
    line-height: 1.125rem;
    padding-left: 1.563rem !important;
  }
}
.placeholderLeftLarge {
  @extend .input;

  &::placeholder {
    text-align: left;
    font-size: 1.25rem !important;
    color: $placeholderCenter;
    line-height: 1.25rem;
    padding-left: 1rem !important;
  }
}

.placeholderCenter {
  @extend .input;

  &::placeholder {
    text-align: center;
    font-size: 1.125rem;
    color: $placeholderCenter;
    line-height: 1.125rem;
  }
}

.placeholderRight {
  @extend .input;

  &::placeholder {
    text-align: right;
    font-size: 1.125rem;
    color: $placeholderCenter;
    line-height: 1.125rem;
    padding-right: 1.563rem;
  }
}

.defaultInput {
  padding-left: 1rem;
  font-size: 1.5rem;
  line-height: 2.375rem;
  color: $gray950;

  &::placeholder {
    font-size: 1.5rem;
    line-height: 2.375rem;
    padding-left: 0;
    opacity: 1;
  }
}
.fullWidth {
  width: 100%;
}

.inputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    position: absolute;
    right: 5%;
    height: 1.5rem;
    cursor: pointer;
  }

  .hiddenEye {
    height: 2.5rem;
  }
}
