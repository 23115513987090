.contactWrapper {
  width: 60%;
  border-radius: 2rem;
  margin: auto;
  margin-top: 5rem;
  box-shadow: 0px 0px 1px rgba(75, 77, 82, 0.48), 0px 0.5rem 1rem rgba(75, 77, 82, 0.24);
  height: fit-content;
  max-height: 80%;
  padding: 5rem 2rem 3rem 2rem;
  overflow: auto;

  .header {
    font-size: 2rem;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  .content {
    width: 80%;
    margin: auto;

    .singleRowInput {
      padding-bottom: 1rem;
      input {
        margin: 0.5rem 0;
      }

      .heading {
        margin-left: 0.5rem;
        margin-top: 2rem;
      }

      .errorSpan {
        margin-left: 0.5rem;
        color: red;
      }

      ::placeholder {
        text-align: start;
        padding-left: 1.7rem;
      }

      .contInputArea {
        width: 100%;
        height: 11rem;
        background-color: #ededed;
        border-radius: 2.5rem;
        border: 0;
        margin-top: 0.5rem;
      }
    }
  }

  .buttonDiv {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
