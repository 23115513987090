.createCampaign {
  .back {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #00aee5;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
  }
  .edit {
    width: 100%;

    h2 {
      margin: 0;
      margin-bottom: 2rem;
      text-align: center;
    }
  }
  .content {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    box-shadow: 0px 0.2rem 0.5rem rgba(black, 0.4);
    border-radius: 2.5rem;
    width: fit-content;
    padding: 1.5rem;
    .leftSide {
      height: 100%;
      display: flex;
      flex-direction: column;
      height: fit-content;
      justify-content: center;
      align-items: center;
      gap: 4rem;

      .formBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 2rem;

        .inputRow {
          display: flex;
          justify-content: space-evenly;
          width: 100%;

          .singleInput {
            width: 20.25rem;
            margin: 0 0.5rem;
            .createBtn {
              margin-left: 2rem;
            }
          }

          .gender {
            width: 55%;
            margin: auto;
            display: flex;
            justify-content: space-evenly;
            background: #ededed;
            margin-left: 0.5rem;
            border-radius: 2rem;
            height: 2.375rem;
            align-items: center;

            span {
              width: 33%;
              border-radius: 2rem;
              background-color: transparent;
              height: 100%;
              color: black;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }

            .active {
              width: 33%;
              border-radius: 2rem;
              background-color: #00aee5;
              height: 100%;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .formBoxBig {
        @extend .formBox;
      }
    }

    .rightSide {
      padding-left: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}

@media screen and (max-width: 1280px) {
  .createCampaign {
    .content {
      .formBox,
      .formBoxBig {
        padding: 1rem 0;
        margin: 0.5rem 0;
        .inputRow {
          flex-direction: column;

          .gender {
            width: 95%;
            margin: 0.5rem auto;
          }
          .selector {
            width: 95%;
            margin: 0.5rem auto;
          }
        }
      }
    }
  }
}
