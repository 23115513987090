.wrapper {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 2rem;
  width: 98%;
  margin: 0 auto;
  h3 {
    text-align: center;
  }
  .inputDiv {
    width: 25%;
    margin: 1rem auto;
  }

  .emailContent {
    height: fit-content;
    margin: 1rem;
  }

  .buttonDiv {
    margin: 4rem 0 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .quillHeight {
    width: 98%;
    margin: 0 auto;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 1rem 0;
    font-weight: bold;

    span {
      min-width: 19%;
    }
  }

  .scrollableContent {
    height: 100%;
    overflow: auto;
  }
  .scrollableContentHistory {
    height: 100%;
  }
  .loadingContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(white, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.875rem;
  }
}
