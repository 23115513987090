.ApplicantsWrapper {
  max-width: 98%;
  margin: auto;
  height: 100%;

  .header {
    display: flex;
    height: 3rem;

    .switch {
      width: 13.063rem;
      border-radius: 2rem;
      height: 2.125rem;
      background: #ededed;
      display: flex;
      margin: auto;
      justify-content: space-between;
      margin-left: 43%;
      margin-bottom: 1rem;

      .singleSwitch {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-radius: 2rem;
        width: 6.25rem;
        color: black;
        background: transparent;
        font-family: Roboto;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1rem;
        letter-spacing: 0.02em;
        cursor: pointer;
      }

      .singleSwitchActive {
        @extend .singleSwitch;
        background-color: #00aee5;
        color: white;
      }
    }
  }

  h2 {
    text-align: center;
    font-size: 2.25rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 2%;
    margin-bottom: 4.25rem;
  }
  .wrapperSingleApplicant {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-weight: 800;

    .singleCell {
      width: 22%;
      letter-spacing: 0.05rem;

      &:first-child {
        padding-left: 2.5rem;
      }
    }
    .showButton {
      letter-spacing: 0.05rem;
    }
  }

  .applicants {
    height: 76%;
    overflow: auto;
  }
}
