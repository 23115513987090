@import 'styles/_variables';

.wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 18.75rem;
  background-color: transparent;
  height: 28.75rem;
  border-radius: 2.775rem;
  transition: all 0.6s ease-in;
  .cardComments {
    position: absolute;
    right: 0.325rem;
    // left: 0.325rem;
    bottom: 0.325rem;
    top: 0.325rem;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }
  &.showComments {
    background-color: $inputGray;
    //18.75 card width
    //20rem for comments
    width: calc(18.75rem + 20rem);
    &.doubleCardType {
      transition: all 1s cubic-bezier(0.13, 0.92, 1, 1);
      width: calc(40.625rem + 20rem);
    }
    .cardComments {
      // left: auto;
      opacity: 1;
    }
  }
}
