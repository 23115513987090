@import '../../styles/variables';

.attributeContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  background-color: $gray250;
  border-radius: 1.875rem;
  padding-right: 0.625rem;
  &.disabled {
    opacity: 0.7;
    &:hover {
      cursor: not-allowed;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;

    .icon {
      margin: 0.875rem 0.938rem 0.875rem 1.25rem;
    }

    .label {
      font-size: 1.5rem;
      line-height: 2.375rem;
      color: $gray950;
      padding: 0;
      width: 100%;
    }
  }

  &:hover {
    cursor: pointer;

    &.notAllowed {
      cursor: not-allowed;
    }
  }

  &.width-260 {
    width: 16.25rem;
  }

  &.full-width {
    width: 100%;
  }

  .info {
    font-size: 0.875rem;
    line-height: 0.938rem;
    color: $gray950;
    position: absolute;
    left: 0.938rem;
    bottom: -0.75rem;
    height: 0.625rem;

    &:hover {
      cursor: default;
    }
  }
  .error,
  .errorMsg {
    color: $error-color;
    font-weight: 500;
    font-size: 0.688rem;
    bottom: -1.125rem;
    width: 100%;
    left: 0.938rem;
  }
}
.mapBtn {
  &.smallFont {
    .label {
      line-height: 1.375rem !important;
      span {
        font-size: 1.375rem !important;
      }
    }
  }
}

.disabledButton {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.mapBtn {
  div {
    line-height: 0 !important;
  }
  span {
    font-size: 1.375rem !important;
    line-height: 1;
  }
}
.mapBtnEmpty {
  span {
    color: $labelGray !important;
  }
}
