@import 'styles/_variables';
@import 'styles/_mixins';

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 30rem;
  height: 34rem;
  overflow: hidden;
  background: $gray100;
  border-radius: 2.5rem;
  gap: 1.5rem;
  box-shadow: inset 0 1px 7px -1px $gray400;
  & > h2 {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.02em;
    color: $gray950;
  }
  .reportsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow: auto;
    margin-bottom: 2rem;
    width: 100%;
    @include changeScrollColor(lighten($gray950, 55%), lighten($inputGray, 1.5%), 0.475rem);
  }
}

@media only screen and (min-width: $breakpoint-tablets) and (max-width: $breakpoint-web-mobile) {
  .wrapper {
    width: 25rem;
  }
}
@media only screen and (min-width: $breakpoint-phones) and (max-width: $breakpoint-tablets) {
  .wrapper {
    width: 20rem;
  }
}
@media only screen and (max-width: $breakpoint-phones) {
  .wrapper {
    display: none;
  }
}
