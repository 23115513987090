@import 'styles/rules';

.wrapper {
  width: 15.625rem;
  cursor: auto;

  .otherAcoutn {
    padding: 0.5rem 0;
    color: #00aee5;
    text-decoration: underline;
    padding: 0.5rem;
    width: 100%;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0.313rem;
    font-weight: normal;
    a {
      color: #030303 !important;
      font-family: 'Roboto', sans-serif;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: bold;
      letter-spacing: 0.2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .details {
    justify-content: center;
    margin-top: 1rem;
  }

  .details,
  .otherAcoutn {
    display: flex;
    align-items: center;
    cursor: pointer;

    .avatar {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      margin-right: 0.625rem;
      object-fit: cover;
    }

    .acount_details {
      width: 10.625rem;

      .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #030303;
        font-size: 0.875rem;
        font-weight: 300;
      }
    }

    .username {
      color: #030303;
      font-family: 'Roboto', sans-serif;
      font-size: 1.125rem;
      line-height: 1.5rem;
      font-weight: bold;
      letter-spacing: 0.2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      margin: 1rem 0;
      margin-bottom: 0;
      margin: 0;
    }

    .btn__logout {
      @extend .username;

      color: #00aee5;
      font-size: 1rem;
      font-weight: 300;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
      margin: 0;
      padding: 0;
    }
  }

  .otherAcoutn {
    h4 {
      margin: 0 !important;
    }
  }

  .btns {
    margin-top: 1.688rem;
    margin-bottom: 1.5rem;

    .btn {
      margin: 0.313rem 0;
      width: 100%;
      border: 0.063rem solid #030303;

      > span {
        color: #030303;
        font-weight: 300;
      }
    }
  }
}
