@import '/src//styles/variables';

.container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .slidesContainer {
    width: 100%;
    height: 100%;
    .slide {
      width: 100%;
      height: 100%;
      position: absolute;
      display: none;
      transition: opacity ease-in-out 0.6s;
      &.activeAnim {
        display: block;
      }
      .slideImage {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        cursor: pointer;
      }
    }
  }
  &.previewSlider {
    height: calc(100% - 3.75rem);
    // border-bottom-left-radius: 1.25rem;
    // border-bottom-right-radius: 1.25rem;
    border-radius: 1.875rem;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba($black, 0.25);
  }
  .captionContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(101, 101, 101, 0) 0.01%,
      rgba(0, 0, 0, 0.35) 100%
    );
    padding: 2.5rem 1.25rem;
    text-align: left;
    &.bottomBorder {
      border-bottom-left-radius: 2.5rem;
      border-bottom-right-radius: 2.5rem;
    }
    span {
      width: 100%;
      font-size: 0.75rem;
      line-height: 1.188rem;
      letter-spacing: 0.02em;
      color: $white;
      word-break: break-word;
    }
    .openLinkButton {
      margin: 1rem auto 0;
      font-size: 0.75rem;
      height: unset; 
      padding: 0.25rem 0.50rem;
    }
  }
}
.closeContainer {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  .closeIcon {
    cursor: pointer;
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    z-index: 10;
    display: none;
    &.active {
      display: block;
    }
  }
}
.containerDots {
  position: absolute;
  bottom: 0.625rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 0.125;
  .dot {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin: 0 0.313rem;
    background: $lightGray;
    transition: background-color ease-in-out 0.2s;
    &:hover {
      background-color: $standardBorder;
    }
    &.active {
      background-color: $standardBorder;
    }
  }
}
