@import '/src/styles/_variables.scss';

.defaultSize {
  width: 7.5rem;
  height: 7.5rem;
}

.wrapper {
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  > div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    > input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 50;
      opacity: 0;
    }

    .change {
      width: 100%;
      height: 30%;
      background-color: rgba(129, 129, 129, 0.8);
      position: absolute;
      color: #fff;
      bottom: 0;
      text-align: center;
    }

    .mediaPicker {
      margin-top: -100%;
      opacity: 0;
    }
  }
  .loader {
    width: 60%;
    height: 60%;
    position: absolute;
    margin: 20%;
    margin-top: 5%;

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 50%;
  }
}

.editable {
  > div {
    cursor: pointer;
  }
}

/* ---- Avatar Frame Size ---- */
.medium {
  padding: 0.313rem;
}
.large {
  padding: 0.563rem;
}

/* ---- Avatar Frame Style ---- */
.bronze {
  background: linear-gradient(207.88deg, #fde6cb 3.83%, #b37d54 25.86%, #fde6cc 64.41%, #b37d54 91.95%);
}
.silver {
  background: linear-gradient(204.93deg, #818181 3.95%, #efefef 36.77%, #919191 67.57%, #f2f2f2 100.9%);
}
.gold {
  background: linear-gradient(210.17deg, #ffd875 13.49%, #c78119 37.76%, #ffd875 68.32%, #c78119 99.78%);
}
.diamond {
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(207, 207, 238, 1) 35%, rgb(25, 56, 228) 100%);
}
.pink {
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(242, 22, 197, 1) 0%, rgb(204, 0, 255) 100%);
}
.platinum {
  background: linear-gradient(207.88deg, #fde6cb 3.83%, #b37d54 25.86%, #fde6cc 64.41%, #b37d54 91.95%);
}
.yellow {
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(241, 242, 22, 1) 0%, rgba(198, 237, 64, 1) 100%);
}
