.trendingWrapper {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 2rem;

  .switch {
    width: 15.063rem;
    border-radius: 2rem;
    height: 2.125rem;
    background: #ededed;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;

    .singleSwitch {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.125rem;
      border-radius: 2rem;
      width: 7.25rem;
      color: black;
      background: transparent;
      font-family: Roboto;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1rem;
      letter-spacing: 0.02em;
      cursor: pointer;
    }

    .singleSwitchActive {
      @extend .singleSwitch;
      background-color: #00aee5;
      color: white;
    }
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .filter {
    display: flex;
    justify-content: flex-start;

    .third {
      width: 19rem;
      max-width: 33%;
      height: 2.75rem;

      input {
        margin-left: 1.438rem;
        width: 18.375rem;
        height: 2.5rem;
        background-color: #ededed;
        border-radius: 2rem;
        border: 0px;
        outline: none;
      }

      input:disabled {
        cursor: not-allowed;
      }

      .selectGrayNoMargin {
        width: 19rem;
      }
    }
  }

  .cardsDiv {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 98%;
    margin: 0 auto;

    .savedOrDeletedCards {
      display: flex;
      flex-direction: column;

      .cards {
        padding: 2rem;
        display: flex;
        gap: 2.4rem;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }

  .cardsDivSlider {
    @extend .cardsDiv;
    overflow: hidden;
  }
}
