.small {
  background-color: #f3f3f3;
  border-radius: 2.5rem;
  width: 30%;
  height: fit-content;
  // padding-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem;
  padding-top: 1rem;
  height: 100%;
  box-shadow: 0.175rem 0.25rem 0.5rem rgba(0, 0, 0, 0.4);

  .legendHeader {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .single {
      display: flex;
      align-items: center;

      .singleBox {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 5px;
        margin: 0 0.5rem;
      }
    }
  }

  .chart {
    width: 100%;
    transform: translateX(-2rem);
    padding-right: 1rem;
  }

  .chartTitle {
    width: 90%;
    margin: auto;
    background: #e1e1e1;
    height: 2.5rem;
    border-radius: 20px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin: 0.5rem 0 1rem 0;
    font-size: 1rem;
  }

  tspan {
    font-size: 12px;
  }
}

.customTooltip {
  background-color: #f3f3f3;
  border-radius: 2rem;
  width: fit-content;
  height: fit-content;
  padding: 1rem 2rem;
  font-size: 0.75rem;

  p {
    text-align: center;
  }
}

.medium {
  @extend .small;
  width: 47%;
}

.large {
  @extend .small;
  width: 100%;
}
