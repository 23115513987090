.savedCardWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;

  .searchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    & > div {
      width: 31.25rem;
    }
  }
  .icons {
    display: flex;
    img {
      margin: 0 0.5rem;
      cursor: pointer;
      width: auto;
      height: 2.5rem;
    }
  }

  .cardsDiv {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    overflow: auto;
    overflow-x: hidden;

    .savedOrDeletedCards {
      display: flex;
      flex-direction: column;
      margin-top: 3rem;
      .cards {
        padding: 2rem;
        display: flex;
        gap: 2.4rem;
        flex-wrap: wrap;
      }
    }
  }

  h3 {
    text-align: center;
  }
}
