@import '/src/styles/variables';

.ApplicationFormWrapper {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    font-size: 2.25rem;
    font-weight: 400;

    .close {
      cursor: pointer;
      color: #00aee5;
    }

    .title {
      letter-spacing: 0.05rem;
      margin-left: -3rem;
    }
  }
  .singleInputRow {
    display: flex;
    margin: 2em 0;
    justify-content: space-around;
    width: 100%;

    textarea {
      width: 98%;
      margin: auto;
      border-radius: 2rem;
      min-height: 4.813rem;
      border: 1px solid $greyLight1;
      height: fit-content;
      padding: 1rem;
      margin: 0.594rem 0;
      resize: none;
      color: $greyLight1;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 0.025rem;
      font-size: 1rem;
    }

    textarea:disabled {
      cursor: not-allowed;
    }

    input {
      border-color: $greyLight1 !important;
      color: $greyLight1;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 0.025rem;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
  }
}
