.wrapper {
  .container {
    box-shadow: 0px 0px 1px rgba(75, 77, 82, 0.48), 0px 0.5rem 1rem #4b4d523d;
    border-radius: 2rem;
    width: 80%;
    margin: 1rem auto;
    max-height: 80%;
    padding: 3rem 1rem;
    display: flex;
    justify-content: space-around;
    .leftSide {
      padding-top: 2rem;
    }
    .rightSide {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .cardImg {
        display: flex;
        justify-content: center;
        width: 100%;
        img {
          height: 10rem;
          width: 100%;
        }
      }
      button {
        width: 22.5rem;
        margin: 1rem auto;
        span {
          font-size: 0.9rem;
        }
      }
    }
  }
  .addNewCard {
    .button {
      height: 2.5rem;
      width: 12rem;
      &.disable {
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
  .loadingContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(white, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.875rem;
  }
}
