* {
  box-sizing: border-box;
}

body,
html {
  font-family: $font-primary, sans-serif;
  height: 100%;
  margin: 0;
  color: $color-primary;
  box-sizing: border-box;
  overflow-x: hidden;
  font-size: 14px;
  background: $bg-primary;
}

a {
  color: $color-primary;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  :active {
    color: $color-primary;
    text-decoration: none;
  }
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
}

// /* Extra Small Devices, Phones */
// @media only screen and (min-width: 480px) {
// }

// /* Small Devices, Tablets */
// @media only screen and (min-width: 768px) {
// }
// /* Medium Devices, Desktops */
// @media only screen and (min-width: 992px) {
//   body,
//   html {
//     font-size: 16px;
//   }
// }

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

@media screen and (min-width: 1921px) {
  html,
  body {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1681px) {
  html,
  body {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1680px) and (min-width: 1600px) {
  html,
  body {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1599px) and (min-width: 1500px) {
  html,
  body {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1499px) and (min-width: 1360px) {
  html,
  body {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1359px) and (min-width: 1024px) {
  html,
  body {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  html,
  body {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 767px) and (min-width: 481px) {
  html,
  body {
    font-size: 7px !important;
  }
}
@media screen and (max-width: 480px) {
  html,
  body {
    font-size: 10px !important;
  }
}

.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}
