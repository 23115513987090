@import 'styles/variables';

.wrapper {
  width: 100%;
  padding-top: 3.125rem;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  padding-bottom: 0.125rem;

  .content {
    display: flex;
    column-gap: 1.875rem;
    @media (max-width: $breakpoint-tablets) {
      display: block;
      text-align: left;
      margin-top: 0.625rem;
      & > div:nth-child(1) {
        transform: translateX(-1.7rem);
      }
      & > div:nth-child(2) {
        transform: translateX(0rem);
      }
    }
    .previewCropForCard {
      position: relative;
      width: 16.875rem;
      height: 25rem;
      & > img {
        height: 100%;
        width: 100%;
        background-repeat: none;
        background-position: center;
        object-fit: cover;
        background-size: cover;
        border-radius: 2rem;
      }
      @media (max-width: $breakpoint-tablets) {
        & > img {
          margin-top: 1.5rem;
        }
      }
    }
    .packContent {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      .previewCropForPack {
        height: 10.25rem;
        background-repeat: none;
        background-position: center;
        background-size: cover;
        border-radius: 1.25rem;
        max-width: 100%;
        object-fit: contain;
        width: auto !important;
        @media (max-width: $breakpoint-tablets) {
          margin-top: 1.5rem;
        }
      }
      .previewCropDnd {
        @extend .previewCropForPack;
        height: 25.25rem;
      }
      .previewDoubleCard {
        @extend .previewCropForPack;
        height: 25.25rem;
        border-radius: 2.5rem;
        width: 32.813rem !important;
        object-fit: cover;
      }
    }
  }
  .btn {
    display: flex;
    align-items: center;
    margin: 4.25rem 0;
    .move {
      margin-right: 1rem;
    }
    .position {
      position: relative;
      input {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        z-index: 5;
        top: 0.7rem;
        height: 2.188rem;
        right: 0;
        left: 0.9rem;
      }
    }
  }
}
.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(white, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
