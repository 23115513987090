@import 'styles/_variables';

.cardBody {
  overflow: visible;
  .ribbonContent {
    position: absolute;
    bottom: 10%;
    left: -1.313rem;
    cursor: pointer;
    .ribbon {
      width: 24.375rem;
      height: 30%;
    }
    span {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      color: $white;
      word-break: break-word;
      padding: 0.938rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4.125rem;
      overflow: hidden;
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 1.75rem;
    }
  }
  .prev {
    position: absolute;
    right: -1.563rem;
    bottom: 10%;
    cursor: pointer;
    .ribbonPrev {
      width: 20.625rem;
    }
    span {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      color: $white;
      word-break: break-word;
      padding: 0.938rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4.125rem;
      overflow: hidden;
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 1.75rem;
    }
  }
}
