@import '../../../../styles/variables';
@import 'styles/_mixins';

.container {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.625rem;
  width: 100%;
  word-break: break-word;
  z-index: 1;
  // max-height: 60%;
  max-height: 70%;
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 2.5rem;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $white;
    border-radius: 2.5rem;
  }
  //   SCROLL COLOR
  &.color-create {
    &::-webkit-scrollbar-track {
      background: lighten($createBorder, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($createBorder, 5%);
    }
  }
  &.color-standard {
    &::-webkit-scrollbar-track {
      background: lighten($standardBorder, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($standardBorder, 5%);
    }
  }
  &.color-instacard {
    &::-webkit-scrollbar-track {
      background: lighten($standardBorder, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($standardBorder, 5%);
    }
  }
  &.color-blank {
    &::-webkit-scrollbar-track {
      background: lighten($standardBorder, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($standardBorder, 5%);
    }
  }
  &.color-slide {
    &::-webkit-scrollbar-track {
      background: lighten($standardBorder, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($standardBorder, 5%);
    }
  }
  &.color-double {
    &::-webkit-scrollbar-track {
      background: lighten($standardBorder, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($standardBorder, 5%);
    }
  }
  &.color-collectible {
    &::-webkit-scrollbar-track {
      background: lighten($collectibleBorder2, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($collectibleBorder2, 5%);
    }
  }
  &.color-mystery {
    &::-webkit-scrollbar-track {
      background: lighten($mysteryBorder2, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($mysteryBorder2, 5%);
    }
  }
  &.color-doubleSidedTrivia {
    &::-webkit-scrollbar-track {
      background: lighten($doubleSidedTriviaBorder, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($doubleSidedTriviaBorder, 5%);
    }
  }
  &.color-poll {
    &::-webkit-scrollbar-track {
      background: lighten($doubleSidedTriviaBorder, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($doubleSidedTriviaBorder, 5%);
    }
  }
  &.color-oneSidedTrivia {
    &::-webkit-scrollbar-track {
      background: lighten($oneSidedTriviaBorder, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($oneSidedTriviaBorder, 5%);
    }
  }
  &.color-sponsored {
    &::-webkit-scrollbar-track {
      background: lighten($sponsoredBorder, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($sponsoredBorder, 5%);
    }
  }
  &.color-bussines {
    &::-webkit-scrollbar-track {
      background: lighten($blue, 70%);
    }
    &::-webkit-scrollbar-thumb {
      background: darken($blue, 5%);
    }
  }
}

@media screen and (min-width: $breakpoint-desktops) {
  .container {
    max-height: 77%;
  }
}
