@import 'styles/_variables';
.wrapper {
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  .filter {
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem;
    padding-bottom: 1rem;
    .range {
      width: 15rem;
      max-width: 33%;
      height: 2.75rem;
    }
    .search {
      input {
        margin-left: 1.438rem;
        width: 18.375rem;
        height: 2.75rem;
        background-color: #ededed;
        border-radius: 2rem;
        border: 0px;
        outline: none;
      }
    }
  }

  .content {
    display: flex;
    width: 100%;
    font-size: 1.125rem;
    font-weight: 700;
    margin-top: 3.188rem;

    .contentHeader {
      display: flex;
      width: 80%;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }
  .centeredTable {
    td {
      text-align: center;
      &:last-child {
        text-align: right;
      }
    }
  }
  .viewRocButton {
    height: 2rem;
    width: auto;
    padding: 1rem 2rem;
    margin: 0 auto;
  }
  .buttonDisable {
    @extend .viewRocButton;
    cursor: not-allowed;
    opacity: 0.7;
  }

  .deleteEmptySearchIcon {
    cursor: pointer;

    img {
      width: 2rem;
      height: 2rem;
      margin: 0 0.5rem;
    }
  }
}
