@import '../../../styles/variables';

.container {
  display: flex;
  position: relative;
  width: 1.375rem;
  height: 1.375rem;
  .horizontalLine {
    position: absolute;
    top: calc(50% - 0.133rem);
    left: 0;
    width: 100%;
    height: 0.266rem;
    background-color: $black;
  }
  .verticalLine {
    position: absolute;
    left: calc(50% - 0.133rem);
    top: 0;
    height: 100%;
    width: 0.266rem;
    background-color: $black;
  }
}
