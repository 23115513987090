.wrapper {
  position: relative;
  .image {
    width: 100%;
    height: auto;
    border-radius: 1rem;
  }
  .buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0.75rem;
    .button {
      max-width: 80%;
    }
  }
}
