@import 'styles/_variables';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 90vh;
  text-align: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .cardsCreate {
      display: flex;
      justify-content: space-evenly;
      width: 98%;
      margin: 0 auto;
    }

    .text {
      width: 80%;
      margin: 0 auto;
      margin-top: 6rem;

      h3 {
        color: $adminBlue;
        text-decoration: underline;
        cursor: pointer;
      }
      p {
        font-weight: 700;
        font-size: 1.4rem;
      }
      span {
        color: $adminBlue;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
