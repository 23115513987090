@import '../../styles/variables';

.container {
  position: relative;
  //Sizing
  &.additional-border {
    &::before {
      content: '';
      position: absolute;
      top: -2.5rem;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: 1.25rem;
      background: linear-gradient(180deg, #f4ca68 0%, #db9915 100%);
      border-radius: 2.188rem;
    }
    &::after {
      content: '';
      position: absolute;
      top: -1.25rem;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: 0.625rem;
      background: linear-gradient(180deg, #fdaf19 0%, #de3e0b 100%);
      border-radius: 2.188rem;
    }
  }
  //Sizing
  &.size-extraSmall {
    border-radius: 0.95rem;
    width: 7.125rem;
    height: 11rem;
    &.double {
      width: 15.4375rem;
    }
  }
  &.size-normal {
    border-radius: 2.5rem;
    width: 18.75rem;
    height: calc(28.75rem - 0.6rem);
    &.double {
      width: 40.625rem;
    }
  }
  &.size-large {
    border-radius: 3.125rem;
    width: 23.125rem;
    height: 34.375rem;
    transition: all 1s ease-out;
    &.double {
      width: 46.25rem;
    }
  }
  &.normalBorder {
    &.size-extraSmall {
      padding: 0.2375rem;
    }
    &.size-normal {
      padding: 0.625rem;
    }
    &.size-large {
      padding: 0.625rem;
    }
  }
  &.marketplaceBorder {
    &.size-extraSmall {
      padding: 0.1425rem;
    }
    &.size-normal {
      padding: 0.575rem;
    }
    &.size-large {
      padding: 0.375rem;
    }
  }
  // Border type
  &.border-create {
    @extend .normalBorder;
    background-color: $createBorder;
  }
  &.border-standard {
    @extend .normalBorder;
    background-color: $standardBorder;
  }
  &.border-instacard {
    @extend .normalBorder;
    background-color: $standardBorder;
  }
  &.border-slide {
    @extend .normalBorder;
    background-color: $standardBorder;
  }
  &.border-double {
    @extend .normalBorder;
    background-color: $standardBorder;
  }
  &.border-blank {
    @extend .normalBorder;
    background-color: $standardBorder;
  }
  &.border-bussines {
    @extend .normalBorder;
    background-color: $adminBlue;
  }
  &.border-collectible {
    @extend .normalBorder;
    background: linear-gradient(180deg, $collectibleBorder1 0%, $collectibleBorder2 100%);
  }
  &.border-mystery {
    @extend .normalBorder;
    background: linear-gradient(180deg, $mysteryBorder1 0%, $mysteryBorder2 100%);
  }
  &.border-doubleSidedTrivia {
    @extend .normalBorder;
    background-color: $doubleSidedTriviaBorder;
  }
  &.border-poll {
    @extend .normalBorder;
    background-color: $doubleSidedTriviaBorder;
  }
  &.border-oneSidedTrivia {
    @extend .normalBorder;
    background-color: $oneSidedTriviaBorder;
  }
  &.border-event {
    @extend .normalBorder;
    background: $eventBorder1;
  }
  &.border-sponsored {
    @extend .normalBorder;
    background: $sponsoredBorder;
  }
  &.border-share {
    @extend .normalBorder;
    background-color: $shareBorder;
  }
  &.border-anchor {
    @extend .normalBorder;
    background: linear-gradient(180deg, $anchorBorder1 100%, $anchorBorder2 100%);
  }
  &.border-first {
    padding: 0.625rem;
    background-color: $shareBorder;
  }
  &.border-partnership {
    padding: 0.625rem;
    background-color: #00aee5;
  }
  &.border-marketplace {
    @extend .marketplaceBorder;
    background-color: $marketplaceBorder1;
    position: relative;
    &.staticBorderForNewsletter {
      border: 0.25rem solid $marketplaceBorder2;
      &:before {
        display: none;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -0.25rem;
      background: linear-gradient(
        225deg,
        $marketplaceBorder2 0%,
        $marketplaceBorder3 33%,
        $marketplaceBorder2 66%,
        $marketplaceBorder3 100%
      );
    }
    &.size-extraSmall {
      border-radius: 0.89rem;
      width: 6.935rem;
      height: calc(10.375rem - 0.228rem);
      &:before {
        border-radius: 0.95rem;
      }
    }
    &.size-normal {
      border-radius: 2.344rem;
      width: 18.19rem;
      height: calc(28.25rem - 0.6rem);
      &:before {
        border-radius: 2.5rem;
      }
    }
    &.size-large {
      border-radius: 2.938rem;
      width: 22.625rem;
      height: 33.875rem;
      &:before {
        border-radius: 3.125rem;
      }
    }
  }

  &.border-user {
    padding: 0.625rem;
  }

  /* --- Border color for the "General User" Cards --- */
  &.border-generalUser-standard {
    @extend .normalBorder;
    background-color: $cardColor-generalUser-primary !important;
  }
  /* --- Border color for the "Bisiness Subscriber - Wonder Shuffle Plus Subscribers" Cards --- */
  &.border-businessSubscriber-standard,
  &.border-businessSubscriber-slide {
    @extend .normalBorder;
    background-color: $cardColor-businessSubscriber-primary !important;
  }
  /* --- Border color for the "Media" Cards --- */
  &.border-media-standard,
  &.border-media-slide,
  &.border-media-instacard {
    @extend .normalBorder;
    background-color: $cardColor-media-primary !important;
  }
  /* --- Border color for the "Primerime Advertiser" Cards --- */
  &.border-primetimeAdvertiser-standard,
  &.border-primetimeAdvertiser-slide,
  &.border-primetimeAdvertiser-double,
  &.border-primetimeAdvertiser-poll,
  &.border-primetimeAdvertiser-instacard {
    @extend .marketplaceBorder;
    position: relative;
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -0.25rem;
      border-radius: 2.5rem;
      background: $cardColor-primetimeAdvertiser-border;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: 0rem;
      border-radius: 2.25rem;
      background-color: $cardColor-primetimeAdvertiser-primary !important;
    }
    &.staticBorderForNewsletter {
      border: 0.25rem solid $mysteryBorder2;
      &:before {
        display: none;
      }
    }
  }
}

.triviaOverflow {
  overflow: auto;
}
