@import 'styles/_variables';
@import 'styles/_mixins';

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  padding: 0.3125rem;
  cursor: pointer;
  & > div {
    height: 3.5rem !important;
    // & > img {
    //   // width: 3.5rem !important;
    //   // height: 3.5rem !important;
    // }
  }
  .rightContainer {
    margin-left: 1.25rem;
    width: 100%;
    overflow: hidden;
    .title {
      font-size: 0.975rem;
      font-weight: 400;
      font-family: 'Roboto-medium';
    }
    .subtitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.6875rem;
      margin: 0.1875rem 0;
    }
    .boldTitle {
      @extend .title;
      font-weight: 700;
    }
    .boldSubtitle {
      @extend .subtitle;
      font-weight: 700;
    }
    .info {
      font-size: 0.825rem;
      margin: 0;
    }
  }
}
.activeWrapper {
  @extend .wrapper;
  background-color: $matureDisable;
  border-radius: 1.875rem;
}
